import { Layout } from "components/Layout";
import RecentSearches from "components/RecentSearches";
import { SearchIcon } from "icons";
import React, { useState } from "react";
import styled from "styled-components";
// import NotificationImageOne from "../assets/notification-image-one.png";
// import NotificationImageTwo from "../assets/notification-image-two.png";
// import NotificationImageThree from "../assets/notification-image-three.png";
import RoomSearchResult from "components/RoomSearchResult";
import SongsSearchResult from "components/SongsSearchResult";
import PeopleSearchResult from "components/PeopleSearchResult";

const SearchScreen = () => {
  // const [isPresentlySearching, setIsPresentlySearching] = useState(true);
  const isPresentlySearching = true;

  const [activeSearchState, setActiveSearchState] = useState("Room");

  const handleButtonClick = (searchState: string) => {
    setActiveSearchState(searchState);
  };

  return (
    <Layout>
      <SearchScreenContainer>
        <h5>Search</h5>
        <SearchInputContainer>
          <SearchIcon />
          <SearchInput
            type="search"
            name="search"
            id="search"
            placeholder="Search for rooms or people"
          />
        </SearchInputContainer>

        {isPresentlySearching ? (
          <SearchContentContainer>
            <SearchContentSelectOptions>
              <StyledButton
                active={activeSearchState === "Room"}
                onClick={() => handleButtonClick("Room")}
              >
                Room
              </StyledButton>
              <StyledButton
                active={activeSearchState === "People"}
                onClick={() => handleButtonClick("People")}
              >
                People
              </StyledButton>
              <StyledButton
                active={activeSearchState === "Songs"}
                onClick={() => handleButtonClick("Songs")}
              >
                Songs
              </StyledButton>
            </SearchContentSelectOptions>
            {activeSearchState === "Room" && <RoomSearchResult />}
            {activeSearchState === "Songs" && <SongsSearchResult />}
            {activeSearchState === "People" && <PeopleSearchResult />}
          </SearchContentContainer>
        ) : (
          <RecentSearches />
        )}
      </SearchScreenContainer>
    </Layout>
  );
};

export default SearchScreen;

const SearchScreenContainer = styled.section`
  h5 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
  }
`;

const SearchInputContainer = styled.div`
  display: flex;
  border: 1px solid #bfbfbf;
  border-radius: 4px;
  outline: none;
  display: flex;
  align-items: center;
  padding-left: 20px;
`;

const SearchInput = styled.input`
  border: none;
  padding: 12px 16px;
  outline: none;
  width: 100%;
  font-size: 14px;
`;

const SearchContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 16px;
`;

const SearchContentSelectOptions = styled.div`
  display: flex;
  gap: 8px;
`;

interface StyledButtonProps {
  active: boolean;
}

const StyledButton = styled.button<StyledButtonProps>`
  padding: 8px 16px;
  border-radius: 32px;
  color: ${({ active }) => (active ? "#FAFAFA" : "#375768")};
  background-color: ${({ active }) => (active ? "#008135" : "transparent")};
  border: solid 1px #5f7f90;
  font-family: Satoshi;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  outline: none;
  cursor: pointer;
`;
