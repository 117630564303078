import React from "react";
export const PasswordToggleIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.28599 6C2.07957 7 1.33337 8 1.33337 8C1.33337 8 4.31814 12 8.00004 12C8.45667 12 8.90257 11.9385 9.33337 11.8307M6.67731 4.16667C7.10484 4.06053 7.54717 4 8.00004 4C11.6819 4 14.6667 8 14.6667 8C14.6667 8 13.9205 9 12.7141 10"
      stroke="#8C8C8C"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.77144 6.8737C6.49941 7.1703 6.33337 7.56573 6.33337 7.99993C6.33337 8.9204 7.07957 9.6666 8.00004 9.6666C8.45427 9.6666 8.86607 9.48487 9.16671 9.19017"
      stroke="#8C8C8C"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14 14L2 2"
      stroke="#8C8C8C"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
