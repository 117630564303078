import React from "react";
import { useSelector } from "react-redux";
import { ProfileBannerContainer, ScreenTitleContainer } from "./ProfileScreen";
import { ScreenTitle } from "components/ScreenTitle";
import styled from "styled-components";
import { selectCurrentUser } from "features/auth/authSlice";
import DefaultProfileImg from "../assets/defaultprofileImg.png";
import { Ellipsis, ShareProfileIcon } from "icons";
import { Layout } from "components/Layout";
import { BackIcon } from "icons/BackIcon";
import cookies from "js-cookie";

const PublicProfileScreen = () => {
  // const user = useSelector(selectCurrentUser);
  const userr = useSelector(selectCurrentUser);
  const user =
    userr !== null ? userr : JSON.parse(cookies.get("playa_user") as string);

  return (
    <Layout>
      <PublicProfileScreenContainer>
        <section>
          <ScreenTitleContainer>
            <ScreenTitle title="View Profile" icon={<BackIcon />} />
          </ScreenTitleContainer>

          <ProfileSectionContainer>
            <ProfileBannerContainer>
              <ProfileBannerContainer>
                <img src={DefaultProfileImg} alt="profile banner" />
              </ProfileBannerContainer>
            </ProfileBannerContainer>
            <ProfileDetailsContainer>
              <h5>{user?.username}</h5>
              <FollowersContainer>
                <p>1.5k Follwers</p>
                <Ellipsis />
                <p>565 Following</p>
              </FollowersContainer>
            </ProfileDetailsContainer>

            <FollowButtonContainer>
              <button>+ Follow</button>
              <ShareProfileContainer>
                <ShareProfileIcon /> <p>Share Profile</p>
              </ShareProfileContainer>
            </FollowButtonContainer>
          </ProfileSectionContainer>
        </section>
      </PublicProfileScreenContainer>
    </Layout>
  );
};

export default PublicProfileScreen;

const PublicProfileScreenContainer = styled.div`
  h5 {
    color: #141414;
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
  }

  button {
    background-color: #008135;
    border: none;
    color: #fff;
    border-radius: 32px;
    display: flex;
    width: 350px;
    height: 48px;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    cursor: pointer;
    font-size: 14px;
  }
`;

const ProfileSectionContainer = styled.div`
  margin-top: 5rem;
`;

const FollowersContainer = styled.div`
  display: flex;
  align-items: baseline;
  gap: 10px;
  p {
    color: #595959;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-top: 5px;
  }
`;

const FollowButtonContainer = styled.div`
  /* margin-top: 2rem; */
`;

const ProfileDetailsContainer = styled.div`
  margin-bottom: 5rem;
`;

const ShareProfileContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
  gap: 10px;

  p {
    color: #008135;
    text-align: center;
    font-family: Satoshi;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px; /* 157.143% */
  }
`;
