//@ts-nocheck
import React, { useState, useEffect } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { DragIcon, LikeIcon } from "icons";
import PlaceholderImg from "../assets/placeholder.png";
import styled from "styled-components";
import { addTrackToLibrary } from "services/spotify";

const reorder = (list: any, startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
  userSelect: "none",
  background: isDragging ? "#f8f9f8" : "transparent",
  ...draggableStyle,
});

const getListStyle = (isDraggingOver: boolean) => ({
  background: isDraggingOver ? "transparent" : "White",
});

export const DraggableCard = ({ itemsArr }: { itemsArr: any }) => {
  const [items, setItems] = useState(itemsArr);
  useEffect(() => {
    setItems(itemsArr);
  }, [itemsArr]);

  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }

    const newItems: any = reorder(
      items,
      result.source.index,
      result.destination.index
    );

    setItems(newItems);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}
          >
            {items.length > 0 ? (
              items.map((item: any, index: number) => (
                <Draggable
                  key={item?.track?.id ?? item.id}
                  draggableId={item?.track?.id ?? item.id}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                    >
                      <CardContainer>
                        <div>
                          <img
                            src={
                              item.image ??
                              item?.track?.album?.images[0]?.url ??
                              PlaceholderImg ??
                              item?.attributes?.artwork?.url
                            } // item?.attributes?.artwork?.url for apple not displaying
                            alt="track cover"
                          />

                          <div>
                            <p>
                              {item?.track?.name ??
                                item.songTitle ??
                                item?.attributes?.name}
                            </p>
                            <span>
                              {item?.track?.artists[0]?.name ??
                                item.author ??
                                item?.attributes?.artistName}
                            </span>
                          </div>

                     
                        </div>
                        <div>
                          <DragIcon />
                        </div>
                      </CardContainer>
                    </div>
                  )}
                </Draggable>
              ))
            ) : (
              <PTag>
                Oops! we could not retrieve your playlist songs. Please try
                again
              </PTag>
            )}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

const PTag = styled.p`
  text-align: center;
  padding: 10vh 0;
  font-size: 12px;
`;
const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #f0f0f0;
  border-radius: 3px;
  margin: 5px 0;
  padding: 14px 16px;
  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 0px;
    img {
      width: 30px;
      height: 30px;
      margin-right: 10px;
    }
    div {
      display: flex;
      flex-direction: column;
      align-items: start;
      border: 0px;
      p {
        font-size: 12px;
        color: #1f1f1f;
        font-weight: 700;
      }
      span {
        font-size: 10px;
        color: #8c8c8c;
        font-weight: 500;
        display: block;
      }
    }
  }
`;
