import React, { FC } from "react";
import { useSelector } from "react-redux";
import cookies from "js-cookie";
import { selectCurrentUser } from "features/auth/authSlice";
import { useNavigate } from "react-router-dom";
import HomeSpotifyPlaylist from "./HomeSpotifyPlaylist";
import HomeYoutubePlaylist from "./HomeYoutubePlaylist";
import { HomeApplePlaylists } from "./HomePlaylistsApple";

interface PlaylistsProps {
  playlists: any;
  applemusicPlaylists: any;
  youtubePlaylists: any;
  clickedItems: any;
  setClickedItems: any;
  initiateCopy: any;
}

const HomePlaylists: FC<PlaylistsProps> = ({
  playlists,
  applemusicPlaylists,
  youtubePlaylists,
  clickedItems,
  setClickedItems,
  initiateCopy
}) => {
  const userr = useSelector(selectCurrentUser);
  const user =
    userr !== null ? userr : JSON.parse(cookies.get("playa_user") as string);
  const navigate = useNavigate();

  return (
    <>
      {user.isSpotifyConnected &&
        user.primaryStreamingService === "spotify" &&
        playlists &&
        Object.keys(playlists) && <HomeSpotifyPlaylist playlists={playlists} clickedItems={clickedItems} setClickedItems={setClickedItems} initiateCopy={initiateCopy} />}

      {user.isGoogleConnected &&
        user.primaryStreamingService === "youtube" &&
        youtubePlaylists?.length > 0 && (
          <HomeYoutubePlaylist playlists={youtubePlaylists} clickedItems={clickedItems} setClickedItems={setClickedItems} initiateCopy={initiateCopy} />
        )}

      {user.isAppleConnected &&
        user.primaryStreamingService === "apple" &&
        Array.isArray(applemusicPlaylists) &&
        applemusicPlaylists.length > 0 && (
          <HomeApplePlaylists
            playlists={applemusicPlaylists}
            clickedItems={clickedItems} 
            setClickedItems={setClickedItems} 
            initiateCopy={initiateCopy}
          />
        )}
    </>
  );
};

export default HomePlaylists;
