/* eslint-disable no-undef */
import styled from "styled-components";

import PlaceholderImg from "../assets/placeholder.png";
import { useNavigate, useParams } from "react-router-dom";
import { useGetRoomQuery } from "features/auth/authApiSlice";
import { YouTubePlayer } from "./YoutubePlayer";
import {
  LikeIcon,
  NextIcon,
  PauseIcon,
  PlayIcon,
  PlayerIcon,
  PreviousIcon,
  ShuffleIcon,
} from "icons";
import ViewPlayListScreen from "screens/ViewPlayListScreen";
import { useEffect, useState } from "react";
import { LoadingScreen } from "./common/LoadingScreen";

export const WebplaybackYoutube = ({ isOwner, playlist, is_paused }) => {
  const navigate = useNavigate();
  const params = useParams();
  const { roomId } = params;
  const { data } = useGetRoomQuery(roomId);
  const [showPlaylist, setShowPlaylist] = useState(false);

  const [isPlaying, setIsplaying] = useState(true);

  const [player, setPlayer] = useState();

  const [songDetails, setSongDetails] = useState();

  useEffect(() => {
    console.log("hiii");

    console.log(songDetails === undefined, "song details");
    console.log(
      "Player state after pause:",
      player && player.getPlayerState && player.getPlayerState()
    );
  }, [songDetails, player]);

  const onPlay = () => {
    if (player && player.playVideo) {
      //
      player.playVideo();
      setIsplaying(true);
    }
  };

  const onPause = () => {
    if (player && player.pauseVideo) {
      player.pauseVideo();
      setIsplaying(false);
    }
  };

  const onNext = () => {
    if (player && player.nextVideo) {
      player.nextVideo();
    }
  };

  const onPrev = () => {
    if (player && player.previousVideo) {
      player.previousVideo();
    }
  };

  const onShuffle = () => {
    if (player && player.setShuffle) {
      player.setShuffle(true);
    }
  };

  return (
    <TrackContainer>
      <YouTubePlayer
        playlistId={playlist}
        player={player}
        isPlaying={isPlaying}
        setPlayer={setPlayer}
        setIsplaying={setIsplaying}
        setSongDetails={setSongDetails}
      />
      {songDetails ? (
        <>
          <TrackCoverContainer>
            {playlist ? (
              <img
                src={
                  songDetails?.video_id
                    ? `https://img.youtube.com/vi/${songDetails?.video_id}/0.jpg`
                    : ""
                }
                alt="playlist cover"
                width={320}
                height={180}
              />
            ) : (
              <img src={PlaceholderImg} alt="playlist cover" />
            )}
          </TrackCoverContainer>
          {isOwner && (
            <PlayerControlsContainer>
              <div onClick={() => onShuffle()}>
                <ShuffleIcon />
              </div>
              <div
                onClick={() => {
                  onPrev();
                }}
              >
                <PreviousIcon />
              </div>
              <div>
                {!isPlaying ? (
                  <div
                    id="play-button"
                    onClick={() => {
                      onPlay();
                    }}
                  >
                    <PlayIcon />
                  </div>
                ) : (
                  <div
                    id="pause-button"
                    onClick={() => {
                      onPause();
                    }}
                  >
                    <PauseIcon />
                  </div>
                )}
              </div>

              <div
                onClick={() => {
                  onNext();
                }}
              >
                <NextIcon />
              </div>

              <div onClick={() => setShowPlaylist(!showPlaylist)}>
                <PlayerIcon />
              </div>
            </PlayerControlsContainer>
          )}

          <TrackDescription>
            <h5>{songDetails?.title}</h5>
            <Artist>
              <h6>{songDetails?.author}</h6>
              {/* <LikeIcon /> */}
            </Artist>
          </TrackDescription>
          {showPlaylist && (
            <div
              style={{
                position: "absolute",
                zIndex: 100,
                top: 0,
                left: 0,
                backgroundColor: "#ffffff",
                width: "100%",
              }}
            >
              <ViewPlayListScreen
                current_track={{
                  album: {
                    images: [
                      {
                        url: songDetails?.video_id
                          ? `https://img.youtube.com/vi/${songDetails?.video_id}/0.jpg`
                          : "",
                      },
                    ],
                  },
                }}
                setShowPlaylist={setShowPlaylist}
              />
            </div>
          )}
        </>
      ) : (
        <LoadingScreen />
      )}
    </TrackContainer>
  );
};

const PlayerControlsContainer = styled.div`
  margin-top: 40px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const TrackContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
`;

const TrackCoverContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;

  img {
    border-radius: 14px;
    object-fit: cover;
    object-position: center;
    /* width: 100%; */
  }
`;

const TrackDescription = styled.div`
  display: flex;
  flex-direction: column;

  h5 {
    color: #1f1f1f;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
  }

  h6 {
    color: #8c8c8c;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }
`;

const Artist = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
