import { CloseIcon } from "icons";
import React, { useState } from "react";
import styled from "styled-components";

const RecentSearches = () => {
  const [recentSearches, setRecentSearches] = useState([
    {
      id: 1,
      title: "TheNinth",
    },
    {
      id: 2,
      title: "Azeez",
    },
    {
      id: 3,
      title: "Jabbar",
    },
  ]);

  const closeRecentSearches = (id: number) => {
    const updatedSeaarchData = recentSearches.filter(
      (search) => search.id !== id,
    );
    setRecentSearches(updatedSeaarchData);
  };
  return (
    <RecentSearchContainer>
      <h6>Recent searches</h6>
      {recentSearches &&
        recentSearches.length > 0 &&
        recentSearches.map((item) => (
          <RecentSearchContent key={item.id}>
            <h6>{item.title}</h6>
            <div onClick={() => closeRecentSearches(item.id)}>
              <CloseIcon />
            </div>
          </RecentSearchContent>
        ))}
    </RecentSearchContainer>
  );
};

export default RecentSearches;

const RecentSearchContainer = styled.section`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 16px;

  h6 {
    font-family: Satoshi;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    color: #8c8c8c;
  }
`;

const RecentSearchContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  h6 {
    font-family: Satoshi;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    color: #1f1f1f;
  }
`;
