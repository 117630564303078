import React from "react";
export const PlayaLogo = () => (
  <svg
    width="111"
    height="32"
    viewBox="0 0 111 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_726_789)">
      <path
        d="M42.7634 16.9555C46.777 16.9555 48.596 15.7426 48.596 13.4907C48.596 11.2095 46.777 9.91002 42.7634 9.91002H38.8653V16.9555H42.7634ZM36.9352 26.08H34.938V6.79831C34.938 6.47556 35.1996 6.21396 35.5223 6.21396H42.763C47.7007 6.21396 52.5229 7.80221 52.5229 13.4907C52.5229 19.15 47.7011 20.6515 42.763 20.6515H38.8649V24.1504C38.8649 25.2162 38.0007 26.08 36.9352 26.08Z"
        fill="#0F2F40"
      />
      <path
        d="M56.7675 26.0231H54.6949V7.97819C54.6949 6.95769 55.4943 6.11619 56.5136 6.06355L58.6218 5.95502V24.1688C58.6218 25.193 57.7917 26.0231 56.7675 26.0231Z"
        fill="#0F2F40"
      />
      <path
        d="M73.0595 21.6917V15.8299C71.9625 15.3966 70.3742 15.0216 69.1326 15.0216C64.917 15.0216 64.7149 17.6782 64.7149 18.7177C64.7149 19.7572 64.917 22.4137 69.1326 22.4137C70.6921 22.4137 72.0491 22.0671 73.0595 21.6917ZM73.377 25.2144C72.0779 25.734 70.4605 26.1385 68.8435 26.1385C63.9058 26.1385 60.7872 23.1641 60.7872 18.7177C60.7872 14.2708 63.8192 11.2968 68.7856 11.2968C70.3451 11.2968 72.0775 11.7876 73.3766 12.3076L73.5212 11.528L75.831 11.6447C76.462 11.6767 76.9572 12.1974 76.9572 12.8292V24.7706C76.9572 25.4627 76.3964 26.0235 75.7043 26.0235H73.5212L73.3766 25.2152L73.377 25.2144Z"
        fill="#0F2F40"
      />
      <path
        d="M84.6648 31.9999H81.7795L84.4939 26.0227H84.0027L78.4301 11.701L81.1327 11.5884C81.9965 11.5523 82.7813 12.0873 83.0644 12.9041L86.2551 22.1246L89.9511 11.701L92.2282 11.6066C93.1313 11.5694 93.7772 12.4688 93.4536 13.3123L86.8641 30.4878C86.5146 31.3986 85.6403 31.9995 84.6648 31.9995V31.9999Z"
        fill="#0F2F40"
      />
      <path
        d="M107.103 21.6917V15.8299C106.005 15.3966 104.417 15.0216 103.175 15.0216C98.9598 15.0216 98.7577 17.6782 98.7577 18.7177C98.7577 19.7572 98.9598 22.4137 103.175 22.4137C104.735 22.4137 106.092 22.0671 107.103 21.6917ZM107.42 25.2144C106.121 25.734 104.504 26.1385 102.887 26.1385C97.949 26.1385 94.8304 23.1641 94.8304 18.7177C94.8304 14.2708 97.8624 11.2968 102.829 11.2968C104.388 11.2968 106.12 11.7876 107.42 12.3076L107.564 11.528L109.709 11.6362C110.433 11.6726 111 12.2699 111 12.9948V24.6633C111 25.4145 110.391 26.0235 109.64 26.0235H107.564L107.42 25.2152L107.42 25.2144Z"
        fill="#0F2F40"
      />
      <path
        d="M16.3972 16.8869L10.2099 20.5315C9.47244 20.966 8.54144 20.4363 8.53779 19.5803L8.50783 12.3428C8.50418 11.4867 9.43073 10.9498 10.1718 11.3778L16.3891 14.971C17.1245 15.3958 17.129 16.456 16.3972 16.8869ZM23.3625 9.99771C22.6073 10.0001 21.886 10.1451 21.2219 10.4043C20.6327 10.5452 20.0188 10.6222 19.3866 10.6242C15.9125 10.6351 12.9527 8.42323 11.8471 5.32814C11.7042 4.50971 11.5114 3.70709 11.0736 2.95467C9.42465 0.119152 5.78893 -0.842624 2.95341 0.806367C0.11749 2.45576 -0.844287 6.09108 0.804705 8.9266C1.12179 9.47127 1.51217 9.94588 1.9556 10.3472C3.42722 11.7897 4.34607 13.7946 4.35295 16.0182C4.35943 18.1115 3.55802 20.0172 2.24393 21.4447C1.69723 21.8909 1.221 22.4417 0.84925 23.0904C-0.781923 25.9365 0.202936 29.5657 3.04858 31.1973C5.89463 32.8285 9.52387 31.8436 11.155 28.9976C11.3579 28.644 11.5199 28.278 11.6434 27.9058C11.6442 27.9038 11.6446 27.9017 11.6454 27.8997C11.6454 27.8993 11.6454 27.8989 11.6459 27.8989C11.6831 27.7985 11.7086 27.7078 11.7289 27.6235C11.7548 27.5292 11.8929 27.1015 11.9326 26.974C12.9344 23.7254 15.9542 21.3596 19.5328 21.3483C20.2735 21.3459 20.9903 21.4467 21.6714 21.6334L21.6726 21.6273C22.2193 21.7913 22.7988 21.8792 23.399 21.8776C26.6791 21.8675 29.33 19.1996 29.3195 15.919C29.3093 12.6389 26.6415 9.98799 23.3613 9.99852L23.3625 9.99771Z"
        fill="#0F2F40"
      />
    </g>
    <defs>
      <clipPath id="clip0_726_789">
        <rect
          width="111.002"
          height="32"
          fill="white"
          transform="translate(-0.000732422)"
        />
      </clipPath>
    </defs>
  </svg>
);
