import React from "react";
import styled from "styled-components";
import SearchResultImageOne from "../assets/notification-image-one.png";
import SearchResultImageTwo from "../assets/notification-image-two.png";
import SearchResultImageThree from "../assets/notification-image-three.png";
import { LikeIcon } from "icons";

const SongsSearchResult = () => {
  const searchResult = [
    {
      id: 1,
      title: "Machalla",
      artist: "Carter Ee",
      img: SearchResultImageOne,
      isLiked: true,
    },
    {
      id: 2,
      title: "If",
      artist: "Davido",
      img: SearchResultImageTwo,
      isLiked: true,
    },
    {
      id: 3,
      title: "Hello",
      artist: "Adele",
      img: SearchResultImageThree,
      isLiked: true,
    },
    {
      id: 4,
      title: "A1 (Feeling Disorder)",
      artist: "Zino",
      img: SearchResultImageOne,
      isLiked: false,
    },
  ];
  return (
    <SearchResultContentContainer>
      {searchResult &&
        searchResult.length > 0 &&
        searchResult.map((item) => (
          <SearchResultContent key={item.id}>
            <SearchResultContentLeft>
              <img src={item?.img} alt="Search Result Poster"></img>
              <div>
                <h6>{item?.title}</h6>
                <p>{item?.artist}</p>
              </div>
            </SearchResultContentLeft>
            <LikeIcon />
          </SearchResultContent>
        ))}
    </SearchResultContentContainer>
  );
};

export default SongsSearchResult;

const SearchResultContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 20px;
`;

const SearchResultContent = styled.div`
  padding: 12px 16px;
  border: solid 1px #f0f0f0;
  border-radius: 3px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  img {
    width: 34px;
    height: 34px;
  }
`;

const SearchResultContentLeft = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  h6 {
    font-family: Satoshi;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    color: #1f1f1f;
  }
  p {
    font-family: Satoshi;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    color: #8c8c8c;
  }
`;
