/* eslint-disable react-hooks/exhaustive-deps */
//@ts-nocheck
import React, { useCallback, useContext, useEffect, useState } from "react";
import { ScreenTitleContainer } from "./ProfileScreen";
import { MusicKitContext } from "components/MusicKitController";
import { ScreenTitle } from "components/ScreenTitle";
import styled from "@emotion/styled";
import { Chat } from "components/Chat";
import {
  ChatUsersIcon,
  ChevronIcon,
  CloseIcon,
  LikeIcon,
  NextIcon,
  PauseIcon,
  PlayIcon,
  PlayerIcon,
  PreviousIcon,
  ShareMusicIcon,
  ShuffleIcon,
} from "icons";
import ChatAvatar from "assets/smallprofileImg.png";
import PlaceholderImg from "assets/placeholder.png";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  clearRoom,
  globalPlayer,
  setCardDetails,
  setGlobalPlayer,
} from "app/api/otherSlice";
import BottomModal from "components/Home/BottomModal";
import { BgOverlay } from "./HomeScreen";
import { IoPowerOutline } from "react-icons/io5";
import { SocketContext } from "components/socket";
import { selectCurrentUser } from "features/auth/authSlice";
import cookies from "js-cookie";
import { Socket } from "socket.io-client";
import {
  useAddCommentMutation,
  useGetRoomQuery,
} from "features/auth/authApiSlice";
import { shuffleArray } from "utils";
import ViewPlayListScreen from "./ViewPlayListScreen";
import toast, { Toaster } from "react-hot-toast";

export const RoomOwnerAppleMusic = ({ token }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [listeners, setListeners] = useState(0);
  const [showPlaylist, setShowPlaylist] = useState(false);
  const [chatCount, setChatCount] = useState(0);
  const socket = useContext(SocketContext) as Socket;
  const player = useSelector(globalPlayer);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const closeModal = () => {
    setOpenModal(false);
  };
  const updateCard = (cardDetails: any) => {
    dispatch(setCardDetails({ cardDetails }));
  };
  const params = useParams();
  const { playlistId } = params;

  const context = useContext(MusicKitContext);

  const [isPaused, setIsPaused] = useState<boolean>(false);
  const [queueLength, setQueueLength] = useState(0);
  const [songs, setSongs] = useState<any[]>([]);
  const [startIndex, setStartIndex] = useState(0);
  const userr = useSelector(selectCurrentUser);
  const [isShuffled, setIsShuffled] = useState<boolean>(false);
  const user =
    userr !== null ? userr : JSON.parse(cookies.get("playa_user") as string);

  const [addComment] = useAddCommentMutation();

  const location = useLocation();

  const playSong = async () => {
    setIsPaused(false);
    await player?.play();
  };
  const pauseSong = async () => {
    setIsPaused(true);
    await player?.pause();
  };
  const nextSong = useCallback(async () => {
    const index =
      startIndex > queueLength - 1 ? queueLength - 1 : startIndex + 1;
    console.log(index, "next clicked");
    await player?.changeToMediaAtIndex(index);
    setStartIndex(index);
  });
  const prevSong = useCallback(async () => {
    const index = startIndex <= 0 ? 0 : startIndex - 1;
    console.log(index, "previous clicked");

    await player?.changeToMediaAtIndex(index);
    setStartIndex(index);
  });
  const shufflePlaylist = async () => {
    setIsShuffled(!isShuffled);
    player._player.shuffleMode = player._player.shuffleMode === 0 ? 1 : 0;
  };

  const stopSong = useCallback(async () => {
    await player?.stop();
    navigate("/home");
  });

  useEffect(() => {
    dispatch(setGlobalPlayer({ player: context.instance }));
    const PlayTracks = async () => {
      try {
        const queue = await player?.setQueue({
          playlist: playlistId,
          startPlaying: true,
        });
        setQueueLength(queue?.items?.length - 1);
        setSongs(queue?.items);

        await player?.play(queue?.items);
        setStartIndex(player?.player?.queue?.position);
        setIsPaused(false);
      } catch (err) {
        console.error(`Apple Music API returned: ${err}`);
      }
    };

    PlayTracks();
  }, [context, player, isShuffled]);

  useEffect(() => {
    if (songs?.length > 0) {
      const roomDetails = {
        songAuthor:
          songs[player?.player?.queue?.position]?.attributes?.name || "Unknown",
        songTitle:
          songs[player?.player?.queue?.position]?.attributes?.artistName ||
          "Unknown",
        comments: chatCount,
        image: songs[player?.player?.queue?.position]?.attributes?.artwork?.url,
        player: player,
        IsRoomOwner: true,
        roomName:location?.state?.playlistName,
        songs: songs,
        app: "apple",
        currentIndex: player?.player?.queue?.position,
        next: nextSong,
        previous: prevSong,
        play: playSong,
        pause: pauseSong,
        stop: stopSong,
      };
      updateCard(roomDetails);
    }
  }, [
    songs,
    player,
    startIndex,
    chatCount,

    nextSong,
    prevSong,
    playSong,
    pauseSong,
    stopSong,
    updateCard,
  ]);

  return (
    <section>
      <ScreenTitleContainer>
        <ScreenTitle
          title={location?.state?.playlistName || ""}
          icon={<ChevronIcon />}
        />

        <IoPowerOutline
          color="#0F2F40"
          size={20}
          onClick={() => setOpenModal(true)}
        />
      </ScreenTitleContainer>

      {/* banner */}
      <BannerContainer>
        <img
        src={PlaceholderImg}
          // src={
          //   player && songs[player?.player?.queue?.position]?.attributes?.artwork?.url
          //     ? songs[player?.player?.queue?.position]?.attributes?.artwork?.url
          //     : PlaceholderImg
          // }
          alt="track cover"
        />
      </BannerContainer>

      {/* controls */}

      <PlayerControlsContainer>
        <div onClick={shufflePlaylist}>
          <ShuffleIcon color={isShuffled ? "#008135" : null} />
        </div>
        <div onClick={player?.player?.queue?.position === 0 ? null : prevSong}>
          <PreviousIcon
            color={player?.player?.queue?.position === 0 ? "#ccc" : null}
          />
        </div>
        <div>
          {isPaused ? (
            <div onClick={playSong}>
              <PlayIcon />
            </div>
          ) : (
            <div onClick={pauseSong}>
              <PauseIcon />
            </div>
          )}
        </div>

        <div
          onClick={
            player?.player?.queue?.position === songs?.length - 1
              ? null
              : nextSong
          }
        >
          <NextIcon
            color={
              player?.player?.queue?.position === songs?.length - 1
                ? "#ccc"
                : null
            }
          />
        </div>

        {/* <div onClick={() => navigate(`/viewplaylist/${data?.data?.room?._id}`)}> */}
        <div onClick={() => setShowPlaylist(!showPlaylist)}>
          <PlayerIcon />
        </div>
      </PlayerControlsContainer>
      <TrackDescription>
        <h5>{player &&songs && songs[player?.player?.queue?.position]?.attributes?.name}</h5>
        <Artist>
          <h6>
            {player &&songs && songs[player?.player?.queue?.position]?.attributes?.artistName}
          </h6>
        </Artist>
      </TrackDescription>
      {showPlaylist && (
        <div
          style={{
            position: "absolute",
            zIndex: 100,
            top: 0,
            left: 0,
            backgroundColor: "#ffffff",
            width: "100%",
          }}
        >
          <ViewPlayListScreen
            current_track={{
              album: {
                images: [
                  {
                    url: songs[player?.player?.queue?.position]?.attributes
                      ?.artwork?.url,
                  },
                ],
              },
            }}
            context={context}
            setShowPlaylist={setShowPlaylist}
          />
        </div>
      )}

      {openModal ? (
        <>
          <BottomModal
            title={`End ${location?.state?.playlistName} session`}
            bodyText={`Are you sure you want to close ${location?.state?.playlistName}?`}
            closeModal={closeModal}
            actionBtn="End"
            callBack={stopSong}
            exitBtn="Cancel"
            icontype="close"
          />
          <BgOverlay />
        </>
      ) : null}

      <Toaster />
    </section>
  );
};

const TrackDescription = styled.div`
  display: flex;
  flex-direction: column;

  h5 {
    color: #1f1f1f;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
  }

  h6 {
    color: #8c8c8c;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }
`;

const Artist = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const PlayerControlsContainer = styled.div`
  margin-top: 40px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const RoomDetailsContainer = styled.div`
  p {
    color: #262626;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }

  button {
    display: flex;
    width: 76px;
    height: 30px;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 32px;
    background: #008135;
    color: var(--neutral-gray-2, #fafafa);
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    border: none;
    margin-top: 20px;
  }
`;

const BannerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  img {
    border-radius: 14px;
    object-fit: cover;
    width: 50%;
    object-position: center;
    /* width: 100%; */
  }
`;
const RoomChatHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  border-bottom: 1px solid #d9d9d9;

  span {
    display: flex;
    flex-direction: row;
    align-items: center;
    p {
      font-family: Satoshi;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      margin: 5px;
    }
    span {
      display: flex;
      flex-direction: row;
      align-items: center;

      p {
        font-family: Satoshi;
        font-size: 12px;
        font-style: normal;
      }
    }
  }
`;
