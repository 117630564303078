import React, { FC } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "features/auth/authSlice";
import { useGetRoomQuery } from "features/auth/authApiSlice";
import { Layout } from "components/Layout";
import "styles/spotify.scss";
import { LoadingScreen } from "components/common/LoadingScreen";
import { RoomOwnerAppleMusic } from "screens/RoomOwnerScreenAppleMusic";
import NonRoomOwnerAppleScreen from "screens/NonRoomOwnerAppleScreen";
import cookies from "js-cookie";
interface AppleMusicProps {
  token: string;
}

export const AppleMusic: FC<AppleMusicProps> = ({ token }) => {
  // const user = useSelector(selectCurrentUser);
  const userr = useSelector(selectCurrentUser);
  const user =
    userr !== null ? userr : JSON.parse(cookies.get("playa_user") as string);

  const params = useParams();
  const { playlistId } = params;


    return (
      <Layout>
        <RoomOwnerAppleMusic token={token} />
      </Layout>
    );
};
