//@ts-nocheck
import { useNavigate, Navigate, useLocation } from "react-router-dom";
import styles from "styles/confirmpassword.module.scss";
import { BackIcon } from "icons/BackIcon";
import { PiEyeThin } from "react-icons/pi";
import { PasswordToggleIcon } from "icons/PasswordToggleIcon";
import { useState } from "react";
import { ErrorIcon } from "icons/ErrorIcon";
import { LoadingScreen } from "components/common/LoadingScreen";
import { useUpdatePasswordMutation } from "features/auth/authApiSlice";

const ConfirmPasswordScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [updatePassword, { isLoading, isSuccess }] =
    useUpdatePasswordMutation();

  const defaultFormState = {
    password: { value: "", error: null },
    confirmPassword: { value: "", error: null },
  };

  const [formState, setFormState] = useState(defaultFormState);
  const [isPassword, setIsPassword] = useState<boolean>(true);
  const [isConfirmPassword, setIsConfirmPassword] = useState<boolean>(true);

  const onChangeHandler = (field, value) => {
    setFormState({
      ...formState,
      [field]: {
        value: value,
        error: null,
      },
    });
  };

  const handleSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    let hasErrors = handleFormValidations();

    const updatedState = { ...formState };

    const { password } = updatedState;

    const formData = {
      password: password.value,
      email: location.state,
    };

    if (hasErrors) {
      return;
    }

    await updatePassword(formData);
    setFormState({
      password: { value: "", error: null },
      confirmPassword: { value: "", error: null },
    });
  };

  const handleFormValidations = () => {
    let updatedState = { ...formState };
    let error = false;

    const { password, confirmPassword } = updatedState;

    if (
      password?.value &&
      confirmPassword?.value &&
      password?.value !== confirmPassword?.value
    ) {
      updatedState.confirmPassword.error = "Passwords do not match";
      error = true;
    }

    if (!password?.value) {
      updatedState.password.error = "Enter a password";
      error = true;
    }

    if (password?.value && password?.value?.length < 8) {
      updatedState.password.error = "Password cannot be less than 8 characters";
      error = true;
    }
    if (!confirmPassword?.value) {
      updatedState.confirmPassword.error = "Enter Confirm Password";
      error = true;
    }

    setFormState({
      ...formState,
      ...updatedState,
    });
    return error;
  };

  if (isSuccess) return <Navigate to="/login" />;

  return (
    <section className={styles.forgetPasswordScreenWrapper}>
      <div className={styles.forgetPasswordScreenContainer}>
        <div
          onClick={() => navigate(-1)}
          className={styles.forgetPasswordCtaContainer}
        >
          <BackIcon onClick={() => navigate(-1)} />
          <h5> Reset Password</h5>
        </div>

        {isLoading && <LoadingScreen />}
        <form className={styles.resetPasswordForm} onSubmit={handleSubmit}>
          <div className={styles.forgetPasswordTextInputContainer}>
            <label htmlFor="password">New password</label>
            <div className={styles.passwordToggeWrapper}>
              <input
                value={formState.password?.value}
                onChange={(e) => onChangeHandler("password", e.target.value)}
                className={`${
                  formState.password.error
                    ? `textInputErrorState`
                    : styles.signupTextInput
                } textInput`}
                placeholder="min. 8 characters"
                type={isPassword ? "password" : "text"}
                id="password"
              />
              <div
                onClick={() => setIsPassword(!isPassword)}
                className={styles.passwordToggleIconWrapper}
              >
                {isPassword ? <PasswordToggleIcon /> : <PiEyeThin />}
              </div>
            </div>
            {formState?.password?.error && (
              <div className="errorStateContainer">
                <ErrorIcon />{" "}
                <small className="error">{formState.password.error}</small>
              </div>
            )}
          </div>
          <div className={styles.forgetPasswordTextInputContainer}>
            <label htmlFor="confirmPassword">Confirm password</label>
            <div className={styles.passwordToggeWrapper}>
              <input
                value={formState.confirmPassword?.value}
                onChange={(e) =>
                  onChangeHandler("confirmPassword", e.target.value)
                }
                className={`${
                  formState.confirmPassword.error
                    ? `textInputErrorState`
                    : styles.signupTextInput
                } textInput`}
                placeholder="Confirm "
                type={isConfirmPassword ? "password" : "text"}
                id="confirmPassword"
              />
              <div
                onClick={() => setIsConfirmPassword(!isConfirmPassword)}
                className={styles.passwordToggleIconWrapper}
              >
                {isPassword ? <PasswordToggleIcon /> : <PiEyeThin />}
              </div>
            </div>
            {formState?.confirmPassword?.error && (
              <div className="errorStateContainer">
                <ErrorIcon />{" "}
                <small className="error">
                  {formState.confirmPassword.error}
                </small>
              </div>
            )}
          </div>

          <div className={styles.forgetPasswordButtonContainer}>
            <button type="submit" className="primaryButton">
              Confirm Password
            </button>
          </div>
        </form>
      </div>
    </section>
  );
};

export default ConfirmPasswordScreen;
