import React from "react";
import styled from "styled-components";
import { RiCloseCircleLine } from "react-icons/ri";
import {
  AppleMusicIcon,
  AudiomacIcon,
  SportifyIcon,
  YoutubeMusicIcon,
} from "icons/musicServicesIcon";
interface ModalProps {
  closeModal: () => void;
  mainText: string;
  mainTextTwo: string;
  closeText: string;
  app: string;
  callBack?: () => void;
  callBackTwo?: () => void;
}

const BottomModalTwo: React.FC<ModalProps> = ({
  closeModal,
  mainText,
  app,
  mainTextTwo,
  closeText,
  callBack,
  callBackTwo,
}) => {
  const selectIcon = (app: string) => {
    switch (app) {
      case "spotify":
        return <SportifyIcon />;
      case "apple":
        return <AppleMusicIcon />;
      case "youtube":
        return <YoutubeMusicIcon />;
      case "audiomack":
        return <AudiomacIcon />;
      default:
        return null;
    }
  };
  return (
    <BottomModalContainer>
      <BottomModalContainerContent>
        {selectIcon(app)}
        {mainText === "" ? null : (
          <BottomModalAction onClick={callBack}>{mainText}</BottomModalAction>
        )}
        <BottomModalDisconnectAction onClick={callBackTwo}>
          {mainTextTwo}
        </BottomModalDisconnectAction>
        <ModalAction>
          <BottomModalCancelAction onClick={closeModal}>
            {closeText}
          </BottomModalCancelAction>
        </ModalAction>
      </BottomModalContainerContent>
    </BottomModalContainer>
  );
};

export default BottomModalTwo;

const BottomModalContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 10px;
  position: fixed;
  bottom: 10%;
  right: 0;
  left: 0;
  border-radius: 27px;
  z-index: 1000;
`;

const BottomModalContainerContent = styled.div`
  background: #ffffff;
  width: 100%;
  padding: 16px;
  border-radius: 8px;
  z-index: 1000;

  h2 {
    color: #d92d20;
    font-family: Satoshi;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  p {
    font-family: Satoshi;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    color: #595959;
    margin-bottom: 32px;
  }
`;

const BottomModalCancelAction = styled.button`
  outline: none;
  border: none;
  background: transparent;
  color: #5f7f90;
  font-family: Satoshi;
  font-size: 14px;
  font-weight: 700;

  line-height: 22px;
`;

const BottomModalDisconnectAction = styled.button`
  outline: none;
  border: none;
  background: transparent;
  color: #d92d20;
  width: 100%;
  padding: 12px 20px;
  border-radius: 100px;
  font-family: Satoshi;
  font-size: 14px;
  font-weight: 700;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  line-height: 22px;
`;

const BottomModalAction = styled.button`
  outline: none;
  border: none;
  background: #008135;
  color: white;
  width: 100%;
  margin-top: 20px;
  padding: 12px 20px;
  border-radius: 100px;
  font-family: Satoshi;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const ModalAction = styled.div`
  display: flex;
  gap: 24px;
  justify-content: right;
`;
