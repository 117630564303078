import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ScreenTitleContainer } from "./ProfileScreen";
import PlaceholderImg from "../assets/placeholder.png";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import {
  ChevronIcon,
  CopyIcon,
  LikeIcon,
  ShareMusicIcon,
  SyncedIcon,
} from "icons";
import { Search } from "components/Search";
import { Layout } from "components/Layout";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "features/auth/authSlice";
import { useGetRoomQuery, useSharePlaylistMutation } from "features/auth/authApiSlice";
import { DraggableCard } from "components/DraggableCard";
import cookies from "js-cookie";
import { getAccessToken, getPlaylistTracks } from "services/spotify";
import { LoadingScreen } from "components/common/LoadingScreen";
import { AnyComponent } from "styled-components/dist/types";
import {
  getAllTracksInYoutubePlaylist,
  getAllUserYoutubePlaylists,
  GOOGLE_LOCALSTORAGE_VALUES,
} from "services/youtube";
import CopyBottomModal from "components/Playlist/CopyBottomModal";
import { BgOverlay } from "./HomeScreen";
import ProgressBottomModal from "components/Playlist/ProgressBottomModal";
import UpgradeBottomModal from "components/Playlist/UpgradeBottomModal";
import CompletedBottomModal from "components/Playlist/CompletedBottomModal";
import { getPreviewUrl, youtubeToSpotify } from "services/conversion";
import toast, { Toaster } from "react-hot-toast";
import { useDispatch } from "react-redux";
import {
  clearTotalTracks,
  totalTracks,
} from "features/tracks/tracksTotalSlice";
import { clearCopied, copied } from "features/tracks/copiedTracksSlice";
import { IYoutubePlayLists, OutputType } from "@types";
import { PlaylistType } from "types";
import { PRIMARY_STREAM_SERVICE } from "contants/primaryStreamingService";
import HomeCopyInitiate from "components/Home/CopyInitiate";
import { clearFailedTracks } from "features/tracks/failedTracksSlice";
import { clearTracks } from "features/tracks/tracksSlice";
import SelectPlaylistBottomModal from "components/ManagePlaylist/DestinationPlatformModal";

// songList.items.map(song=>{
// song.track.id, song.track.name, song.track.artists[0].name, song.track.album.images[0].url
// })
type ViewListProps = {
  current_track: any;
  context?: any;
  setShowPlaylist: React.Dispatch<React.SetStateAction<boolean>>;
};
const ViewPlayListScreen = ({
  current_track,
  context,
  setShowPlaylist,
}: ViewListProps) => {
  // const user = useSelector(selectCurrentUser);
  const userr = useSelector(selectCurrentUser);
  const user =
    userr !== null ? userr : JSON.parse(cookies.get("playa_user") as string);
  const params = useParams();
  const { playlistId } = params;
  const [songList, setSongList] = useState<any>();
  const [originalSongList, setOriginalSongList] = useState<any>();
  const [filteredSongList, setFilteredSongList] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [copyModal, setcopyModal] = useState<boolean>(false);
  const location = useLocation();
  const [clickedItems, setClickedItems] = useState<PlaylistType[]>([]);
  const [copyPlaylistOngoing, setCopyPlaylistOngoing] =
    useState<boolean>(false);
  const [syncPlaylistOngoing, setSyncPlaylistOngoing] =
    useState<boolean>(false);
  const [actionCompleted, setActionCompleted] = useState<boolean>(false);
  const [playListArr, setPlayListArr] = useState<OutputType>([]);
  const totalTrackss = useSelector(totalTracks);
  const totalCopied = useSelector(copied);
  const [isFetchingPlaylist, setFetchingPlaylist] = useState<boolean>(true);
  const [progress, setProgress] = useState<number>(0);
  const [seeDetails, setSeeDetails] = useState<boolean>(true);
  const dispatch = useDispatch();
  const [hideModal, setHideModal] = useState<boolean>(false);
  const [initiateCopy, setInitiateCopy] = useState<boolean | null>(null);
  const [handleCopy, setHandleCopy] = useState<boolean | null>(null);
  const [sharableUrl, setShareAbleUrl] = useState<string | undefined>(
    undefined
  );

  const appType = user?.primaryStreamingService;
  const { playlistName } = location?.state;

  const closeModal = () => {
    setcopyModal(!copyModal);
  };

  const selectPlaylistToBeCopied = (name: string, id: any) => {
    setClickedItems([{ playlistName: name, playlistId: id }]);
    setInitiateCopy(true);
  };

  const spotifyPlayListTracks = async () => {
    const { data: tracks } = await getPlaylistTracks(playlistId);
    tracks && setSongList(tracks.items);
    tracks && setOriginalSongList(tracks.items);
    !tracks && setSongList([]);
    setLoading(false);
    return tracks.items;
  };
  // playlistId
  const youtubePlayListTracks = async () => {
    const { tracks } = await getAllTracksInYoutubePlaylist(playlistId);
    console.log(tracks, "---data tracks youtube");
    tracks && setSongList(tracks);
    tracks && setOriginalSongList(tracks);
    !tracks && setSongList([]);
    setLoading(false);
  };

  const applePlayListTracks = async () => {
    const tracks =
      context && (await context?.getAppleMusicPlaylistTracks(playlistId));
    tracks && setSongList(tracks);
    tracks && setOriginalSongList(tracks);
    !tracks && setSongList([]);
    setLoading(false);
  };

  const playlistTracks = () => {
    switch (appType) {
      case "youtube":
        youtubePlayListTracks();
        break;
      case "spotify":
        spotifyPlayListTracks();
        break;
      case "apple":
        applePlayListTracks();
        break;
      case "library-playlists":
        applePlayListTracks();
        break;
      default:
        break;
    }
  };

  const handleHideModals = () => {
    setHideModal(true);
  }
  
  const copyMusic = async () => {
    const { status, playlists } = await getAllUserYoutubePlaylists();
  };

  const handleHideModal = () => {
    dispatch(clearCopied());
    dispatch(clearFailedTracks());
    dispatch(clearTotalTracks());
    setInitiateCopy(false);
    setHandleCopy(false);
  };

  const closeCopyPlaylistModal = () => {
    setCopyPlaylistOngoing(!copyPlaylistOngoing);
    dispatch(clearCopied());
    dispatch(clearFailedTracks());
    dispatch(clearTotalTracks());
    dispatch(clearTracks());
    setHideModal(false);
  };

  const closeSyncPlaylistModal = () => {
    setSyncPlaylistOngoing(!syncPlaylistOngoing);
    dispatch(clearCopied());
    dispatch(clearFailedTracks());
    dispatch(clearTotalTracks());
    dispatch(clearTracks());
  };

  useEffect(() => {
    if (totalCopied !== 0 && totalTrackss !== 0) {
      const percentageComplete = (totalCopied / totalTrackss) * 100;
      setProgress(percentageComplete);
    }
    console.log(
      totalCopied,
      totalTrackss,
      (totalCopied / totalTrackss) * 100,
      "totalCopied, totalTrackss,percentageComplete"
    );
  }, [totalCopied, totalTrackss]);

  useEffect(() => {
    playlistTracks();
  }, [playlistId]);

  function searchTracks(query: string) {
    let result = originalSongList;
    if (query && query !== "" && query.length > 2) {
      const lowerQuery = query.toLowerCase(); // Convert query to lowercase for case-insensitive search

      result = originalSongList.filter((track: any) => {
        const trackName = track?.track?.name?.toLowerCase() || track?.attributes?.name?.toLowerCase();
        const artistName = track?.track?.artists[0]?.name?.toLowerCase()|| track?.attributes?.artistName?.toLowerCase();

        return (
          trackName.includes(lowerQuery) || artistName.includes(lowerQuery)
        );
      });
    }
    setFilteredSongList(result);
    return result;
  }

  useEffect(() => {
    if (filteredSongList && filteredSongList.length <= 0) {
      setSongList(originalSongList);
    }
    setSongList(filteredSongList);
  }, [filteredSongList]);

  const [
    createSharedPlaylist,
    { data: newSharedPlaylist, isSuccess, isLoading, error: sharedRoomError },
  ] = useSharePlaylistMutation();

  const shareSpotifyPlayListTracks = async (playlist: any) => {
    try {
      const { data: tracks } = await getPlaylistTracks(playlist?.id);
      const tracksList = tracks.items;

      const playlistDetails = await Promise.all(
        tracksList.map(async (track: any) => {
          const previewUrl = await getPreviewUrl(
            track?.track?.name,
            track?.track?.artists[0]?.name,
            context
          );

          return (
            track?.track?.name && {
              name: track?.track?.name,
              url: track?.track?.href,
              isrc: track?.track?.external_ids?.isrc,
              artist: track?.track?.artists[0]?.name,
              track_id: track?.track?.id,
              image_url: track?.track?.album?.images[0]?.url,
              preview_url: previewUrl ?? "no preview",
            }
          );
        })
      );
      const filteredPlaylistDetails = playlistDetails.filter((i) => i?.name);
      const sharedPlaylistData = {
        userId: userr._id,
        playlistName: playlist?.name,
        playlistId: playlist?.id,
        streamingService: "spotify",
        tracks: filteredPlaylistDetails,
        playlistBannerUrl: playlist?.images ? playlist?.images[0]?.url : "",
      };

      const result: any = await createSharedPlaylist(sharedPlaylistData);
      if (result && result?.data && result.data?._id) {
        const url = `https://app.playamusic.io/shared-playlist/${result.data._id}`;
        setShareAbleUrl(url);
        console.log(url, "copy url---");
        // Trigger share directly
        if (navigator.share) {
          await navigator.share({
            title: "Shared Playlist",
            text: "Check out this playlist I shared!",
            url: url,
          });
        }
      }
    } catch (error) {
      console.error("Error in spotifyPlayListTracks:", error);
    }
  };

  const shareYoutubePlayListTracks = async (playlist: IYoutubePlayLists) => {
    const { tracks } = await getAllTracksInYoutubePlaylist(playlist?.id);

    if (tracks) {
      const playlistDetails = await Promise.all(
        tracks.map(async (track: any) => {
          const previewUrl = await getPreviewUrl(
            track?.songTitle,
            track?.author,
            context
          );

          return {
            name: track?.songTitle,
            artist: track?.author,
            track_id: track?.id,
            image_url: track?.image,
            preview_url: previewUrl ?? "no preview",
          };
        })
      );

      const sharedPlaylistData = {
        userId: userr._id,
        playlistName: playlist?.snippet?.title,
        playlistId: playlist?.id,
        streamingService: "youtube",
        tracks: playlistDetails,
        playlistBannerUrl: playlist?.snippet.thumbnails?.default?.url
          ? playlist?.snippet.thumbnails?.medium?.url
          : "",
      };

      const result = await createSharedPlaylist(sharedPlaylistData);
      if ("data" in result && result.data?._id) {
        const url = `https://app.playamusic.io/shared-playlist/${result.data._id}`;
        setShareAbleUrl(url);
        return url;
      }
    }
    return null;
  };

  const shareApplePlayListTracks = async (playlist: any) => {
    const tracks =
      context && (await context?.getAppleMusicPlaylistTracks(playlist?.id));

    if (tracks) {
      const playlistDetails = await Promise.all(
        tracks.map(async (track: any) => {
          const previewUrl = await getPreviewUrl(
            track?.attributes?.albumName,
            track?.attributes?.artistName,
            context
          );

          return (
            track?.attributes?.albumName && {
              name: track?.attributes?.albumName,
              artist: track?.attributes?.artistName,
              track_id: track?.id,
              image_url: track?.attributes?.artwork?.url,
              preview_url: previewUrl ?? "no preview",
            }
          );
        })
      );
      const filteredPlaylistDetails = playlistDetails.filter((i) => i?.name);
      const sharedPlaylistData = {
        userId: userr._id,
        playlistName: playlist?.attributes?.name,
        playlistId: playlist?.id,
        streamingService: "apple",
        tracks: filteredPlaylistDetails,
        playlistBannerUrl: playlist?.attributes?.artwork?.url
          ? playlist?.attributes?.artwork?.url
          : "",
      };

      const result:any = await createSharedPlaylist(sharedPlaylistData);
      if (result && result?.data && result.data?._id) {
        const url = `https://app.playamusic.io/shared-playlist/${result.data._id}`;
        setShareAbleUrl(url);
        console.log(url, "copy url---");
        return url; // Return the shareable URL
      }
    }
    return null;
  };

  const sharePlaylists = () => {
    switch (appType) {
      case "youtube":
        youtubePlayListTracks();
        break;
      case "spotify":
        spotifyPlayListTracks();
        break;
      case "apple":
        applePlayListTracks();
        break;
      case "library-playlists":
        applePlayListTracks();
        break;
      default:
        break;
    }
  };

  if (loading) return <LoadingScreen />;
  return (
    <Layout>
      <ViewPlaylistScreenContainer>
        <ScreenTitleContainer>
          <span onClick={() => setShowPlaylist(false)}>
            <ChevronIcon />
          </span>

          <Search onChange={searchTracks} placeholder="Search playlist" />
        </ScreenTitleContainer>
        <CurrentSongCard>
          <img
            src={current_track?.album?.images[0]?.url ?? PlaceholderImg}
            alt="track cover"
          />
          <p>{playlistName ? playlistName : ""}</p>
          <span>
            {playlistName ? playlistName : ""}
            {" Playlist - "}
            {/* {data?.data?.room?.playlist?.tracks ?? songList?.length} songs */}
          </span>
        </CurrentSongCard>

        <ListenerOptionsCard>
          <div
            onClick={() => selectPlaylistToBeCopied(playlistName, playlistId)}
          >
            <CopyIcon />
            <p>Copy</p>
          </div>

          <div>
            <ShareMusicIcon />
            <p>Share</p>
          </div>
        </ListenerOptionsCard>
    
       {!loading && (
          <DraggableStackContainer>
            <DraggableCard
              itemsArr={songList && songList?.length > 0 ? songList : []}
            />
          </DraggableStackContainer>
        )}

        {initiateCopy && (
          <>
            <HomeCopyInitiate
              closeModal={() => handleHideModal()}
              callBack={setHandleCopy}
            />
            <BgOverlay />
          </>
        )}

        {handleCopy && !actionCompleted && (
          <>
            <SelectPlaylistBottomModal
              clickedItems={clickedItems}
              setClickedItems={setClickedItems}
              onGoingState={setCopyPlaylistOngoing}
              action="Copy"
              closeModal={handleHideModal}
              playListArr={playListArr}
              actionCompleted={setActionCompleted}
            />
            <BgOverlay />
          </>
        )}

        {(syncPlaylistOngoing || copyPlaylistOngoing) && !hideModal && (
          <>
            <ProgressBottomModal
              title={
                copyPlaylistOngoing
                  ? "Copy in progress, please wait..."
                  : "Sync in progress..."
              }
              bodyText={
                copyPlaylistOngoing
                  ? `Copied ${totalCopied} of ${totalTrackss} songs`
                  : "Syncing 10 of 100 songs to your playlist"
              }
              closeModal={
                syncPlaylistOngoing
                  ? closeSyncPlaylistModal
                  : closeCopyPlaylistModal
              }
              actionBtn="Hide"
              callBack={handleHideModals}
              exitBtn="Cancel"
              icontype="copy"
              progress={progress}
            />
            <BgOverlay />
          </>
        )}

        {actionCompleted && (
          <>
            <CompletedBottomModal
              title="We are done copying your tracks 🤩"
              numberOfTracks={totalTrackss}
              totalNumOfCopiedTracks={totalCopied}
              seeDetails={seeDetails}
              setSeeDetails={setSeeDetails}
              closeModal={() => {
                setActionCompleted(false);
                dispatch(clearCopied());
                dispatch(clearFailedTracks());
                dispatch(clearTotalTracks());
                dispatch(clearTracks());
              }}
            />
            <BgOverlay />
          </>
        )}
      </ViewPlaylistScreenContainer>
      <Toaster />
    </Layout>
  );
};

export default ViewPlayListScreen;

const ViewPlaylistScreenContainer = styled.div``;

const DraggableStackContainer = styled.div`
  overflow: auto;
  height: 55vh;
`;

const CurrentSongCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 5%;

  img {
    width: 150px;
    height: 150px;
    border-radius: 22px;
  }
  p {
    font-size: 16px;
    color: #1f1f1f;
    font-weight: 700;
    margin: 5px auto;
  }
  span {
    font-size: 12px;
    color: #8c8c8c;
    display: block;
    font-weight: 500;
  }
`;

const ListenerOptionsCard = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 10px 0;

  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 20px;

    p {
      font-size: 12px;
      color: #1f1f1f;
      font-weight: 400;
      padding-left: 8px;
    }
  }
`;
