import React, { FC, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "features/auth/authSlice";
import { useGetRoomQuery } from "features/auth/authApiSlice";
import { Layout } from "components/Layout";
import "styles/spotify.scss";
import RoomOwnerScreen from "screens/RoomOwnerScreen";
import NonRoomOwnerScreen from "screens/NonRoomOwnerScreen";
import { LoadingScreen } from "components/common/LoadingScreen";
import cookies from "js-cookie";
import {
  LOCALSTORAGE_VALUES,
  hasTokenExpired,
  refreshToken,
} from "services/spotify";
import { hasGoogleTokenExpired, refreshGoogleToken } from "services/youtube";
import RoomOwnerScreenYoutube from "screens/RoomOwnerScreenYoutube";
interface SpotifyProps {
  token: string;
}

export const Youtube: FC<SpotifyProps> = ({ token }) => {
  // const user = useSelector(selectCurrentUser);
  const userr = useSelector(selectCurrentUser);
  const user =
    userr !== null ? userr : JSON.parse(cookies.get("playa_user") as string);
  const params = useParams();
  const { playlistId } = params;

  useEffect(() => {
    if (hasGoogleTokenExpired()) {
      refreshGoogleToken(userr.googleRefreshToken);
    }
  }, [hasGoogleTokenExpired()]);

    return (
      <Layout>
        <RoomOwnerScreenYoutube token={token} />
        {/* <NonRoomOwnerScreen token={token} data={data} /> */}
      </Layout>
    );

  // return (
  //   <Layout>
  //     {/* <RoomOwnerScreen token={token} data={data}/> */}
  //     <NonRoomOwnerScreen token={token} data={data} />
  //   </Layout>
  // );
};
