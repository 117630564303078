// @ts-nocheck
import { createSlice } from "@reduxjs/toolkit";
import cookies from "js-cookie";
// cookies.set("playa_access_token", data.accessToken as string, {});
//       cookies.set("playa_user", JSON. stringify(data.user), {});
const storageData = {
  user: cookies.get("playa_user")
    ? JSON.parse(cookies.get("playa_user") as string)
    : "",
  token: cookies.get("playa_access_token")
    ? cookies.get("playa_access_token")
    : "",
};
const authSlice = createSlice({
  name: "auth",
  initialState: storageData,
  reducers: {
    setCredentials: (state, action) => {
      const { user, accessToken } = action.payload;
      state.user = user === null ? storageData.user : user;
      state.token = accessToken === null ? storageData.token : accessToken;
    },
    setAuthUser: (state, action) => {
      const { user } = action.payload;
      state.user = user === null ? storageData.user : user;
    },
    logOut: (state, action) => {
      state.user = null;
      state.token = null;
    },
  },
});

export const { setCredentials, logOut, setAuthUser} = authSlice.actions;

export default authSlice.reducer;

export const selectCurrentUser = (state) => state.auth.user;
export const selectCurrentToken = (state) => state.auth.token;
