import React, { useState } from "react";
import AiLogo from "../assets/ai-logo.png";
import AiScreenBG from "../assets/ai-bg.png";
import styled from "styled-components";
import { Layout } from "components/Layout";
import { useCreateAiPlaylistMutation } from "features/auth/authApiSlice";
import { useNavigate } from "react-router-dom";
import AiPlaylistLoader from "components/Loader/AiPlaylistLoader";
import toast from "react-hot-toast";

const genres = [
  "Afrobeats",
  "Pop",
  "R&B",
  "Hip Hop",
  "Reggae",
  "Alte",
  "Amapiano",
  "Gospel",
  "For You",
];

const AIRoomScreen = () => {
  const [createPlaylist, { isLoading, error, isSuccess }] =
    useCreateAiPlaylistMutation();
  const [aiGeneratedPlaylist, setAiGeneratedPlaylist] = useState({});
  const navigate = useNavigate();

  const handleCreatePlaylist = async (genre: any) => {
    const data = await createPlaylist({ genre });
    setAiGeneratedPlaylist(data);
    navigate("/view-ai-playlist", { state: data });
  };

  return (
    <Layout>
      {!isLoading ? (
        <AiScreenContainer>
          <img src={AiLogo} alt="AI Logo" />
          <h2>Get creative with Playa AI</h2>
          <AiActions>
            {genres.map((genre) => (
              <GenreButton
                key={genre}
                genre={genre}
                onClick={() =>
                  genre !== "For You"
                    ? handleCreatePlaylist(genre)
                    : toast.error("Oops, coming soon!")
                }
                isLoading={isLoading}
              />
            ))}
          </AiActions>
        </AiScreenContainer>
      ) : (
        <AiPlaylistLoader />
      )}
    </Layout>
  );
};

export default AIRoomScreen;

const GenreButton = ({ genre, onClick, isLoading }: any) => (
  <button onClick={onClick} disabled={isLoading}>
    {genre}
  </button>
);

const AiScreenContainer = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0px;
  left: 0;
  width: 100%;
  background: url(${AiScreenBG});
  background-size: cover;
  background-position: center;
  z-index: 999;
  border-radius: 8px;
  min-height: 100vh;
  padding-bottom: 160px;

  h2 {
    font-size: 32px;
    font-weight: 700;
    color: #fff;
    text-align: center;
    padding: 0 40px;
  }
`;

const AiActions = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px 12px;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    background: #ffffff;
    border: solid 1px #ffbe55;
    border-radius: 11px;
    font-size: 14px;
    font-weight: 700;
    color: #0f2f40;
    width: 109px;
    height: 89px;
    cursor: pointer;

    &:disabled {
      background: #f0f0f0;
      cursor: not-allowed;
    }
  }
`;
