import { apiSlice } from "app/api/apiSlice";
import { setCredentials, logOut } from "./authSlice";
import cookies from "js-cookie";
export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    register: builder.mutation({
      query: (credentials) => ({
        url: "/auth/signup",
        method: "POST",
        body: credentials,
      }),
    }),

    login: builder.mutation({
      query: (credentials) => ({
        url: "/auth/login",
        method: "POST",
        body: { ...credentials },
      }),
    }),

    sendLogout: builder.mutation({
      query: () => ({
        url: "/auth/logout",
        method: "POST",
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          console.log(data);
          dispatch(logOut());
          setTimeout(() => {
            dispatch(apiSlice.util.resetApiState());
          }, 1000);
        } catch (err) {
          console.log(err);
        }
      },
    }),
    refresh: builder.mutation({
      query: () => ({
        url: "/auth/refresh",
        method: "GET",
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled; 
          // const { accessToken, user } = data;
          cookies.set("playa_access_token", data.accessToken , {});
          dispatch(setCredentials({ ...data }));
        } catch (err) {
          console.log(err);
        }
      },
    }),
    createVerificationCode: builder.mutation({
      query: (payload) => ({
        url: "/auth/verify/account",
        method: "POST",
        body: payload,
      }),
    }),
    createOtpCode: builder.mutation({
      query: (payload) => ({
        url: "/auth/verify/resetpassword",
        method: "POST",
        body: payload,
      }),
    }),

    verifyAccount: builder.mutation({
      query: (payload) => ({
        url: "/auth/verifyaccount",
        method: "POST",
        body: payload,
      }),
    }),

    verifyOtp: builder.mutation({
      query: (payload) => ({
        url: "/auth/verifyotp",
        method: "POST",
        body: payload,
      }),
    }),

    resetPassword: builder.mutation({
      query: (payload) => ({
        url: "/auth/forgetpassword",
        method: "POST",
        body: payload,
      }),
    }),

    updatePassword: builder.mutation({
      query: (user) => ({
        url: "/auth/updatepassword",
        method: "POST",
        body: user,
      }),
    }),

    changePassword: builder.mutation({
      query: (payload) => ({
        url: "/user/changepassword",
        method: "PATCH",
        body: payload,
      }),
    }),

    updateUserDetails: builder.mutation({
      query: (payload) => ({
        url: "/user/updateuserdetails",
        method: "PATCH",
        body: payload,
      }),
      // invalidatesTags: ["UserDetails"],
    }),

    connectAppleMusic: builder.mutation({
      query: (payload) => ({
        url: "/user/applemusic",
        method: "POST",
        body: payload,
      }),
      // invalidatesTags: ["UserDetails"],
    }),

    getRooms: builder.query({
      query: (url) => ({
        url: url,
        method: "GET",
      }),
      // providesTags: ["Rooms"],
    }),

    getRoom: builder.query({
      query: (roomId) => ({
        url: `/room/${roomId}`,
        method: "GET",
      }),
      // providesTags: ["Rooms"],
    }),

    createNewRoom: builder.mutation({
      query: (payload) => ({
        url: "/room",
        method: "POST",
        body: payload,
      }),
      // invalidatesTags: ["Rooms"],
    }),

    getUserDetails: builder.query({
      query: (url) => ({
        url: url,
        method: "GET",
      }),
      // providesTags: ["UserDetails"],
    }),

    getUserPublicProfile: builder.query({
      query: (url) => ({
        url: url,
        method: "GET",
      }),
    }),

    disconnectMusic: builder.mutation({
      query: (payload) => ({
        url: "/auth/disconnect-account",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["UserDetails"],
    }),
    changeMusic: builder.mutation({
      query: (payload) => ({
        url: "/auth/change-primary-streaming-service",
        method: "PATCH",
        body: payload,
      }),
      invalidatesTags: ["UserDetails"],
    }),
    addComment: builder.mutation({
      query: (payload) => ({
        url: "/listeners/add-comment",
        method: "POST",
        body: payload,
      })
    }),
    sharePlaylist: builder.mutation({
      query: (payload) => ({
        url: "/shared-playlist",
        method: "POST",
        body: payload,
      })
    }),
    fetchSharedPlaylist: builder.query({
      query: (playlistId) => ({
        url: `/shared-playlist/${playlistId}`,
        method: "GET",
      }),
    }),
    createAiPlaylist: builder.mutation({
      query: (payload) => ({
        url: `/listeners/ai-playlist`,
        method: "POST",
        body: payload,
      }),
    }),
    curateAiPlaylist: builder.mutation({
      query: (payload) => ({
        url: `/listeners/curate-ai-playlist`,
        method: "POST",
        body: payload,
      }),
    }),
  }),
});

export const {
  useRegisterMutation,
  useLoginMutation,
  useRefreshMutation,
  useSendLogoutMutation,
  useGetRoomsQuery,
  useGetRoomQuery,
  useResetPasswordMutation,
  useCreateVerificationCodeMutation,
  useUpdatePasswordMutation,
  useVerifyAccountMutation,
  useCreateOtpCodeMutation,
  useVerifyOtpMutation,
  useGetUserDetailsQuery,
  useChangePasswordMutation,
  useGetUserPublicProfileQuery,
  useUpdateUserDetailsMutation,
  useCreateNewRoomMutation,
  useConnectAppleMusicMutation,
  useDisconnectMusicMutation,
  useChangeMusicMutation,
  useAddCommentMutation,
  useSharePlaylistMutation,
  useFetchSharedPlaylistQuery,
  useCreateAiPlaylistMutation,
  useCurateAiPlaylistMutation
} = authApiSlice;
