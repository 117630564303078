import React from "react";
import styled from "styled-components";
import CopyIcon from "../../assets/copy.svg";
interface ModalProps {
  closeModal: () => void;
  callBack: any;
}

const HomeCopyInitiate: React.FC<ModalProps> = ({ closeModal, callBack }) => {
  return (
    <BottomModalContainer>
      <BottomModalContainerContent>
        <BottomModalContainerContentFlex>
          <img src={CopyIcon} alt="copy icon" />
          <h2>Copy playlist?</h2>
        </BottomModalContainerContentFlex>

        <p>A copy of this playlist would be created in your music library</p>
        <ModalAction>
          <BottomModalCancelAction onClick={closeModal}>
            Cancel
          </BottomModalCancelAction>
          <BottomModalAction onClick={() => callBack(true)}>Copy</BottomModalAction>
        </ModalAction>
      </BottomModalContainerContent>
    </BottomModalContainer>
  );
};

export default HomeCopyInitiate;

const BottomModalContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 10px;
  position: fixed;
  bottom: 8%;
  right: 0;
  left: 0;
  border-radius: 27px;
  z-index: 1000;
`;

const BottomModalContainerContentFlex = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  margin-bottom: 16px;
  margin-top: 8px;
`;

const BottomModalContainerContent = styled.div`
  background: #ffffff;
  width: 100%;
  padding: 16px;
  border-radius: 8px;
  z-index: 1000;

  h2 {
    color: #1f1f1f;
    font-family: Satoshi;
    font-size: 16px;
    font-weight: 700;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  p {
    font-family: Satoshi;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    color: #595959;
    margin-bottom: 32px;
    margin-top: 8px;
  }
`;

const BottomModalCancelAction = styled.button`
  outline: none;
  border: none;
  background: transparent;
  color: #5f7f90;
  font-family: Satoshi;
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
`;

const BottomModalAction = styled.button`
  outline: none;
  border: none;
  background: transparent;
  color: #008135;
  font-family: Satoshi;
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
`;

const ModalAction = styled.div`
  display: flex;
  gap: 24px;
  justify-content: space-between;
`;
