import React, { ReactNode } from "react";
import styled from "styled-components";
import { BottomNavigationTab } from "./BottomNavigationTab";
import BottomModal from "./Home/BottomModal";

interface ILayout {
  children: ReactNode;
}
export const Layout = ({ children }: ILayout) => {
  return (
    <LayoutContainer>
      <ScreenContainer>{children}</ScreenContainer>
      <BottomNavigationTab />
    </LayoutContainer>
  );
};

const LayoutContainer = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  position: relative;
`;

const ScreenContainer = styled.div`
  padding: 2rem 1rem 0 1rem;
  margin-bottom: 100px;
  z-index: 5;
`;
