import { SearchIcon } from "icons";
import React from "react";

import styled from "styled-components";

interface ISearch {
  placeholder: string;
  onChange:(e:string)=> void;
}

export const Search = ({ placeholder, onChange }: ISearch) => {

  const handleSearch = (event:any) => {
    const query = event.target.value.toLowerCase();
    onChange(query);

  };

  return (
    <Container>
      <span>
        <SearchIcon />
      </span>
      <input type="text" placeholder={placeholder}  onChange={handleSearch}/>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  position: relative;

  span {
    position: absolute;
    left: 8%;
    top: 20%;
  }
  input {
    border: 1px solid #bfbfbf;
    border-radius: 4px;
    height: 40px;
    width: 90%;
    padding: 7px 14px 7px 12%;
  }
`;
