import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { store } from "./app/api/store";
import {
  MusicKitContext,
  MusicKitController,
} from "components/MusicKitController";
import { Toaster } from "react-hot-toast";
import { SocketContext, socket } from "components/socket";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <SocketContext.Provider value={socket}>
      <MusicKitContext.Provider value={new MusicKitController()}>
      <Toaster />
      <BrowserRouter>
        <Routes>
          <Route path="/*" element={<App />} />
        </Routes>
      </BrowserRouter>
      </MusicKitContext.Provider>
    </SocketContext.Provider>
  </Provider>
  // </React.StrictMode>
);

//I do not want the offline/cache-first behavior for now
serviceWorkerRegistration.unregister();
// serviceWorkerRegistration.register();

reportWebVitals();
