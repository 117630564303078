import React from "react";
import styled from "styled-components";
import SearchResultImageOne from "../assets/notification-image-one.png";
import SearchResultImageTwo from "../assets/notification-image-two.png";
import SearchResultImageThree from "../assets/notification-image-three.png";

const PeopleSearchResult = () => {
  const searchResult = [
    {
      id: 1,
      title: "Cruise Maka",
      genre: "Genre: Hip-Hop",
      img: SearchResultImageOne,
      isFollowed: true,
    },
    {
      id: 2,
      title: "Jabbar Music",
      genre: "Genre: Hip-Hop",
      img: SearchResultImageTwo,
      isFollowed: true,
    },
    {
      id: 3,
      title: "Cruise Maka",
      genre: "Genre: Hip-Hop",
      img: SearchResultImageThree,
      isFollowed: true,
    },
    {
      id: 4,
      title: "Cruise Maka",
      genre: "Genre: Hip-Hop",
      img: SearchResultImageOne,
      isFollowed: false,
    },
    {
      id: 5,
      title: "Jabbar Music",
      genre: "Genre: Hip-Hop",
      img: SearchResultImageTwo,
      isFollowed: false,
    },
    {
      id: 6,
      title: "Cruise Maka",
      genre: "Genre: Hip-Hop",
      img: SearchResultImageThree,
      isFollowed: false,
    },
  ];
  return (
    <SearchResultContentContainer>
      {searchResult &&
        searchResult.length > 0 &&
        searchResult.map((item) => (
          <SearchResultContent key={item.id}>
            <SearchResultContentLeft>
              <img src={item.img} alt="Search Result Poster"></img>
              <div>
                <h6>{item.title}</h6>
                <p>Genre: {item.genre}</p>
              </div>
            </SearchResultContentLeft>
            {item.isFollowed ? (
              <FollowButton>
                <span>+</span> Follow
              </FollowButton>
            ) : (
              <UnFollowButton>Unfollow</UnFollowButton>
            )}
          </SearchResultContent>
        ))}
    </SearchResultContentContainer>
  );
};

export default PeopleSearchResult;

const SearchResultContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 20px;
`;

const SearchResultContent = styled.div`
  padding: 12px 16px;
  border: solid 1px #f0f0f0;
  border-radius: 3px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  img {
    width: 34px;
    height: 34px;
  }
`;

const SearchResultContentLeft = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;

  h6 {
    font-family: Satoshi;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    color: #1f1f1f;
  }
  p {
    font-family: Satoshi;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    color: #8c8c8c;
  }
`;

const FollowButton = styled.button`
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  color: #008135;
  background: transparent;
  border: none;
  outline: none;
`;

const UnFollowButton = styled.button`
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  color: #375768;
  background: transparent;
  border: none;
  outline: none;
`;
