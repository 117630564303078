//@ts-nocheck
import React, { useState, useEffect, FC, useContext } from "react";
import { useLocation, useParams } from "react-router-dom";
import styled from "styled-components";
import axios from "axios";
import "styles/spotify.scss";
import { PlayaToast } from "utils";
import { ScreenTitleContainer } from "./ProfileScreen";
import { ScreenTitle } from "components/ScreenTitle";
import { ChatUsersIcon, ChevronIcon, CloseIcon, ShareMusicIcon } from "icons";
import { Webplayback } from "components/Webplayback";
import { LoadingScreen } from "components/common/LoadingScreen";
import { Chat } from "components/Chat";
import ChatAvatar from "../assets/smallprofileImg.png";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  cardDetails,
  clearRoom,
  globalPlayer,
  setCardDetails,
  setGlobalPlayer,
} from "app/api/otherSlice";
import PlayingMusicIcon from "../assets/Rectangle 89.png";
import { MdDirections } from "react-icons/md";
import BottomModal from "components/Home/BottomModal";
import { BgOverlay } from "./HomeScreen";
import { IoPowerOutline } from "react-icons/io5";
import { SocketContext } from "components/socket";
import { Socket } from "socket.io-client";
import { selectCurrentUser } from "features/auth/authSlice";
import cookies from "js-cookie";
import {
  useAddCommentMutation,
  useGetRoomQuery,
  useRoomDataMutation,
} from "features/auth/authApiSlice";
import { removeSpotifySDK } from "services/spotify";
import { WebplaybackYoutube } from "components/WebplaybackYoutube";
import toast, { Toaster } from "react-hot-toast";
import { getAllTracksInYoutubePlaylist } from "services/youtube";

interface IRoomOwnerScreen {
  token: string;
}
 
const RoomOwnerScreenYoutube: FC<IRoomOwnerScreen> = ({ token }) => {
  const [is_paused, setPaused] = useState(false);
  const socket = useContext(SocketContext) as Socket;
  const [current_track, setTrack] = useState({});
  const userr = useSelector(selectCurrentUser);
  const user =
    userr !== null ? userr : JSON.parse(cookies.get("playa_user") as string);

  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { playlistId } = params;

  const [openModal, setOpenModal] = useState<boolean>(false);
  const closeModal = () => {
    setOpenModal(false);
  };
  
  const updateCard = (cardDetails: any) => {
    dispatch(setCardDetails({ cardDetails }));
  };

  const handleStopMusic = () => {
    setPaused(true);
    dispatch(clearRoom());
    // Call the function
    removeSpotifySDK(); 
    navigate("/home");
  };


  const youtubePlayListTracks = async () => {
    const { tracks } = await getAllTracksInYoutubePlaylist(playlistId);
    console.log(tracks, "---data tracks youtube");
    tracks && setSongList(tracks);
    !tracks && setSongList([]);
    setLoading(false);
  };

  return (
    <section>
      <ScreenTitleContainer>
        <ScreenTitle
          title={location?.state?.playlistName || ""}
          icon={<ChevronIcon />}
        />
        <IoPowerOutline
          color="#0F2F40"
          size={20}
          onClick={() => setOpenModal(true)}
        />
        {/* <div onClick={handleStopMusic}>
          <CloseIcon />
        </div> */}
      </ScreenTitleContainer>

      <WebplaybackYoutube
        playlist={playlistId}
        is_paused={is_paused}
        isOwner={true}
      />

      {/* <RoomDetailsContainer>
        <p>
          {location?.state?.data?.room?.roomDescription ||
            data?.data?.room?.roomDescription}
        </p>
      </RoomDetailsContainer> */}

      {openModal ? (
        <>
          <BottomModal
            title={`End ${location?.state?.playlistName} session`}
            bodyText={`Are you sure you want to close ${location?.state?.playlistName}?`}
            closeModal={closeModal}
            actionBtn="End"
            callBack={handleStopMusic}
            exitBtn="Cancel"
            icontype="close"
          />
          <BgOverlay />
        </>
      ) : null}
      <Toaster />
    </section>
  );
};

export default RoomOwnerScreenYoutube;

const RoomDetailsContainer = styled.div`
  p {
    color: #262626;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }

  button {
    display: flex;
    width: 76px;
    height: 30px;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 32px;
    background: #008135;
    color: var(--neutral-gray-2, #fafafa);
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    border: none;
    margin-top: 20px;
  }
`;
const RoomChatHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  border-bottom: 1px solid #d9d9d9;
  span {
    display: flex;
    flex-direction: row;
    align-items: center;
    p {
      font-family: Satoshi;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      margin: 5px;
    }
    span {
      display: flex;
      flex-direction: row;
      align-items: center;

      p {
        font-family: Satoshi;
        font-size: 12px;
        font-style: normal;
      }
    }
  }
`;
