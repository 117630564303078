// @ts-nocheck
import { createSlice } from "@reduxjs/toolkit";

const tracksSlice = createSlice({
  name: "tracks",
  initialState: [],
  reducers: {
    setTracks: (state, action) => {
      return action.payload;
    },
    clearTracks: () => {
      return []; // Reset the state to its initial value
    },
  },
});

export const { setTracks, clearTracks } = tracksSlice.actions;

export default tracksSlice.reducer;

export const tracks = (state) => state.tracks;
