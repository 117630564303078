import React from "react";
export const NotifictionIcon = () => (
  <svg
    width="20"
    height="22"
    viewBox="0 0 20 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.6 8.458V7.4C3.6 5.70261 4.27428 4.07475 5.47452 2.87452C6.67475 1.67428 8.30261 1 10 1C11.6974 1 13.3253 1.67428 14.5255 2.87452C15.7257 4.07475 16.4 5.70261 16.4 7.4V8.458C16.4 10.758 17.065 13.01 18.315 14.941L19 16H1L1.685 14.94C2.93451 13.0093 3.59951 10.7577 3.6 8.458Z"
      stroke="#0F2F40"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9 19.8889C9.12613 20.0291 9.28034 20.1413 9.45262 20.218C9.6249 20.2948 9.81139 20.3345 10 20.3345C10.1886 20.3345 10.3751 20.2948 10.5474 20.218C10.7197 20.1413 10.8739 20.0291 11 19.8889"
      stroke="#0F2F40"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const ProfileIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 22C17.5229 22 22 17.5229 22 12C22 6.47715 17.5229 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5229 6.47715 22 12 22Z"
      stroke="#0F2F40"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 11.5C13.3807 11.5 14.5 10.3807 14.5 9C14.5 7.6193 13.3807 6.5 12 6.5C10.6193 6.5 9.5 7.6193 9.5 9C9.5 10.3807 10.6193 11.5 12 11.5Z"
      stroke="#0F2F40"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M5.01099 19.166C5.18284 16.5603 7.35079 14.5 9.99999 14.5H14C16.6457 14.5 18.8114 16.5548 18.9883 19.1556"
      stroke="#0F2F40"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
