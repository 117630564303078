import { Layout } from "components/Layout";
import React from "react";
import styled from "styled-components";
import { ScreenTitleContainer } from "./ProfileScreen";
import { ScreenTitle } from "components/ScreenTitle";
import { BackIcon } from "icons/BackIcon";

const SubscriptionScreen = () => {
  return (
    <Layout>
      <ScreenTitleContainer>
        <ScreenTitle title="Subscription" icon={<BackIcon />} />
      </ScreenTitleContainer>
      <ScreenWrapper>
        <div>Oops you caught us we are still working on this!</div>
      </ScreenWrapper>
    </Layout>
  );
};

export default SubscriptionScreen;

export const ScreenWrapper = styled.div`
  display: flex;
  height: 80vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
