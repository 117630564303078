import React, { FC } from "react";
import { useNavigate } from "react-router-dom";
import { NotifictionIcon } from "./icons";
import styles from "styles/topnav.module.scss";
import styled from "styled-components";
import { IUser } from "@types";
import ProfileIcon from "assets/home-profile-img.png";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "features/auth/authSlice";
import cookies from "js-cookie";

export const TopNav: FC<IUser> = ({ username, email, password }) => {
  const navigate = useNavigate();
  const userr = useSelector(selectCurrentUser);
  const user =
    userr !== null ? userr : JSON.parse(cookies.get("playa_user") as string);

  return (
    <section className={styles.navContainer}>
      <div className={styles.profileContainer}>
        <h5>Welcome back,</h5>
        <p>{username}</p>
      </div>

      <div
        onClick={() =>
          navigate("/account", { state: { username, email, password } })
        }
        style={{ zIndex: "50" }}
      >
        <ProfileImageText>
          <p>{user?.username.charAt(0).toUpperCase()}</p>
        </ProfileImageText>
      </div>
    </section>
  );
};

const ProfileImageText = styled.div`
  width: 40px;
  height: 40px;
  background-color: #008135;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    color: #fff;
    font-size: 20px;
    font-weight: 500;
  }
`;
