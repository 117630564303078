import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { ScreenTitle } from "components/ScreenTitle";
import DefaultProfileImg from "../assets/defaultprofileImg.png";
import { useUpdateUserDetailsMutation } from "features/auth/authApiSlice";
import { selectCurrentUser } from "features/auth/authSlice";
import { LoadingScreen } from "components/common/LoadingScreen";
import { Layout } from "components/Layout";
import { BackIcon } from "icons/BackIcon";
// import { UpdateIcon } from "icons";
import cookies from "js-cookie";
const ProfileScreen = () => {
  const [updateUserDetails, { isLoading }] = useUpdateUserDetailsMutation();
  const navigate = useNavigate();

  // const user = useSelector(selectCurrentUser);
  const userr = useSelector(selectCurrentUser);
  const user =
    userr !== null ? userr : JSON.parse(cookies.get("playa_user") as string);

  const defaultFormState = {
    userName: { value: "", error: null },
    emailAddress: { value: "", error: null },
    password: { value: "", error: null },
  };

  const [formState, setFormState] = useState(defaultFormState);

  const onChangeHandler = (field: string, value: string) => {
    setFormState({
      ...formState,
      [field]: {
        value: value,
        error: null,
      },
    });
  };

  const handleSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    let hasErrors = handleFormValidations();

    const updatedState = { ...formState };

    const { userName, emailAddress } = updatedState;

    const payload = {
      username: userName.value || user?.username,
      email: emailAddress.value || user?.email,
    };

    await updateUserDetails(payload);

    if (hasErrors) {
      return;
    }
  };

  const handleFormValidations = () => {
    let updatedState = { ...formState };
    let error = false;

    const { userName, emailAddress } = updatedState;

    if (!userName.value) {
      //@ts-ignore
      updatedState.userName.error = "Enter a username";
      error = true;
    }
    if (userName.value && userName.value?.length < 3) {
      //@ts-ignore
      updatedState.userName.error = "username cannot be less than 3 characters";
      error = true;
    }

    const re =
      /^(([^<>()[\]\\.,;:\s@\\"]+(\.[^<>()[\]\\.,;:\s@\\"]+)*)|(\\".+\\"))@(([^<>()[\]\\.,;:\s@\\"]+\.)+[^<>()[\]\\.,;:\s@\\"]{2,})$/i;

    if (!emailAddress?.value) {
      //@ts-ignore
      updatedState.emailAddress.error = "Enter email address";
      error = true;
    }

    if (emailAddress?.value && !emailAddress?.value?.match(re)) {
      //@ts-ignore
      updatedState.emailAddress.error = "Email address is invalid";
      error = true;
    }

    setFormState({
      ...formState,
      ...updatedState,
    });
    return error;
  };

  return (
    <Layout>
      {isLoading && <LoadingScreen />}

      <ProfileScreenContainer>
        <ScreenTitleContainer>
          <ScreenTitle title="Profile" icon={<BackIcon />} />
          <SaveButton form="changedetailsForm">Save</SaveButton>
        </ScreenTitleContainer>

        <FormInputContainer>
          <form id="changedetailsForm" onSubmit={handleSubmit}>
            <ProfileBannerContainer>
            <ProfileImageText>
              <p>{user?.username.charAt(0).toUpperCase()}</p>
            </ProfileImageText>
              <div>{/* <UpdateIcon /> */}</div>
            </ProfileBannerContainer>
            <InputLabelContainer>
              <label htmlFor="userName">Username</label>
              <input
                name="userName"
                value={formState.userName.value}
                type="text"
                onChange={(e) => onChangeHandler("userName", e.target.value)}
                placeholder={user?.username}
              />
            </InputLabelContainer>
            <InputLabelContainer>
              <label htmlFor="emailAddress">Email</label>
              <input
                name="emailAddress"
                value={formState.emailAddress.value}
                type="text"
                onChange={(e) =>
                  onChangeHandler("emailAddress", e.target.value)
                }
                placeholder={user?.email}
              />
            </InputLabelContainer>
          </form>

          <ChangePassWordButtonContainer>
            <button
              onClick={() => navigate("/changepassword")}
              className="changePasswordBtn"
            >
              Change Password
            </button>
            <button className="deleteAccountBtn">Delete Account</button>
          </ChangePassWordButtonContainer>
        </FormInputContainer>
      </ProfileScreenContainer>
    </Layout>
  );
};

export default ProfileScreen;

const ProfileScreenContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const InputLabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;

  label {
    font-size: 14px;
    font-weight: 700;
  }
`;

export const ProfileBannerContainer = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
`;

const ProfileImageText = styled.div`
  width: 100px;
  height: 100px;
  background-color: #008135;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    color: #FFF;
    font-size: 40px;
    font-weight: semibold;
  }
`;

export const ScreenTitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

const ChangePassWordButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  .changePasswordBtn {
    color: black;
    border-radius: 32px;
    display: flex;
    width: 100%;
    height: 48px;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    cursor: pointer;
    font-size: 14px;
    border-radius: 32px;
    border: 1px solid #375768;
    background: #fafafa;
  }

  .deleteAccountBtn {
    color: #b42318;
    border-radius: 32px;
    display: flex;
    height: 48px;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    cursor: pointer;
    font-size: 14px;
    border-radius: 32px;
    border: none;
    background: transparent;
    font-weight: 700;
  }
`;

const SaveButton = styled.button`
  display: flex;
  width: 79px;
  height: 38px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 32px;
  background: #008135;
  color: white;
  border: none;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
`;
const FormInputContainer = styled.div`
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100vh - 200px);

  input {
    margin-bottom: 20px;
    border: 1px solid #bfbfbf;
    padding: 12px 16px;
    border-radius: 4px;
    outline: none;
    font-size: 14px;
  }
`;
