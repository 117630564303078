import React, { useEffect, useRef } from "react";

interface YouTubePlayerProps {
  playlistId: string;
  player: any;
  isPlaying: boolean;
  setSongDetails: React.Dispatch<React.SetStateAction<any>>;
  setIsplaying: React.Dispatch<React.SetStateAction<boolean>>;
  setPlayer: React.Dispatch<React.SetStateAction<any>>;
}

// Extend the Window interface
declare global {
  interface Window {
    onYouTubeIframeAPIReady: () => void;
    YT: any;
  }
}

export const YouTubePlayer: React.FC<YouTubePlayerProps> = ({
  playlistId,
  setPlayer,
  setSongDetails,
}) => {
  const playerRef = useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    const loadYouTubeAPI = async () => {
      if (window.YT && window.YT.Player) {
        // If the API and the Player constructor are ready, create the player
        createPlayer();
      } else {
        // If not, wait for the API to be ready
        window.onYouTubeIframeAPIReady = createPlayer;
      }

      const tag = document.createElement("script");
      tag.src = "https://www.youtube.com/iframe_api";
      const firstScriptTag = document.getElementsByTagName("script")[0];
      if (firstScriptTag && firstScriptTag.parentNode) {
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
      }
    };

    const createPlayer = () => {
      const playerr = new window.YT.Player(playerRef.current, {
        playerVars: { autoplay: 1, controls: 0 },
        events: {
          onReady: (event: any) => onPlayerReady(event, playerr),
          onStateChange: (event: any) => onPlayerStateChange(event, playerr),
          onError: onError,
        },
      });
      setPlayer(playerr);
    };

    loadYouTubeAPI();
  }, [playlistId]);

  const onPlayerReady = (event: any, player: any) => {
    player.loadPlaylist({ list: playlistId });
    player.playVideo();
  };

  const onPlayerStateChange = (event: any, player: any) => {
    console.log(event.data, "player state");
    setSongDetails(player.getVideoData());
    console.log(
      "isMute- initial: ",
      player.isMuted(),
      "volume:",
      player.getVolume()
    );
  };

  const onError = (event: any) => {
    console.log("Player error:", event.data);
  };

  const domain =
    window.location.hostname === "localhost"
      ? `http://localhost:3000`
      : `https://${window.location.hostname}`;
  return (
    <iframe
        id="youtube_player"
        width="100%"
        allow="autoplay"
        src={`https://www.youtube.com/embed?listType=playlist&list=${playlistId}&enablejsapi=1&origin=${domain}&autoplay=1`}
        style={{ display:"none" }}
        ref={playerRef}
      />
  );
};
