import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { PiEyeThin } from "react-icons/pi";
import { PasswordToggleIcon } from "icons/PasswordToggleIcon";
import { UserError } from "components/common/UserError";
import { IChangePasswordDefaultFormState } from "@types";
import styled from "styled-components";
import { BottomNavigationTab } from "components/BottomNavigationTab";
import { useChangePasswordMutation } from "features/auth/authApiSlice";
import { LoadingScreen } from "components/common/LoadingScreen";
import { ScreenTitle } from "components/ScreenTitle";
import { ScreenTitleContainer } from "./ProfileScreen";
import { BackIcon } from "icons/BackIcon";

const ChangePasswordScreen = () => {
  const navigate = useNavigate();
  const defaultFormState: IChangePasswordDefaultFormState = {
    currentPassword: { value: "", error: null },
    newPassword: { value: "", error: null },
    confirmPassword: { value: "", error: null },
  };

  const [formState, setFormState] = useState(defaultFormState);
  const [isPassword, setIsPassword] = useState<boolean>(true);

  const onChangeHandler = (field: string, value: string) => {
    setFormState({
      ...formState,
      [field]: {
        value: value,
        error: null,
      },
    });
  };

  const [changePassword, { isLoading }] = useChangePasswordMutation();
  const handleSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    let hasErrors = handleFormValidations();

    const updatedState = { ...formState };

    const { currentPassword, newPassword } = updatedState;

    const formData = {
      currentPassword: currentPassword.value,
      newPassword: newPassword.value,
    };

    if (hasErrors) {
      return;
    }

    await changePassword(formData)
      .unwrap()
      .then(() => {
        navigate("/login");
      })
      .catch((error) => console.log(error));
  };

  const handleFormValidations = () => {
    let updatedState = { ...formState };
    let error = false;

    const { currentPassword, newPassword, confirmPassword } = updatedState;

    if (!currentPassword?.value) {
      updatedState.currentPassword.error = "Enter your current password";
      error = true;
    }

    if (!newPassword?.value) {
      updatedState.currentPassword.error = "Enter your current password";
      error = true;
    }

    if (currentPassword?.value && currentPassword?.value?.length < 8) {
      updatedState.currentPassword.error =
        "password cannot be less than 8 characters";
      error = true;
    }

    if (newPassword?.value && newPassword?.value?.length < 8) {
      updatedState.newPassword.error =
        "password cannot be less than 8 characters";
      error = true;
    }

    if (newPassword.value !== confirmPassword.value) {
      updatedState.confirmPassword.error = "passwords do not match";
      error = true;
    }

    setFormState({
      ...formState,
      ...updatedState,
    });
    return error;
  };

  return (
    <ChangePasswordScreenContainer>
      {isLoading && <LoadingScreen />}

      <ScreenTitleContainer>
        <ScreenTitle title="Change Password" icon={<BackIcon />} />
      </ScreenTitleContainer>
      <InputsSectionContainer>
        <form id="changePasswordForm" onSubmit={handleSubmit}>
          <LabelPasswordInputContainer>
            <label htmlFor="currentPassword">current password</label>
            <PasswordToggelWrapper>
              <input
                value={formState.currentPassword.value}
                onChange={(e) =>
                  onChangeHandler("currentPassword", e.target.value)
                }
                placeholder="Current password"
                type={isPassword ? "password" : "text"}
                id="currentPassword"
                className={`${
                  formState.currentPassword.error
                    ? `textInputErrorState`
                    : `textInput`
                } textInput`}
              />

              <PasswordToggleIconWrapper
                onClick={() => setIsPassword(!isPassword)}
              >
                {isPassword ? <PasswordToggleIcon /> : <PiEyeThin />}
              </PasswordToggleIconWrapper>
            </PasswordToggelWrapper>
            {formState?.currentPassword?.error && (
              <UserError errorType={formState.currentPassword.error} />
            )}
          </LabelPasswordInputContainer>

          <LabelPasswordInputContainer>
            <label htmlFor="newPassword">new password</label>
            <PasswordToggelWrapper>
              <input
                value={formState.newPassword.value}
                onChange={(e) => onChangeHandler("newPassword", e.target.value)}
                placeholder="new password"
                type={isPassword ? "password" : "text"}
                id="newPassword"
                className={`${
                  formState.newPassword.error
                    ? `textInputErrorState`
                    : `textInput`
                } textInput`}
              />

              <PasswordToggleIconWrapper
                onClick={() => setIsPassword(!isPassword)}
              >
                {isPassword ? <PasswordToggleIcon /> : <PiEyeThin />}
              </PasswordToggleIconWrapper>
            </PasswordToggelWrapper>
            {formState?.newPassword?.error && (
              <UserError errorType={formState.newPassword.error} />
            )}
          </LabelPasswordInputContainer>

          <LabelPasswordInputContainer>
            <label htmlFor="confirmPassword">confirm new password</label>
            <PasswordToggelWrapper>
              <input
                value={formState.confirmPassword.value}
                onChange={(e) =>
                  onChangeHandler("confirmPassword", e.target.value)
                }
                placeholder="Confirm new password"
                type={isPassword ? "password" : "text"}
                id="confirmPassword"
                className={`${
                  formState.confirmPassword.error
                    ? `textInputErrorState`
                    : `textInput`
                } textInput`}
              />

              <PasswordToggleIconWrapper
                onClick={() => setIsPassword(!isPassword)}
              >
                {isPassword ? <PasswordToggleIcon /> : <PiEyeThin />}
              </PasswordToggleIconWrapper>
            </PasswordToggelWrapper>
            {formState?.confirmPassword?.error && (
              <UserError errorType={formState.confirmPassword.error} />
            )}
          </LabelPasswordInputContainer>
        </form>

        <ChangePassWordButtonContainer>
          <button form="changePasswordForm" className="changePasswordBtn">
            save password
          </button>
        </ChangePassWordButtonContainer>
      </InputsSectionContainer>

      <BottomNavigationTab />
    </ChangePasswordScreenContainer>
  );
};

export default ChangePasswordScreen;

const ChangePasswordScreenContainer = styled.div`
  padding-top: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
`;

const InputsSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 4rem;
  padding-right: 1rem;
  padding-left: 1rem;
  flex: 1;
`;

const LabelPasswordInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  .textInputErrorState {
    border: 1px solid #d92d20;
    background-color: #fef3f2;
  }

  .textInput {
    border: 1px solid #bfbfbf;
    padding: 12px 16px;
    border-radius: 4px;
    outline: none;
    font-size: 14px;
    width: 100%;
  }

  label {
    color: #1f1f1f;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
  }
`;

const PasswordToggelWrapper = styled.div`
  border-radius: 4px;
  outline: none;
  display: flex;
  align-items: center;
  position: relative;
`;

const PasswordToggleIconWrapper = styled.div`
  position: absolute;
  right: 10px;
  display: flex;
`;

const ChangePassWordButtonContainer = styled.div`
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  .changePasswordBtn {
    background-color: #008135;
    border: none;
    color: #fff;
    border-radius: 32px;
    display: flex;
    width: 350px;
    height: 48px;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    cursor: pointer;
    font-size: 14px;
  }
`;
