import { configureStore } from "@reduxjs/toolkit";
import { apiSlice } from "./apiSlice";
import roomsReducer from "features/rooms/roomsSlice";
import tracksReducer from "features/tracks/tracksSlice";
import copiedReducer from "features/tracks/copiedTracksSlice";
import failedTracksReducer from "features/tracks/failedTracksSlice";
import authReducer from "features/auth/authSlice";
import otherReducer from "./otherSlice";
import totalTrackerReducer from "features/tracks/tracksTotalSlice";
export const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    auth: authReducer,
    other: otherReducer,
    rooms: roomsReducer,
    tracks: tracksReducer,
    copied: copiedReducer,
    failedTracks: failedTracksReducer,
    totalTracks: totalTrackerReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiSlice.middleware),
  devTools: true,
});
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
