import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import SuccessIcon from "../../assets/success.svg";
import FailedIcon from "../../assets/failed.svg";
import { ArrowDownBlue, ArrowUpBlue } from "icons/musicServicesIcon";
import { useSelector } from "react-redux";
import {
  clearFailedTracks,
  failedTracks,
} from "features/tracks/failedTracksSlice";
import { clearCopied } from "features/tracks/copiedTracksSlice";
import { clearTotalTracks } from "features/tracks/tracksTotalSlice";
import { clearTracks } from "features/tracks/tracksSlice";
import { useDispatch } from "react-redux";
interface ModalProps {
  title: string;
  closeModal: () => void;
  numberOfTracks: number;
  totalNumOfCopiedTracks: number;
  seeDetails: boolean;
  setSeeDetails: React.Dispatch<React.SetStateAction<boolean>>;
}

interface Track {
  title: string;
  author: string;
}

const CompletedBottomModal: React.FC<ModalProps> = ({
  title,
  closeModal,
  numberOfTracks,
  totalNumOfCopiedTracks,
  seeDetails,
  setSeeDetails,
}) => {
  const failedTracksList = useSelector(failedTracks);
  const ref = useRef<any>();
  console.log(failedTracksList, "Array list");

  const toggleDetails = () => {
    setSeeDetails(!seeDetails);
  };
  const dispatch = useDispatch();
  useEffect(() => {
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        closeModal();
        dispatch(clearCopied());
        dispatch(clearFailedTracks());
        dispatch(clearTotalTracks());
        dispatch(clearTracks());
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  return (
    <BottomModalContainer ref={ref}>
      <BottomModalContainerContent>
        <ButtomModalAction onClick={closeModal}>
          <CloseDiv></CloseDiv>
        </ButtomModalAction>
        <BottomModalContainerContentFlex>
          <h2>{title}</h2>
        </BottomModalContainerContentFlex>
        <BottomModalContainerContentFlex>
          <img src={SuccessIcon} alt="" />
          <p>{totalNumOfCopiedTracks} songs were copied successfully</p>
        </BottomModalContainerContentFlex>
        {failedTracksList.length > 0 && (
          <>
            <BottomModalContainerContentFlex>
              <img src={FailedIcon} alt="" />
              <p>{failedTracksList.length} songs could not be copied</p>
            </BottomModalContainerContentFlex>
            <ModalAction>
              {seeDetails ? <ArrowUpBlue /> : <ArrowDownBlue />}
              <p onClick={toggleDetails}>
                See {seeDetails ? "less" : "details"}
              </p>
            </ModalAction>
          </>
        )}

        <FailedSongs>
          {seeDetails && (
            <>
              {failedTracksList.map((item: Track) => (
                <p key={item.title}>
                  {item.title} - {item.author}
                </p>
              ))}
            </>
          )}
        </FailedSongs>
      </BottomModalContainerContent>
    </BottomModalContainer>
  );
};

export default CompletedBottomModal;

const BottomModalContainer = styled.div`
  display: flex;
  width: 100vw;
  justify-content: space-between;
  padding: 0px;
  position: fixed;
  bottom: 50px;
  right: 0;
  left: 0;
  border-radius: 27px;
  z-index: 1000 !important;
`;

const ButtomModalAction = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CloseDiv = styled.div`
  width: 54px;
  height: 6px;
  border-radius: 10px;
  background: #d9d9d9;
`;

const BottomModalContainerContentFlex = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  margin-bottom: 16px;
  margin-top: 8px;
  z-index: 1000;
`;

const BottomModalContainerContent = styled.div`
  background: #ffffff;
  width: 100%;
  padding: 16px;
  border-radius: 24px 24px 0px 0px;
  z-index: 10000000;

  h2 {
    color: #1f1f1f;
    font-family: Satoshi;
    font-size: 16px;
    font-weight: 700;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 8px;
  }

  p {
    font-family: Satoshi;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #262626;
  }
`;

const ModalAction = styled.div`
  display: flex;
  gap: 9px;
  align-items: center;

  p {
    color: #2e90fa;
    font-size: 14px;
    font-weight: 500;
    font-family: Satoshi;
    text-decoration: underline;
  }
`;

const FailedSongs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 9px;
  margin-top: 8px;
  margin-bottom: 28px;
  max-height: 50vh;
  overflow-y: auto;

  p {
    color: #d92d20;
    font-size: 12px;
    font-weight: 500;
    font-family: Satoshi;
    margin-left: 20px;
  }
`;
