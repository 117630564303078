import React, { ReactNode } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import styled from "styled-components";
import { useDispatch } from "react-redux";

import { setIsCurrentPlayingMusic } from "app/api/otherSlice";

interface IScreenTitle {
  title: string;
  icon: ReactNode;
}

export const ScreenTitle = ({ title, icon }: IScreenTitle) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const handlePlayMusic = () => {
    dispatch(setIsCurrentPlayingMusic({ isCurrentPlayingMusic: true }));
  };

  return (
    <Container>
      {icon && (
        <div
        onClick={location.pathname.includes('listeningroom') ? () => {
          handlePlayMusic();
          navigate("/home");
        }:()=>{
          navigate(-1);
        }}
      >
        {icon}
      </div>
      )}
      <p>{title}</p>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;

  p {
    color: #141414;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    margin: 0 auto;
    text-transform: capitalize;
  }
`;
