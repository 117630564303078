//@ts-nocheck
import React, { useState, useEffect, FC, useContext } from "react";
import { useLocation, useParams } from "react-router-dom";
import styled from "styled-components";
import axios from "axios";
import "styles/spotify.scss";
import { PlayaToast } from "utils";
import { ScreenTitleContainer } from "./ProfileScreen";
import { ScreenTitle } from "components/ScreenTitle";
import { ChatUsersIcon, ChevronIcon, CloseIcon, ShareMusicIcon } from "icons";
import { Webplayback } from "components/Webplayback";
import { LoadingScreen } from "components/common/LoadingScreen";
import { Chat } from "components/Chat";
import ChatAvatar from "../assets/smallprofileImg.png";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  cardDetails,
  clearRoom,
  globalPlayer,
  setCardDetails,
  setGlobalPlayer,
} from "app/api/otherSlice";
import PlayingMusicIcon from "../assets/Rectangle 89.png";
import { MdDirections } from "react-icons/md";
import BottomModal from "components/Home/BottomModal";
import { BgOverlay } from "./HomeScreen";
import { IoPowerOutline } from "react-icons/io5";
import { SocketContext } from "components/socket";
import { Socket } from "socket.io-client";
import { selectCurrentUser } from "features/auth/authSlice";
import cookies from "js-cookie";
import {
  useAddCommentMutation,
  useGetRoomQuery,
  useRoomDataMutation,
  useSharePlaylistMutation,
} from "features/auth/authApiSlice";
import { getPlaylistTracks, removeSpotifySDK } from "services/spotify";
import toast, { Toaster } from "react-hot-toast";
import { CopyToClipboard } from "react-copy-to-clipboard";

interface IRoomOwnerScreen {
  token: string;
}

const RoomOwnerScreen: FC<IRoomOwnerScreen> = ({ token }) => {
  const [is_paused, setPaused] = useState(false);
  const [is_active, setActive] = useState(false);
  const [current_track, setTrack] = useState({});
  const [playerState, setPlayerState] = useState({});
  const [listeners, setListeners] = useState(0);
  const [chatCount, setChatCount] = useState(0);
  const socket = useContext(SocketContext) as Socket;
  const userr = useSelector(selectCurrentUser);
  const [shareUrl, setShareUrl] = useState("");

  const [addComment] = useAddCommentMutation();
  const [
    createSharedPlaylist,
    { data: newSharedPlaylist, isSuccess, isLoading, error: sharedRoomError },
  ] = useSharePlaylistMutation();

  const user =
    userr !== null ? userr : JSON.parse(cookies.get("playa_user") as string);

  const location = useLocation();
  const params = useParams();
  const { playlistId } = params;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { playlistName } = location?.state;
  const player = useSelector(globalPlayer);
  console.log(player, "player");
  const [openModal, setOpenModal] = useState<boolean>(false);
  const closeModal = () => {
    setOpenModal(false);
  };
  const updateCard = (cardDetails: any) => {
    dispatch(setCardDetails({ cardDetails }));
  };

  const handleStopMusic = () => {
    if (!is_paused) {
      player.togglePlay();
    }
    console.log("out---");
    setPaused(true);
    dispatch(clearRoom());
    // Call the function
    removeSpotifySDK();
    navigate("/home");
  };
console.log(current_track);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://sdk.scdn.co/spotify-player.js";
    script.async = true;
    document.head.appendChild(script);

    if (!is_active) {
      window.onSpotifyWebPlaybackSDKReady = () => {
        const player = new window.Spotify.Player({
          name: "PlayaMusic Web Playback SDK",
          getOAuthToken: (cb) => {
            cb(token);
          },
          volume: 1,
        });

        // setPlayer(player);

        player.addListener("ready", ({ device_id }) => {
          // console.log("Ready with Device ID", device_id);
          const transferPlaybackHere = async (device_id) => {
            await axios
              .put(
                `https://api.spotify.com/v1/me/player/play?device_id=${device_id}`,
                {
                  context_uri: `spotify:playlist:${playlistId}`,
                  position_ms: 0,
                },

                {
                  headers: {
                    authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                  },
                }
              )
              .then((response) => console.log(response.data, "response"))
              .catch((error) => {
                console.log(error);
              });
          };

          transferPlaybackHere(device_id);
        });

        player?.addListener("not_ready", ({ device_id }) => {
          // console.log("Device ID has gone offline", device_id);
        });

        player?.addListener("player_state_changed", (state) => {
          if (!state) {
            return;
          }

          setTrack(state.track_window.current_track);

          setPaused(state.paused);
          setPlayerState(state);

          player?.getCurrentState().then((state) => {
            if (!state) {
              setActive(false);
              return;
            }

            setActive(true);
          });
        });

        player?.connect();
        dispatch(setGlobalPlayer({ player }));
      };
    }
  }, [location, token, playlistId]);

  useEffect(() => {
    if (current_track?.uid) {
      const roomDetails = {
        roomID: playlistId,
        roomName: playlistName,
        playlistId: playlistId,
        roomDescription: "Room description should be here",
        songAuthor: current_track?.artists[0]?.name || "Unknown",
        songTitle: current_track?.name || "Unknown",
        comments: chatCount,
        roomMembers: 0,
        image: current_track?.album?.images[0]?.url || PlayingMusicIcon,
        player: player,
        IsRoomOwner: true,
        currentIndex: 0,
        app: "spotify",
      };
      updateCard(roomDetails);
    }
  }, [current_track, is_paused, player, chatCount]);

  if (!is_active) return <LoadingScreen />;

  return (
    <section>
      <ScreenTitleContainer>
        <ScreenTitle title={playlistName} icon={<ChevronIcon />} />
        <IoPowerOutline
          color="#0F2F40"
          size={20}
          onClick={() => setOpenModal(true)}
        />
        {/* <div onClick={handleStopMusic}>
          <CloseIcon />
        </div> */}
      </ScreenTitleContainer>

      <Webplayback
        current_track={current_track}
        player={player}
        is_active={is_active}
        is_paused={is_paused}
        token={token}
        playerState={playerState}
        isOwner={true}
      />

      {openModal ? (
        <>
          <BottomModal
            title={`End ${playlistName} session`}
            bodyText={`Are you sure you want to close ${playlistName}?`}
            closeModal={closeModal}
            actionBtn="End"
            callBack={handleStopMusic}
            exitBtn="Cancel"
            icontype="close"
          />
          <BgOverlay />
        </>
      ) : null}
      <Toaster />
    </section>
  );
};

export default RoomOwnerScreen;

const RoomDetailsContainer = styled.div`
  p {
    color: #262626;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }

  button {
    display: flex;
    width: 76px;
    height: 30px;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 32px;
    background: #008135;
    color: var(--neutral-gray-2, #fafafa);
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    border: none;
    margin-top: 20px;
  }
`;
const RoomChatHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  border-bottom: 1px solid #d9d9d9;
  span {
    display: flex;
    flex-direction: row;
    align-items: center;
    p {
      font-family: Satoshi;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      margin: 5px;
    }
    span {
      display: flex;
      flex-direction: row;
      align-items: center;

      p {
        font-family: Satoshi;
        font-size: 12px;
        font-style: normal;
      }
    }
  }
`;
