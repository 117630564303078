//@ts-nocheck
import { redirect, useLocation, useNavigate } from "react-router-dom";
import { Navigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import styles from "styles/loginscreen.module.scss";
import { PasswordToggleIcon } from "icons/PasswordToggleIcon";
import { PiEyeThin } from "react-icons/pi";
import { useEffect, useState } from "react";
import { LoadingScreen } from "components/common/LoadingScreen";
import usePersist from "hooks/usePersist";
import { UserError } from "components/common/UserError";
import { useLoginMutation } from "features/auth/authApiSlice";
import { selectCurrentUser, setCredentials } from "features/auth/authSlice";
import cookies from "js-cookie";
import { refreshToken } from "services/spotify";
import NewUserConnectAccount from "./NewUserConnectAccount";
import { useSelector } from "react-redux";


const LoginScreen = () => {
  const [persist, setPersist] = usePersist();
  const currentLocation = useLocation()
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showConnectScreen, setShowConnectScreen] = useState<boolean>(false);
  const [login, { isLoading, error, isSuccess }] = useLoginMutation();

  const defaultFormState = {
    userName: { value: "", error: null },
    password: { value: "", error: null },
  };

  const [formState, setFormState] = useState(defaultFormState);
  const [isPassword, setIsPassword] = useState<boolean>(true);

  const onChangeHandler = (field: string, value: string) => {
    setFormState({
      ...formState,
      [field]: {
        value: value,
        error: null,
      },
    });
  };

  const handleSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    let hasErrors = handleFormValidations();

    const updatedState = { ...formState };

    const { userName, password } = updatedState;

    const formData = {
      username: userName.value,
      email: userName.value,
      password: password.value,
    };

    if (hasErrors) {
      return;
    }

    const { data } = await login(formData);
    // const { accessToken, user } = data;
    // if(data)
    if (data) {
      dispatch(setCredentials({ ...data }));
      cookies.set("playa_access_token", data.accessToken as string, {});
      cookies.set("playa_user", JSON.stringify(data.user));
      setFormState({
        userName: { value: "", error: null },
        password: { value: "", error: null },
      });

      if(data?.user?.spotifyAccessToken && data?.user?.spotifyAccessTokenExpiry && data?.user?.spotifyRefreshToken){
       if(!localStorage.getItem('spotify_access_token') && !localStorage.getItem('spotify_token_expire_time') && !localStorage.getItem('spotify_refresh_token') ){
         localStorage.setItem('spotify_access_token', data.user.spotifyAccessToken);
         localStorage.setItem('spotify_token_expire_time', data.user.spotifyAccessTokenExpiry);
         localStorage.setItem('spotify_refresh_token', data.user.spotifyRefreshToken);
         localStorage.setItem('spotify_token_timestamp', Date.now());
         refreshToken();
         window.location.reload();
       }
     }
    }
  };

  const handleFormValidations = () => {
    let updatedState = { ...formState };
    let error = false;

    const { userName, password } = updatedState;

    if (!userName.value) {
      updatedState.userName.error = "Enter a username or email";
      error = true;
    }

    if (!password?.value) {
      updatedState.password.error = "Enter a password";
      error = true;
    }

    setFormState({
      ...formState,
      ...updatedState,
    });
    return error;
  };

  const handleToggle = () => setPersist((prev) => !prev);

  const from = currentLocation.state?.from?.pathname || "/home";

  useEffect(() => {
    if (isSuccess) {
      navigate(from, { replace: true });
    }
  }, [isSuccess, from, navigate]);

  return (
   <>
    {showConnectScreen ? (
      <NewUserConnectAccount /> 
    ) : (
      <section className={styles.loginScreenWrapper}>
      <div className={styles.loginScreenContainer}>
        <div className={styles.loginCtaContainer}>
          <h5> Hello Playa</h5>
          <p>Log into your Playa Account</p>
        </div>

        {isLoading && <LoadingScreen />}

        <form onSubmit={handleSubmit}>
          <div className={styles.loginTextInputContainer}>
            <label htmlFor="username">Username or email</label>
            <input
              autoComplete="false"
              value={formState.userName?.value}
              onChange={(e) => onChangeHandler("userName", e.target.value)}
              className={`${
                formState.userName.error
                  ? `textInputErrorState`
                  : styles.loginTextInput
              } textInput`}
              placeholder="Enter username or email"
              type="text"
              id="username"
            />

            {(formState?.userName?.error || error) && (
              <UserError
                errorType={formState?.userName?.error}
                authenticationErrorType={error?.data?.message}
              />
            )}
          </div>

          <div className={styles.loginTextInputContainer}>
            <label htmlFor="password">Password</label>
            <div className={styles.passwordToggeWrapper}>
              <input
                value={formState.password?.value}
                onChange={(e) => onChangeHandler("password", e.target.value)}
                className={`${
                  formState.userName.error
                    ? `textInputErrorState`
                    : styles.loginTextInput
                } textInput`}
                placeholder="Enter password"
                type={isPassword ? "password" : "text"}
                id="password"
              />
              <div
                onClick={() => setIsPassword(!isPassword)}
                className={styles.passwordToggleIconWrapper}
              >
                {isPassword ? <PasswordToggleIcon /> : <PiEyeThin />}
              </div>
            </div>

            {formState?.password?.error && (
              <UserError errorType={formState.password.error} />
            )}
          </div>

          <div className={styles.loginForgetPasswordTextContainer}>
            <span
              className="linkButton"
              onClick={() => navigate("/forgetpassword")}
            >
              Forget password?
            </span>
          </div>
          <div className={styles.loginButtonContainer}>
            <button type="submit" className="primaryButton">
              Login
            </button>
            {/* <label
              // style={{ visibility: "hidden" }}
              htmlFor="persist"
              className="form__persist"
            >
              <input
                type="checkbox"
                className="form__checkbox"
                id="persist"
                onChange={handleToggle}
                checked={persist}
              />
              Trust This Device
            </label> */}
            <div>
              <div className={styles.loginSignupTextContainer}>
                <span>Don't have an account?</span>
                <span
                  className="linkButton"
                  onClick={() => navigate("/signup")}
                >
                  Sign up
                </span>
              </div>
            </div>
          </div>
        </form>
      </div>
    </section>
    )}
   </>
  );
};

export default LoginScreen;
