import { useState } from "react";
import { useNavigate, Navigate, useLocation } from "react-router-dom";

import styles from "styles/resetpassword.module.scss";
import { BackIcon } from "icons/BackIcon";
import { LoadingScreen } from "components/common/LoadingScreen";
import OtpInput from "components/OtpInput";
import { UserError } from "components/common/UserError";
import { errorSignature } from "utils/errorSignature";
import {
  useResetPasswordMutation,
  useVerifyOtpMutation,
} from "features/auth/authApiSlice";

const ResetPasswordScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [otp, setOtp] = useState("");
  const onChange = (value: string) => setOtp(value);
  const [verifyOtp, { isLoading, isSuccess, error }] = useVerifyOtpMutation();
  const [resetPassword] = useResetPasswordMutation();

  if (isSuccess)
    return (
      <Navigate
        to="/confirmpassword"
        replace={false}
        state={location.state.emailAddress.value}
      />
    );

  return (
    <section className={styles.resetPasswordScreenWrapper}>
      <div className={styles.resetPasswordScreenContainer}>
        <div
          onClick={() => navigate(-1)}
          className={styles.resetPasswordCtaContainer}
        >
          <BackIcon />
          <h5> OTP Verification</h5>
        </div>
        <p>Check your email for the verification code sent</p>

        {isLoading && <LoadingScreen />}
        <div className={styles.otpFormContainer}>
          <OtpInput value={otp} valueLength={4} onChange={onChange} />
        </div>

        {error && (
          <UserError authenticationErrorType={errorSignature.INVALID_OTP} />
        )}

        <div className={styles.resetPasswordButtonContainer}>
          <button
            onClick={async () =>
              await verifyOtp({
                code: otp,
                email: location.state.emailAddress.value,
              })
            }
            className="primaryButton"
          >
            Confirm Code
          </button>
        </div>

        <div>
          <div className={styles.loginSignupTextContainer}>
            <span>Didn't get a code?</span>
            <button
              className="linkButton"
              onClick={async () =>
                resetPassword({ email: location.state.emailAddress.value })
              }
            >
              Resend
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ResetPasswordScreen;
