import { toast } from "react-hot-toast";
import axios, { AxiosHeaders, Method } from "axios";
import { InputType, InputTypeSpotify, OutputType } from "@types";
import Placeholder from "../assets/placeholder.png";
enum ToastPosition {
  TOPRIGHT = "top-right",
  TOPLEFT = "top-left",
  TOPCENTER = "top-center",
  BOTTOMLEFT = "bottom-left",
  BOTTOMRIGHT = "bottom-right",
  BOTTOMCENTER = "bottom-center",
}
interface ToastProps {
  msg: string;
  position?: ToastPosition;
}

const mtToastStyle = {
  style: {
    fontSize: "15px",
  },
};

export const PlayaToast = {
  success: ({ msg, position = ToastPosition.TOPRIGHT }: ToastProps) =>
    toast.success(msg, {
      position,
      duration: 6000,
      ...mtToastStyle,
    }),

  error: ({ msg, position = ToastPosition.TOPRIGHT }: ToastProps) =>
    toast.error(msg, { duration: 6000, position, ...mtToastStyle }),
  warn: ({ msg, position = ToastPosition.TOPRIGHT }: ToastProps) =>
    toast.custom(msg, {
      position,
      duration: 6000,
      style: {
        backgroundColor: "blue",
        fontSize: "15px",
        color: "blue",
      },
      className: "bg-primary-60 text-neutral white",
    }),
};

export const fetchData = async (
  url: string,
  method: Method,
  headers: AxiosHeaders,
  body?: any
) => {
  try {
    // Declare config with an optional 'data' property
    const config: {
      url: string;
      method: Method;
      headers: AxiosHeaders;
      data?: any;
    } = {
      url: url,
      method: method,
      headers: headers,
    };

    // Only add the body to the request config if the method is not 'GET'
    if (method.toLowerCase() !== "get") {
      config.data = body;
    }

    const response = await axios(config);
    return response;
  } catch (error) {
    console.error(`Error fetching data: ${error}`);
    throw error;
  }
};
export const transformManagePlaylistArray = (input: any): OutputType => {
  return input.map((item: any) => ({
    id: item?.id,
    title:
      item?.snippet?.localized?.title ?? item?.name ?? item.attributes.name,
    songCount: item?.tracks?.total ?? item?.songCount ?? null,
    img:
      item?.snippet?.thumbnails?.default?.url ||
      (item?.images && item.images.length > 0
        ? item.images[0]?.url
        : Placeholder),
    isToggled: true,
    isPlaylistSynced: false,
  }));
};

export const generateRandomString = () => {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  for (let i = 0; i < 10; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};
export const parseTitle = (title: string, subAuthor: string) => {
  let author = null;
  let songTitle = title;

  // Check if title contains a hyphen
  if (title.includes("-")) {
    // Split the title into parts based on the hyphen
    const parts = title.split("-");

    // Remove unwanted words and trim whitespace
    author = parts[0]?.trim();
    songTitle = parts[1]
      ?.replace(/\(.*?\)/g, "")
      .replace(/\[.*?\]/g, "")
      .trim();
  }

  // Remove any remaining parentheses or square brackets
  songTitle = songTitle.replace(/[()\[\]]/g, "");

  // If author is still null, use subAuthor
  if (!author) {
    author = subAuthor?.replace("- Topic", "")?.replace("Music", "").trim();
  }

  return { title: songTitle, author: author };
};

function deepCopy(obj: any) {
  let copy: any;

  // Handle the 3 simple types, and null or undefined
  if (null == obj || "object" != typeof obj) return obj;

  // Handle Array
  if (obj instanceof Array) {
    copy = [];
    for (let i = 0, len = obj.length; i < len; i++) {
      copy[i] = deepCopy(obj[i]);
    }
    return copy;
  }

  // Handle Object
  if (obj instanceof Object) {
    copy = {};
    for (let attr in obj) {
      if (obj.hasOwnProperty(attr)) copy[attr] = deepCopy(obj[attr]);
    }
    return copy;
  }

  throw new Error("Unable to copy obj! Its type isn't supported.");
}

export const shuffleArray = (array: any[]) => {
  // Create a deep copy of the array
  let copiedArray = deepCopy(array);

  let currentIndex = copiedArray.length,
    temporaryValue,
    randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = copiedArray[currentIndex];
    copiedArray[currentIndex] = copiedArray[randomIndex];
    copiedArray[randomIndex] = temporaryValue;
  }

  return copiedArray;
};

export const parseSpotifyTitleAndAuthor = (data: any) => {
  return {
    title: data?.title?.split("(")[0].trim(),
    author: data?.author?.split("&")[0].trim(),
  };
};
