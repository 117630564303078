import { OutputType } from "@types";
import { SyncedPlaylist } from "icons";
import { useState } from "react";
import Skeleton from "react-loading-skeleton";
import styled from "styled-components";
import { ItemType, PlaylistType } from "types";

interface playListArrItem {
  id: string;
  title: string;
  songCount: number | string | null;
  img: string;
  isToggled: boolean;
  isPlaylistSynced: boolean;
}

interface PageProps {
  playListArr: playListArrItem[];
  clickedItems: PlaylistType[];
  setClickedItems: React.Dispatch<React.SetStateAction<PlaylistType[]>>;
  fetchingPlaylist: boolean;
}


const SelectPlaylist: React.FC<PageProps> = ({
  playListArr,
  clickedItems,
  setClickedItems,
  fetchingPlaylist
}) => {
  const [selectAllChecked, setSelectAllChecked] = useState<boolean>(false);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);

  const handleClick = (item: ItemType) => {
    setClickedItems((prevState: PlaylistType[]) => {
      const isItemInClickedItems = prevState.some(playlist => playlist.playlistId === item.id);

      if (isItemInClickedItems) {
        // Item is already selected, so deselect it
        return prevState.filter(playlist => playlist.playlistId !== item.id);
      } else {
        // Item is not selected, so add it to the clicked items
        return [
          ...prevState,
          { playlistName: item.title, playlistId: item.id },
        ];
      }
    });
  };


  const handleSelectAll = () => {
    if (!selectAllChecked) {
      for (const item of playListArr) {
        setClickedItems((prevState: PlaylistType[]) => {
          // Check if the item is already in clickedItems
          const isItemInClickedItems = prevState.some(playlist => playlist.playlistId === item.id);

          if (!isItemInClickedItems) {
            // Item is not in clickedItems, add it to the state
            return [
              ...prevState,
              { playlistName: item.title, playlistId: item.id },
            ];
          } else {
            // Item is already in clickedItems, return previous state unchanged
            return prevState;
          }
        });
      }
      setSelectedItems(playListArr.map(item => item.id));
    } else {
      setSelectedItems([]);
      setClickedItems([]);
    }
    setSelectAllChecked(!selectAllChecked);
  }

  const isItemSelected = (itemId: string) => {
    return selectedItems.includes(itemId);
  };

  const selectedChange = (item: ItemType) => {
    if (selectedItems.includes(item.id)) {
      setSelectedItems(selectedItems.filter(id => id !== item.id));
    } else {
      setSelectedItems([...selectedItems, item.id]);
    }
  }

  console.log(clickedItems, "clicked playlist");
  // console.log(playListArr, "playlists");
  return (
    <PlaylistContentContainer>
      <SelectAll onClick={handleSelectAll}>
        <p>{selectAllChecked ? "Deselect all" : "Select all"}</p>
      </SelectAll>
      {!fetchingPlaylist ? (
        <>
          {playListArr &&
            playListArr.length > 0 &&
            playListArr.map((item) => (
              <PlaylistContent
                key={item.id}
                onClick={() => handleClick(item)}
              >
                <PlaylistContentLeft htmlFor={item.id}>
                  <img src={item.img} alt="Song Poster" />
                  <div>
                    <h6>{item.title}</h6>
                    <p>{item?.songCount} songs</p>
                  </div>
                </PlaylistContentLeft>

                <PlaylistContentRight>
                  {item.isPlaylistSynced && <SyncedPlaylist />}
                  <CheckboxContainer>
                    <input type="checkbox" checked={isItemSelected(item.id)} onChange={() => selectedChange(item)} id={item.id} />
                  </CheckboxContainer>
                </PlaylistContentRight>
              </PlaylistContent>
            ))}
        </>
      ) : (
        <>
          <Skeleton style={{ height: "50px" }} />
          <Skeleton style={{ height: "50px" }} />
          <Skeleton style={{ height: "50px" }} />
          <Skeleton style={{ height: "50px" }} />
          <Skeleton style={{ height: "50px" }} />
        </>
      )}

    </PlaylistContentContainer>
  )
}

export default SelectPlaylist;

const PlaylistContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 20px;
  margin-top: 40px;
  max-height: 65vh;
  overflow-y: auto;
`;

const PlaylistContent = styled.div`
  padding: 12px 16px;
  border: solid 1px #f0f0f0;
  border-radius: 3px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  img {
    width: 34px;
    height: 34px;
  }
`;

const PlaylistContentLeft = styled.label`
  display: flex;
  gap: 8px;
  align-items: center;
  h6 {
    font-family: Satoshi;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    color: #1f1f1f;
  }
  p {
    font-family: Satoshi;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    color: #8c8c8c;
  }
`;

const PlaylistContentRight = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 10px 20px;

  input[type="checkbox"] {
    height: 22px;
    width: 22px;
    cursor: pointer;
    background-color: #ffffff;
    outline: #D9D9D9;
    apperance: none;
    -webkit-appearance: none;
    border: solid 1px #D9D9D9;
    border-radius: 4px;
    position: relative;
  }

  input[type="checkbox"]:checked {
    background-color: #008135 !important;
  }

  input[type="checkbox"]::before {
    content: '';
    position: absolute;
    top: 2px;
    left: 6px;
    width: 8px;
    height: 13px;
    border: 3px solid transparent;
    border-left: none;
    border-top: none;
    transform: rotate(45deg) scale(1);
  }

  input[type="checkbox"]:checked::before {
    border-color: #fff;
    animation: checkAnim .2s ease;
  }

  @keyframes checkAnim {
    from{
      transform rotate(45deg) scale(0);
    }
  }
`;

const SelectAll = styled.div`
  display: flex;
  justify-content: right;

  p{
    font-size: 12px;
    color: #008135;
    font-family: Satoshi;
  }
`;