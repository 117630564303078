import React, { useEffect, useState } from "react";
import AiLogo from "../../assets/ai-logo.png";
import AiScreenBG from "../../assets/ai-bg.png";
import styled from "styled-components";
import { useCurateAiPlaylistMutation } from "features/auth/authApiSlice";
import { useNavigate } from "react-router-dom";
import AiPlaylistLoader from "components/Loader/AiPlaylistLoader";
import { Layout } from "components/Layout";

const AiScreen = () => {
  const [inputValue, setInputValue] = useState("");
  const [createPlaylist, { isLoading, error, isSuccess }] =
    useCurateAiPlaylistMutation();
  const navigate = useNavigate();

  const handleInputChange = (e: any) => {
    setInputValue(e.target.value);
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const data = await createPlaylist({ inputValue });
    navigate("/view-ai-playlist", { state: data });
  };

  return (
    <Layout>
      {!isLoading ? (
        <AiScreenContainer>
          <img src={AiLogo} alt="AI Logo" />
          <h2 onClick={handleSubmit}>Get creative with Playa AI</h2>
          <AiForm onSubmit={handleSubmit}>
            <input
              type="text"
              placeholder="Curate your playlist"
              value={inputValue}
              onChange={handleInputChange}
            />
            <button type="submit">Curate</button>
          </AiForm>
        </AiScreenContainer>
      ) : (
        <AiPlaylistLoader />
      )}
    </Layout>
  );
};

export default AiScreen;

const AiScreenContainer = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0px;
  left: 0;
  width: 100%;
  height: 100vh;
  background: url(${AiScreenBG});
  background-size: cover;
  background-position: center;
  z-index: 999;

  h2 {
    font-size: 32px;
    font-weight: 700;
    color: #fff;
    text-align: center;
    padding: 0 40px;
  }
`;

const AiForm = styled.form`
  background: #ffffff;
  padding: 10px;
  width: 100%;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  margin-top: 32px;

  input {
    border: none;
    outline: none;
    color: #000000;
    font-weight: 700;
    padding: 8px;
    border-radius: 4px;
    margin-bottom: 16px;
  }

  button {
    border: none;
    outline: none;
    margin-top: 16px;
    background: #008135;
    padding: 12px 16px;
    border-radius: 32px;
    color: #fafafa;
    font-weight: 700;
    cursor: pointer;
  }

  button:hover {
    background: #00692a;
  }
`;
