import { useNavigate } from "react-router-dom";
import { ForwardIcon } from "../icons/AccountIcons";
import React, { ReactNode } from "react";
import styled from "styled-components";

interface IProfileSettingButton {
  icon: ReactNode;
  title: string;
  screen: string;
  user?: any;
}

export const ProfileSettingButton = ({
  icon,
  title,
  screen,
  user,
}: IProfileSettingButton) => {
  const navigate = useNavigate();
  return (
    <StyledButton onClick={() => navigate(screen, { state: user })}>
      <div>
        {icon} {title}
      </div>
      <ForwardIcon />
    </StyledButton>
  );
};

const StyledButton = styled.button`
  display: flex;
  padding: 16px;
  justify-content: space-between;
  border-radius: 3px;
  border: 1px solid #f0f0f0;
  background: #fafafa;
  margin-bottom: 10px;
  width: 100%;

  div {
    display: flex;
    align-items: center;
    gap: 20px;
  }
`;
