//@ts-nocheck
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import styles from "../styles/connect.module.scss";
import { PlayaLogo } from "../icons/PlayaLogo";
import {
  AppleMusicIcon,
  AudiomacIcon,
  SportifyIcon,
  YoutubeMusicIcon,
} from "../icons/musicServicesIcon";
import toast from "react-hot-toast";
const ConnectServiceScreen = () => {
  const navigate = useNavigate();
  const { userInfo } = useSelector((state) => state.auth);
  const [searchParams] = useSearchParams();
  const queryParam = searchParams.get('errorConnect');

  const authWithSpotify = () => {
    window.open(
      `${process.env.REACT_APP_BACKEND_URL}/auth/login/spotify`,
      "_self",
    );
  };

  const authWithGoogle = () => {
    window.open(`${process.env.REACT_APP_BACKEND_URL}/auth/google`, "_self");
  };

  useEffect(() => {
    if (userInfo?.isSpotifyConnected || userInfo?.isGoogleConnected)
      navigate("/home");
  }, [navigate, userInfo?.isSpotifyConnected, userInfo?.isGoogleConnected]);

  useEffect(() => {
    if(queryParam){
      toast.error(queryParam);
    }
  }, []);

  return (
    <div className={styles.connectScreenWrapper}>
      <div className={styles.servicesHeader}>
        <PlayaLogo />
        <p className={styles.connectionscreenCta}>
          Connect your streaming service to host sessions
        </p>
        <p>and add songs to your playlist.</p>
      </div>

      <div className={styles.servicesContainer}>
        <button onClick={() => authWithSpotify()} className="connectionButton">
          Connect with <SportifyIcon />
        </button>
      </div>

      <div className={styles.servicesContainer}>
        <button onClick={() => authWithGoogle()}>
          Connect with <YoutubeMusicIcon />
        </button>
      </div>

      <div className={styles.servicesContainer}>
        <button>
          Connect with <AppleMusicIcon />
        </button>
      </div>

      <div className={styles.servicesContainer}>
        <button>
          Connect with <AudiomacIcon />
        </button>
      </div>

      <div className={styles.servicesContainer}>
        <button>
          Connect with <AudiomacIcon />
        </button>
      </div>

      <button className={styles.startplayingButton}>Start playing</button>
    </div>
  );
};

export default ConnectServiceScreen;
