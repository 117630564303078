import React from "react";

export const ConnectAccountIcon = () => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.75 15.8132C13.75 14.9272 14.4682 14.209 15.3542 14.209H18.7917V15.7673C18.7917 16.6786 18.0529 17.4173 17.1417 17.4173H15.3542C14.4682 17.4173 13.75 16.6991 13.75 15.8132Z"
      stroke="#0F2F40"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M2.75 17.6452C2.75 16.7592 3.46821 16.041 4.35417 16.041H7.33333V17.5993C7.33333 18.5106 6.59459 19.2493 5.68333 19.2493H4.35417C3.46821 19.2493 2.75 18.5311 2.75 17.6452Z"
      stroke="#0F2F40"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M7.33325 8.26952L18.7916 5.55664"
      stroke="#0F2F40"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.33325 17.4173V4.58398L18.7916 1.83398V15.443"
      stroke="#0F2F40"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const ManagePlaylistIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.5 3V17.5"
      stroke="#0F2F40"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.5 18.02C7.5 16.6283 8.62825 15.5 10.02 15.5H14.5V18.48C14.5 19.8717 13.3717 21 11.98 21H10.02C8.62825 21 7.5 19.8717 7.5 18.48V18.02Z"
      stroke="#0F2F40"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.5 7.0332L20.9417 8.56075V4.50669L14.5 3V7.0332Z"
      stroke="#0F2F40"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3 4H10"
      stroke="#0F2F40"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3 8H10"
      stroke="#0F2F40"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3 12H8"
      stroke="#0F2F40"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const SubscriptionIcon = () => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.83325 4.58268C1.83325 4.07642 2.24366 3.66602 2.74992 3.66602H19.2499C19.7562 3.66602 20.1666 4.07642 20.1666 4.58268V17.416C20.1666 17.9223 19.7562 18.3327 19.2499 18.3327H2.74992C2.24366 18.3327 1.83325 17.9223 1.83325 17.416V4.58268Z"
      stroke="#0F2F40"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M1.83325 7.33398H20.1666"
      stroke="#0F2F40"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.375 14.666H16.5"
      stroke="#0F2F40"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.1667 4.58398V11.9173"
      stroke="#0F2F40"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.83325 4.58398V11.9173"
      stroke="#0F2F40"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const LogoutIcon = () => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1926_8630)">
      <path
        d="M10.9962 2.75H2.75V19.25H11"
        stroke="#B42318"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.125 15.125L19.25 11L15.125 6.875"
        stroke="#B42318"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.33325 10.9961H19.2499"
        stroke="#B42318"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1926_8630">
        <rect width="22" height="22" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const ForwardIcon = () => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.25 5.5L13.75 11L8.25 16.5"
      stroke="#8C8C8C"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
