//@ts-nocheck
import { Navigate, useNavigate } from "react-router-dom";
import styles from "styles/forgetpassword.module.scss";
import { BackIcon } from "icons/BackIcon";
import { useState } from "react";
import { LoadingScreen } from "components/common/LoadingScreen";
import { UserError } from "components/common/UserError";
import { errorSignature } from "utils/errorSignature";
import { useCreateOtpCodeMutation } from "features/auth/authApiSlice";

const ForgetPasswordScreen = () => {
  const navigate = useNavigate();

  const [createOtpCode, { isLoading, isSuccess, error }] =
    useCreateOtpCodeMutation();

  const defaultFormState = {
    emailAddress: { value: "", error: null },
  };

  const [formState, setFormState] = useState(defaultFormState);

  const onChangeHandler = (field, value) => {
    setFormState({
      ...formState,
      [field]: {
        value: value,
        error: null,
      },
    });
  };

  const handleSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    let hasErrors = handleFormValidations();

    const updatedState = { ...formState };

    const { emailAddress } = updatedState;

    const formData = {
      email: emailAddress.value,
    };

    if (hasErrors) {
      return;
    }

    await createOtpCode(formData);
  };

  const handleFormValidations = () => {
    let updatedState = { ...formState };
    let error = false;

    const { emailAddress } = updatedState;

    const re =
      /^(([^<>()[\]\\.,;:\s@\\"]+(\.[^<>()[\]\\.,;:\s@\\"]+)*)|(\\".+\\"))@(([^<>()[\]\\.,;:\s@\\"]+\.)+[^<>()[\]\\.,;:\s@\\"]{2,})$/i;

    if (!emailAddress?.value) {
      //@ts-ignore
      updatedState.emailAddress.error = "Enter email address";
      error = true;
    }

    if (emailAddress?.value && !emailAddress?.value?.match(re)) {
      //@ts-ignore
      updatedState.emailAddress.error = "Email address is invalid";
      error = true;
    }

    setFormState({
      ...formState,
      ...updatedState,
    });
    return error;
  };

  if (isSuccess) return <Navigate to="/otpverification" state={formState} />;
  return (
    <section className={styles.forgetPasswordScreenWrapper}>
      <div className={styles.forgetPasswordScreenContainer}>
        <div
          onClick={() => navigate(-1)}
          className={styles.forgetPasswordCtaContainer}
        >
          <BackIcon />
          <h5> Forget Password</h5>
        </div>
        <p>
          Don’t panic, it happens. Enter the email associated with the account
        </p>

        {isLoading && <LoadingScreen />}
        <form className={styles.resetPasswordForm} onSubmit={handleSubmit}>
          <div className={styles.forgetPasswordTextInputContainer}>
            <label htmlFor="email">Email address</label>
            <input
              value={formState.emailAddress?.value}
              onChange={(e) => onChangeHandler("emailAddress", e.target.value)}
              className={`${
                formState.emailAddress.error
                  ? `textInputErrorState`
                  : styles.forgetPasswordTextInput
              } textInput`}
              placeholder="example@gmail.com"
              type="text"
              id="email"
            />
          </div>

          {(formState?.emailAddress?.error || error) && (
            <UserError
              errorType={formState?.emailAddress?.error}
              authenticationErrorType={errorSignature.EMAIL_DOES_NOT_EXIST}
            />
          )}

          <div className={styles.forgetPasswordButtonContainer}>
            <button type="submit" className="primaryButton">
              Reset Password
            </button>
          </div>
        </form>
      </div>
    </section>
  );
};

export default ForgetPasswordScreen;
