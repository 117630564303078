import { TopNav } from "components/TopNav";
import React, { FC, useState, useEffect, useContext } from "react";
import { Layout } from "components/Layout";
import { useGetRoomsQuery } from "features/auth/authApiSlice";
import { selectCurrentUser } from "features/auth/authSlice";
import { LoadingScreen } from "components/common/LoadingScreen";
import styled from "styled-components";
import { IUser, OutputType } from "@types";
import { useSelector } from "react-redux";
import cookies from "js-cookie";
import {
  getAllUserYoutubePlaylists,
  getYoutubeAccessToken,
} from "services/youtube";
import {
  getAccessToken,
  getUserPlaylistAsJson,
  searchForTrackInSpotify,
} from "services/spotify";
import AiIcon from "../assets/ai-image.png";
import { MusicKitContext } from "components/MusicKitController";
import {
  useCreateNewRoomMutation,
  useRefreshMutation,
} from "features/auth/authApiSlice";
import {
  LOCALSTORAGE_VALUES,
  getCurrentUserPlaylists,
  hasTokenExpired,
  refreshToken,
} from "services/spotify";
import { IPlayLists, IYoutubePlayLists } from "@types";
import { PlayaToast, transformManagePlaylistArray } from "utils";
import { PRIMARY_STREAM_SERVICE } from "contants/primaryStreamingService";
import {
  GOOGLE_LOCALSTORAGE_VALUES,
  refreshGoogleToken,
} from "services/youtube";
import HomePlaylists from "components/Home/HomePlaylists";
import { RWebShare } from "react-web-share";
import HomeCopyInitiate from "components/Home/CopyInitiate";
import { PlaylistType } from "types";
import {
  clearTotalTracks,
  totalTracks,
} from "features/tracks/tracksTotalSlice";
import { clearCopied, copied } from "features/tracks/copiedTracksSlice";
import { clearFailedTracks } from "features/tracks/failedTracksSlice";
import { useDispatch } from "react-redux";
import SelectPlaylistBottomModal from "components/ManagePlaylist/DestinationPlatformModal";
import ProgressBottomModal from "components/Playlist/ProgressBottomModal";
import { clearTracks } from "features/tracks/tracksSlice";
import CompletedBottomModal from "components/Playlist/CompletedBottomModal";
import { clearRoom, isCurrentPlayingMusic } from "app/api/otherSlice";
import BottomPlayingCard from "components/Home/BottomPlayingCard";
import AiScreen from "components/Home/AiScreen";
import { Link } from "react-router-dom";
// import {
//   getAllUserYoutubePlaylists,
//   refreshGoogleToken,
// } from "services/youtube";
interface IdefaultFormState {
  roomName: { value: string; error: null | string };
  roomDescription: { value: string; error: null | string };
  roomGenre: { value: string; error: null | string };
}

const HomeScreen: FC<IUser> = () => {
  const [selectedPlaylist, setSelectedPlaylist] = useState<IPlayLists | null>(
    null
  );
  const context = useContext(MusicKitContext);
  const [applemusicPlaylists, setAppleMusicPlaylists] = useState([]);
  const [refresh] = useRefreshMutation();

  const userr = useSelector(selectCurrentUser);
  const user =
    userr !== null ? userr : JSON.parse(cookies.get("playa_user") as string);

  const [token, setToken] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean | null>(true);
  const [initiateCopy, setInitiateCopy] = useState<boolean | null>(null);
  const [handleCopy, setHandleCopy] = useState<boolean | null>(null);
  const [playlists, setPlaylists] = useState<IPlayLists>();
  const [appType, setAppType] = useState<string>("");
  const [youtubePlaylists, setYoutubePlaylists] =
    useState<IYoutubePlayLists[]>();
  const [clickedItems, setClickedItems] = useState<PlaylistType[]>([]);
  const [copyPlaylistOngoing, setCopyPlaylistOngoing] =
    useState<boolean>(false);
  const [syncPlaylistOngoing, setSyncPlaylistOngoing] =
    useState<boolean>(false);
  const [actionCompleted, setActionCompleted] = useState<boolean>(false);
  const [playListArr, setPlayListArr] = useState<OutputType>([]);
  const totalTrackss = useSelector(totalTracks);
  const totalCopied = useSelector(copied);
  const [isFetchingPlaylist, setFetchingPlaylist] = useState<boolean>(true);
  const [progress, setProgress] = useState<number>(0);
  const [seeDetails, setSeeDetails] = useState<boolean>(true);
  const dispatch = useDispatch();
  const [hideModal, setHideModal] = useState<boolean>(false);
  const [isAiScreenActive, setIsAIScreenActive] = useState<boolean>(false);

  const isMusicPlaying = useSelector(isCurrentPlayingMusic);

  useEffect(() => {
    if (!isMusicPlaying) {
      dispatch(clearRoom());
    }
  });

  const handleAiScreenState = () => {
    setIsAIScreenActive(!isAiScreenActive)
  }

  const handleHideModals = () => {
    setHideModal(true);
  };

  const getYoutubePlaylistt = async () => {
    if (
      (GOOGLE_LOCALSTORAGE_VALUES.refreshToken !== "undefined" ||
        user?.googleRefreshToken) &&
      user?.primaryStreamingService === PRIMARY_STREAM_SERVICE.YOUTUBE_MUSIC
    ) {
      const { status, playlists } = await getAllUserYoutubePlaylists();
      console.log(playlists, "---data youtube", status);
      if (status === "success") {
        setAppType("youtube");
        setYoutubePlaylists(status === "success" ? playlists : []);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        refreshGoogleToken(user.googleRefreshToken);
      }
    }
  };
  const getApplePlaylists = async () => {
    console.log("apple section");
    const { data } = await context?.getPlaylists();
    setAppType("apple");
    setAppleMusicPlaylists(data);
    setIsLoading(false);
  };
  const getSpotifyPlaylists = async () => {
    const { status, data } = await getCurrentUserPlaylists();
    const res = await getCurrentUserPlaylists();
    console.log(res, "spotfuty section");
    if (res.status === 200) {
      setAppType("spotify");
      setPlaylists(data);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      await refresh("");
      if (LOCALSTORAGE_VALUES.refreshToken && hasTokenExpired()) {
        refreshToken();
        console.log("getting new spotify token....");
      }
    }
  };
  useEffect(() => {
    if (user?.primaryStreamingService === PRIMARY_STREAM_SERVICE.SPOTIFY) {
      getSpotifyPlaylists();
    }
    if (user?.primaryStreamingService === PRIMARY_STREAM_SERVICE.APPLE_MUSIC) {
      getApplePlaylists();
    }
    if (
      user?.primaryStreamingService === PRIMARY_STREAM_SERVICE.YOUTUBE_MUSIC
    ) {
      getYoutubePlaylistt();
    }
  }, []);

  useEffect(() => {
    const accessToken = getAccessToken();
    setToken(accessToken);
  }, [token]);

  const handleHideModal = () => {
    dispatch(clearCopied());
    dispatch(clearFailedTracks());
    dispatch(clearTotalTracks());
    setInitiateCopy(false);
    setHandleCopy(false);
  };

  const closeCopyPlaylistModal = () => {
    setCopyPlaylistOngoing(!copyPlaylistOngoing);
    dispatch(clearCopied());
    dispatch(clearFailedTracks());
    dispatch(clearTotalTracks());
    dispatch(clearTracks());
    setHideModal(false);
  };

  const closeSyncPlaylistModal = () => {
    setSyncPlaylistOngoing(!syncPlaylistOngoing);
    dispatch(clearCopied());
    dispatch(clearFailedTracks());
    dispatch(clearTotalTracks());
    dispatch(clearTracks());
  };

  useEffect(() => {
    if (totalCopied !== 0 && totalTrackss !== 0) {
      const percentageComplete = (totalCopied / totalTrackss) * 100;
      setProgress(percentageComplete);
    }
    console.log(
      totalCopied,
      totalTrackss,
      (totalCopied / totalTrackss) * 100,
      "totalCopied, totalTrackss,percentageComplete"
    );
  }, [totalCopied, totalTrackss]);

  const getCurrentStreamingPlaylist = async () => {
    if (
      user?.primaryStreamingService === PRIMARY_STREAM_SERVICE.YOUTUBE_MUSIC
    ) {
      const { status, playlists } = await getAllUserYoutubePlaylists();
      console.log(playlists);
      if (status === "success") {
        setFetchingPlaylist(false);
        setPlayListArr(
          status === "success" ? transformManagePlaylistArray(playlists) : []
        );
      } else {
        setFetchingPlaylist(false);
        refreshGoogleToken(userr.googleRefreshToken);
      }
    }

    if (user?.primaryStreamingService === PRIMARY_STREAM_SERVICE.APPLE_MUSIC) {
      const data = context && (await context.getPlaylists());
      // console.log(data.data, "apple playlist section");
      if (data && data?.data && data?.data?.length > 0) {
        setFetchingPlaylist(false);
        setPlayListArr(transformManagePlaylistArray(data?.data) ?? []);
      }
    }
    if (user?.primaryStreamingService === PRIMARY_STREAM_SERVICE.SPOTIFY) {
      const { status, data } = await getCurrentUserPlaylists();
      console.log(data?.items, "spotfuty section");
      if (status === 200) {
        setFetchingPlaylist(false);
        setPlayListArr(
          status === 200 ? transformManagePlaylistArray(data.items) : []
        );
      } else {
        setFetchingPlaylist(false);
        await refresh("");
        if (LOCALSTORAGE_VALUES.refreshToken && hasTokenExpired()) {
          refreshToken();
          console.log("getting new spotify token....");
        }
      }
    }
  };

  useEffect(() => {
    getCurrentStreamingPlaylist();
  }, []);

  return (
    <Layout>
      <TopNav {...user} />
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <HomeScreenWrapper>
          <HomePlaylists
            playlists={playlists}
            applemusicPlaylists={applemusicPlaylists}
            youtubePlaylists={youtubePlaylists}
            clickedItems={clickedItems}
            initiateCopy={setInitiateCopy}
            setClickedItems={setClickedItems}
          />

          <AiImage>
            <Link to="/curate-ai-playlists">
            <img src={AiIcon} />
            </Link>
          </AiImage>
        </HomeScreenWrapper>
      )}

      {initiateCopy && (
        <>
          <HomeCopyInitiate
            closeModal={() => handleHideModal()}
            callBack={setHandleCopy}
          />
          <BgOverlay />
        </>
      )}

      {handleCopy && !actionCompleted && (
        <>
          <SelectPlaylistBottomModal
            clickedItems={clickedItems}
            setClickedItems={setClickedItems}
            onGoingState={setCopyPlaylistOngoing}
            action="Copy"
            closeModal={handleHideModal}
            playListArr={playListArr}
            actionCompleted={setActionCompleted}
          />
          <BgOverlay />
        </>
      )}

      {(syncPlaylistOngoing || copyPlaylistOngoing) && !hideModal && (
        <>
          <ProgressBottomModal
            title={
              copyPlaylistOngoing
                ? "Copy in progress, please wait..."
                : "Sync in progress..."
            }
            bodyText={
              copyPlaylistOngoing
                ? `Copied ${totalCopied} of ${totalTrackss} songs`
                : "Syncing 10 of 100 songs to your playlist"
            }
            closeModal={
              syncPlaylistOngoing
                ? closeSyncPlaylistModal
                : closeCopyPlaylistModal
            }
            actionBtn="Hide"
            callBack={handleHideModals}
            exitBtn="Cancel"
            icontype="copy"
            progress={progress}
          />
          <BgOverlay />
        </>
      )}

      {actionCompleted && (
        <>
          <CompletedBottomModal
            title="We are done copying your tracks 🤩"
            numberOfTracks={totalTrackss}
            totalNumOfCopiedTracks={totalCopied}
            seeDetails={seeDetails}
            setSeeDetails={setSeeDetails}
            closeModal={() => {
              setActionCompleted(false);
              dispatch(clearCopied());
              dispatch(clearFailedTracks());
              dispatch(clearTotalTracks());
              dispatch(clearTracks());
            }}
          />
          <BgOverlay />
        </>
      )}
      
      {isMusicPlaying && <BottomPlayingCard />}
    </Layout>
  );
};

export default HomeScreen;

const HomeScreenWrapper = styled.section`
  max-width: 100vw;
  position: relative;
`;

const AiImage = styled.div`
  position: fixed;
  bottom: 80px;
  right: 20px;
  width: fit-content;
  z-index: 1000;
`;

export const BgOverlay = styled.div`
  width: 100%;
  height: 100vh;
  opacity: 0.48;
  background: #262626;
  z-index: 10;
  position: fixed;
  top: 0;
  left: 0;
`;
