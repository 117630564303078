// @ts-nocheck
import { createSlice } from "@reduxjs/toolkit";

const totalTracksSlice = createSlice({
  name: "totalTracks",
  initialState: 0,
  reducers: {
    setTotalTracks: (state, action) => {
      return state + action.payload; // Add the payload to the existing state
    },
    clearTotalTracks: () => {
      return 0; // Reset the state to its initial value
    },
  },
});


export const { setTotalTracks, clearTotalTracks } = totalTracksSlice.actions;

export default totalTracksSlice.reducer;

export const totalTracks = (state) => state.totalTracks;
