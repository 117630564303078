import React from "react";
import Skeleton from "react-loading-skeleton";

const AiPlaylistLoader = () => {
  return (
    <>
      <div style={{ width: "100%" }}>
        <Skeleton style={{ height: "30px", borderRadius: "4px" }} />
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          marginTop: "28px",
        }}
      >
        <Skeleton
          style={{ width: "200px", height: "200px", borderRadius: "4px" }}
        />
      </div>

      <div style={{ display: "flex", gap: "8px", marginTop: "40px" }}>
        <Skeleton
          style={{ height: "50px", width: "50px", borderRadius: "4px" }}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            gap: "8px",
          }}
        >
          <Skeleton
            style={{ height: "20px", width: "180px", borderRadius: "4px" }}
          />
          <Skeleton
            style={{ height: "20px", width: "180px", borderRadius: "4px" }}
          />
        </div>
      </div>

      <div style={{ display: "flex", gap: "8px", marginTop: "40px" }}>
        <Skeleton
          style={{ height: "50px", width: "50px", borderRadius: "4px" }}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            gap: "8px",
          }}
        >
          <Skeleton
            style={{ height: "20px", width: "180px", borderRadius: "4px" }}
          />
          <Skeleton
            style={{ height: "20px", width: "180px", borderRadius: "4px" }}
          />
        </div>
      </div>

      <div style={{ display: "flex", gap: "8px", marginTop: "40px" }}>
        <Skeleton
          style={{ height: "50px", width: "50px", borderRadius: "4px" }}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            gap: "8px",
          }}
        >
          <Skeleton
            style={{ height: "20px", width: "180px", borderRadius: "4px" }}
          />
          <Skeleton
            style={{ height: "20px", width: "180px", borderRadius: "4px" }}
          />
        </div>
      </div>

      <div style={{ display: "flex", gap: "8px", marginTop: "40px" }}>
        <Skeleton
          style={{ height: "50px", width: "50px", borderRadius: "4px" }}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            gap: "8px",
          }}
        >
          <Skeleton
            style={{ height: "20px", width: "180px", borderRadius: "4px" }}
          />
          <Skeleton
            style={{ height: "20px", width: "180px", borderRadius: "4px" }}
          />
        </div>
      </div>

      <div style={{ display: "flex", gap: "8px", marginTop: "40px" }}>
        <Skeleton
          style={{ height: "50px", width: "50px", borderRadius: "4px" }}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            gap: "8px",
          }}
        >
          <Skeleton
            style={{ height: "20px", width: "180px", borderRadius: "4px" }}
          />
          <Skeleton
            style={{ height: "20px", width: "180px", borderRadius: "4px" }}
          />
        </div>
      </div>
    </>
  );
};

export default AiPlaylistLoader;
