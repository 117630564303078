// @ts-nocheck
import { createSlice } from "@reduxjs/toolkit";
import PlayingMusicIcon from "../../assets/Rectangle 89.png";
const otherSlice = createSlice({
  name: "other",
  initialState: {
    isCurrentPlayingMusic: false,
    cardDetails: {
      roomName: "Unknown",
      roomDescription: "Unknown",
      songAuthor: "Unknown",
      songTitle: "Unknown",
      comments: 0,
      roomMembers: 0,
      image: PlayingMusicIcon,
      player: null,
      IsRoomOwner: false,
    },
    globalPlayer: null,
  },
  reducers: {
    setIsCurrentPlayingMusic: (state, action) => {
      const { isCurrentPlayingMusic } = action.payload;
      state.isCurrentPlayingMusic = isCurrentPlayingMusic;
    },
    setCardDetails: (state, action) => {
      const { cardDetails } = action.payload;
      state.cardDetails = cardDetails;
    },
    setGlobalPlayer: (state, action) => {
      const { player } = action.payload;
      state.globalPlayer = player;
    },
    clearRoom: (state) => {
      state.isCurrentPlayingMusic = false;
      state.globalPlayer = null;
      state.cardDetails = {
        roomName: "Unknown",
        roomDescription: "Unknown",
        songAuthor: "Unknown",
        songTitle: "Unknown",
        comments: 0,
        roomMembers: 0,
        image: PlayingMusicIcon,
        player: null,
        IsRoomOwner: false,
      };
      // return {
      //   isCurrentPlayingMusic: false,
      //   cardDetails: {
      //     roomName: "Unknown",
      //     roomDescription: "Unknown",
      //     songAuthor: "Unknown",
      //     songTitle: "Unknown",
      //     comments: 0,
      //     roomMembers: 0,
      //     image: PlayingMusicIcon,
      //     player: null,
      //     IsRoomOwner: false,
      //   },
      //   globalPlayer: null,
      // };
    },
  },
});

export const {
  setIsCurrentPlayingMusic,
  setCardDetails,
  setGlobalPlayer,
  clearRoom,
} = otherSlice.actions;

export default otherSlice.reducer;

export const isCurrentPlayingMusic = (state) =>
  state?.other?.isCurrentPlayingMusic;
export const cardDetails = (state) => state?.other?.cardDetails;
export const globalPlayer = (state) => state?.other?.globalPlayer;
