import React from "react";

import { ErrorIcon } from "../../icons/ErrorIcon";

export type UserErrorProps = {
  errorType?: null | string;
  authenticationErrorType?: string;
};

export const UserError = ({
  errorType,
  authenticationErrorType,
}: UserErrorProps) => {
  return (
    <div className="errorStateContainer">
      {(errorType || authenticationErrorType) && <ErrorIcon />}
      <small className="error">
        {errorType || (authenticationErrorType && authenticationErrorType)}
      </small>
    </div>
  );
};
