// @ts-nocheck
import { createSlice } from "@reduxjs/toolkit";

const copiedTracksSlice = createSlice({
  name: "copied",
  initialState: 0,
  reducers: {
    setCopied: (state, action) => {
      return state + action.payload; 
    },
    clearCopied: () => {
      return 0; // Reset the state to its initial value
    },
  },
});

export const { setCopied, clearCopied } = copiedTracksSlice.actions;

export default copiedTracksSlice.reducer;

export const copied = (state) => state.copied;
