import { useState, useEffect, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import styles from "styles/resetpassword.module.scss";
import { BackIcon } from "icons/BackIcon";
import { LoadingScreen } from "components/common/LoadingScreen";
import OtpInput from "components/OtpInput";
import { UserError } from "components/common/UserError";
import { errorSignature } from "utils/errorSignature";
import {
  useCreateVerificationCodeMutation,
  useVerifyAccountMutation,
} from "features/auth/authApiSlice";
import NewUserConnectAccount from "./NewUserConnectAccount";
import { useDispatch } from "react-redux";
import { setCredentials } from "features/auth/authSlice";
import cookies from "js-cookie";

const AccountVerificationScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [showConnectAccount, setShowConnectAccount] = useState<boolean>(false);
  const [otp, setOtp] = useState<string>("");
  const dispatch = useDispatch();

  const [verifyAccount, { error, isLoading, isSuccess }] =
    useVerifyAccountMutation();
  const [createVerificationCode] = useCreateVerificationCodeMutation();

  const handleChange = useCallback((value: string) => setOtp(value), []);

  const handleSubmit = useCallback(async () => {
    try {
      const result = await verifyAccount({
        code: otp,
        email: location.state.emailAddress.value,
      });

      if ("data" in result) {
        const { data } = result;
        const { message, status, ...restData } = data;

        dispatch(setCredentials({ ...restData, message, status }));
        cookies.set("playa_access_token", data.accessToken);
        cookies.set("playa_user", JSON.stringify(data.user));
      } else {
        // Handle the error case
        console.error("Verification error", result.error);
      }
    } catch (error) {
      console.error("Verification failed", error);
      // Handle unexpected errors
    }
  }, [verifyAccount, otp, location.state.emailAddress.value, dispatch]);

  useEffect(() => {
    if (isSuccess) {
      setShowConnectAccount(true);
    }
  }, [isSuccess]);

  if (showConnectAccount) {
    return <NewUserConnectAccount />;
  }

  return (
    <section className={styles.resetPasswordScreenWrapper}>
      <div className={styles.resetPasswordScreenContainer}>
        <div
          onClick={() => navigate(-1)}
          className={styles.resetPasswordCtaContainer}
        >
          <BackIcon />
          <h5>Account Verification</h5>
        </div>
        <p>Check your email for the verification code sent</p>

        {isLoading && <LoadingScreen />}
        <div className={styles.otpFormContainer}>
          <OtpInput value={otp} valueLength={4} onChange={handleChange} />
        </div>

        {error && (
          <UserError authenticationErrorType={errorSignature.INVALID_OTP} />
        )}

        <div className={styles.resetPasswordButtonContainer}>
          <button onClick={handleSubmit} className="primaryButton">
            Confirm Code
          </button>
        </div>

        <div className={styles.loginSignupTextContainer}>
          <span>Didn't get a code?</span>
          <button
            className="linkButton"
            onClick={async () =>
              await createVerificationCode({
                email: location.state.emailAddress.value,
              })
            }
          >
            Resend
          </button>
        </div>
      </div>
    </section>
  );
};

export default AccountVerificationScreen;
