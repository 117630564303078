import { setTracks } from "features/tracks/tracksSlice";
import {
  createPlaylistTracks,
  getPlaylistTracks,
  searchForTrackInSpotify,
} from "./spotify";
import {
  createNewYoutubePlaylist,
  getAllTracksInYoutubePlaylist,
  searchForTrackInYoutube,
} from "./youtube";
import { setFailedTracks } from "features/tracks/failedTracksSlice";
import { setTotalTracks } from "features/tracks/tracksTotalSlice";
import { setCopied } from "features/tracks/copiedTracksSlice";
import { parseSpotifyTitleAndAuthor } from "utils";

export const youtubeToSpotify = async (
  playlistData,
  dispatch,
  numOfCopiedTrack
) => {
  let totalTracks = 0; // Initialize total tracks count
  const failedTracks = [];
  let tracks;
  const playlistResults = await Promise.all(
    playlistData.map(async (playlist) => {
      if (playlist.playlistId) {
        const { tracks: data } = await getAllTracksInYoutubePlaylist(
          playlist.playlistId
        );
        tracks = data;
        totalTracks += tracks?.length; // Add the number of tracks in the current playlist to the total
      } else if (playlist.selectedTracks) {
        tracks = playlist.selectedTracks;
        totalTracks += tracks.length;
      }
      dispatch(setTracks(tracks));
      dispatch(setTotalTracks(totalTracks));

      const uris = [];

      for (const track of tracks) {
        try {
          const resp = await searchForTrackInSpotify(
            track.songTitle ?? track?.name,
            track.author ?? track?.artist
          );
          uris.push({ track: { uri: resp.uri } });
          dispatch(setCopied(1));
        } catch (error) {
          console.error(
            `Failed to find track: ${track.songTitle} by ${track.author}`
          );
          failedTracks.push({ title: track.songTitle, author: track.author });
        }
      }

      const playlist_name = playlist.playlistName;
      const items = uris;

      const res = await createPlaylistTracks(playlist_name, items, dispatch);
      return res;
    })
  );
  dispatch(setFailedTracks(failedTracks));
  return { playlistResults, totalTracks }; // Return the results and the total number of tracks
};

// SPOTIFY TO YOUTUBE
export const spotifyToYoutube = async (
  playlistData,
  dispatch,
  numOfCopiedTrack
) => {
  let totalTracks = 0; // Initialize total tracks count
  const failedTracks = [];
  let tracks;
  const playlistResults = await Promise.all(
    playlistData.map(async (playlist) => {
      if (playlist.playlistId) {
        const playlist_id = playlist.playlistId;
        const { data } = await getPlaylistTracks(playlist_id);
        tracks = data;
        totalTracks += tracks?.items.length; // Add the number of tracks in the current playlist to the total
      } else if (playlist.selectedTracks) {
        tracks = playlist.selectedTracks;
        totalTracks += tracks.length;
      }

      dispatch(setTotalTracks(totalTracks));

      const uris = [];
      const failedTracks = [];

      for (const track of tracks?.items ?? tracks) {
        try {
          const resp = await searchForTrackInYoutube(
            track?.track?.name ?? track?.name
          );

          uris.push({ videoId: resp.trackDetails.id.videoId });
        } catch (error) {
          console.error(
            `Failed to find track: ${track.songTitle} by ${track.author}`
          );
          failedTracks.push({ title: track.songTitle, author: track.author });
        }
      }

      const playlist_name = playlist.playlistName;
      const items = uris;
      const res = await createNewYoutubePlaylist(
        playlist_name,
        "",
        items,
        dispatch
      );

      return res;
    })
  );

  dispatch(setFailedTracks(failedTracks));
  return { playlistResults, totalTracks }; // Return the results and the total number of tracks
};

export const spotifyToSpotify = async (
  playlistData,
  dispatch,
  numOfCopiedTrack
) => {
  let totalTracks = 0; // Initialize total tracks count
  const failedTracks = [];
  let tracks;
  const playlistResults = await Promise.all(
    playlistData.map(async (playlist) => {
      const uris = [];
      const failedTracks = [];
     
      if(!playlist.playlistId){
        dispatch(setTotalTracks(playlist.selectedTracks.length));
        for (const track of playlist.selectedTracks) {
          try {
            const resp = await searchForTrackInSpotify(
              track?.title,
              track?.artistName
            );
            uris.push({ track: { uri: resp.uri } });
            dispatch(setCopied(1));
          } catch (error) {
            console.error(
              `Failed to find track: ${track.songTitle} by ${track.author}`
            );
            failedTracks.push({ title: track.songTitle, author: track.author });
          }
        }
  
        const playlist_name = playlist.playlistName;
        const items = uris;
 
        const res = await createPlaylistTracks(playlist_name, items, playlist.playlistImage, dispatch);
        return res;
      }else{
        if (playlist.playlistId) {
          const playlist_id = playlist.playlistId;
          const { data } = await getPlaylistTracks(playlist_id);
          tracks = data;
          totalTracks += tracks?.items.length; // Add the number of tracks in the current playlist to the total
        } else if (playlist.selectedTracks) {
          tracks = playlist.selectedTracks;
          totalTracks += tracks.length;
        }
  
        dispatch(setTotalTracks(totalTracks));
       
        
        for (const track of tracks?.items ?? tracks) {
          try {
            const resp = await searchForTrackInSpotify(
              track?.track?.name ?? track?.name,
              track?.track?.artists[0]?.name ?? track?.artist
            );
            uris.push({ track: { uri: resp.uri } });
            dispatch(setCopied(1));
          } catch (error) {
            console.error(
              `Failed to find track: ${track.songTitle} by ${track.author}`
            );
            failedTracks.push({ title: track.songTitle, author: track.author });
          }
        }
  
        const playlist_name = playlist.playlistName;
        const items = uris;
  
        const res = await createPlaylistTracks(playlist_name, items, dispatch);
        return res;
      }
     
    })
  );

  dispatch(setFailedTracks(failedTracks));
  return { playlistResults, totalTracks }; // Return the results and the total number of tracks
};

export const spotifyToApple = async (
  clickedItems,
  context,
  dispatch,
  numOfCopiedTrack
) => {
  const results = [];
  const failedTracks = [];
  let tracks;
  for (let playlist of clickedItems) {
    if (playlist.playlistId) {
      const { data } = await getPlaylistTracks(playlist.playlistId);
      tracks = data;
    } else if (playlist.selectedTracks) {
      tracks = playlist.selectedTracks;
    }
    let totalSongs = 0;
    let totalFound = 0;
    let totalNotFound = 0;
    const finalSearchResults = [];

    dispatch(setTotalTracks(tracks.items.length ?? tracks.length));
    for (let item of tracks.items ?? tracks) {
      let parsedData = parseSpotifyTitleAndAuthor({
        title: item?.track?.name ?? item?.name,
        author: item?.track?.artists[0]?.name ?? item?.artist,
      });
      const data = {
        trackName: parsedData.title,
        artistName: parsedData.author,
        isrc: item?.track?.external_ids.isrc,
      };
      const res = await context?.searchAppleMusic(data);

      if (res && res?.id) {
        totalFound++;
        dispatch(setCopied(1));

        finalSearchResults.push({ id: res.id, type: res.type });
      } else {
        totalNotFound++;
        failedTracks.push({
          title: item?.track?.name,
          author: item?.track?.artists[0]?.name,
        });
      }

      totalSongs++;
    }

    const appleres = await context.createAppleMusicPlaylist({
      playlistName: playlist.playlistName,
      tracks: finalSearchResults,
    });

    results.push({
      playlistName: playlist.playlistName,
      totalSongs,
      totalFound,
      totalNotFound,
      finalSearchResults,
      ApplePlaylistId: appleres[0]?.id ?? "not created",
    });
  }
  dispatch(setFailedTracks(failedTracks));
  return results;
};

export const youtubeToApple = async (
  playlistData,
  context,
  dispatch,
  numOfCopiedTrack
) => {
  const results = [];
  const failedTracks = [];
  let tracks;
  for (const playlist of playlistData) {
    if (playlist.playlistId) {
      const { tracks: data } = await getAllTracksInYoutubePlaylist(
        playlist.playlistId
      );
      tracks = data;
    } else if (playlist.selectedTracks) {
      tracks = playlist.selectedTracks;
    }

    dispatch(setTracks(tracks));
    dispatch(setTotalTracks(tracks?.length));

    const finalSearchResults = [];

    for (const track of tracks) {
      const res = await context?.searchAppleMusic({
        trackName: track?.songTitle ?? track?.name,
        artistName: track?.author ?? track.artist,
      });

      if (res && res?.id) {
        finalSearchResults.push({ id: res.id, type: res.type });
        dispatch(setCopied(1));
      } else {
        failedTracks.push({
          title: track?.songTitle,
          author: track?.author,
        });
      }
    }

    const appleres = await context.createAppleMusicPlaylist({
      playlistName: playlist.playlistName,
      tracks: finalSearchResults,
    });

    results.push({
      playlistName: playlist.playlistName,
      totalSongs: tracks.length,
      totalFound: finalSearchResults.length,
      totalNotFound: failedTracks.length,
      finalSearchResults,
      ApplePlaylistId: appleres[0]?.id ?? "not created",
    });
  }
  dispatch(setFailedTracks(failedTracks));
  return results;
};

export const appleToSpotify = async (
  clickedItems,
  context,
  dispatch,
  numOfCopiedTrack
) => {
  const results = [];
  const failedTracks = [];
  for (let playlist of clickedItems) {
    const tracks = playlist.playlistId
      ? await context.getAppleMusicPlaylistTracks(playlist.playlistId)
      : playlist.selectedTracks;
    let totalSongs = 0;
    let totalFound = 0;
    let totalNotFound = 0;
    const finalSearchResults = [];

    dispatch(setTotalTracks(tracks.length));
    for (let item of tracks) {
      const data = {
        trackName: item?.attributes?.name,
        artistName: item?.attributes?.artistName,
        isrc: item?.attributes?.isrc,
      };
      const resss = playlist.playlistId
        ? await context?.searchAppleMusic(data)
        : { hasTracks: true };

      if (
        (resss.attributes &&
          resss.attributes?.isrc &&
          resss.attributes.artistName === item?.attributes?.artistName &&
          resss.attributes.name === item?.attributes?.name) ||
        resss?.hasTracks
      ) {
        let parsedData = parseSpotifyTitleAndAuthor({
          title: item?.attributes?.name ?? item?.name,
          author: item?.attributes?.artistName ?? item?.artist,
        });
        const res = await searchForTrackInSpotify(
          parsedData.title,
          parsedData.author,
          resss.attributes?.isrc ?? item?.isrc
        );

        if (res && res?.id) {
          totalFound++;
          dispatch(setCopied(1));

          finalSearchResults.push({ track: { uri: res.uri } });
        } else {
          totalNotFound++;
          failedTracks.push({
            title: item?.attributes?.name,
            author: item?.attributes?.artistName,
          });
        }
      } else {
        totalNotFound++;
        failedTracks.push({
          title: item?.attributes?.name,
          author: item?.attributes?.artistName,
        });
      }

      totalSongs++;
    }

    const spotifyRes = await createPlaylistTracks(
      playlist.playlistName,
      finalSearchResults,
      dispatch
    );

    results.push({
      playlistName: playlist.playlistName,
      totalSongs,
      totalFound,
      totalNotFound,
      finalSearchResults,
      appleToSpotifyPlaylistResult: spotifyRes,
    });
  }
  dispatch(setFailedTracks(failedTracks));
  return results;
};

export const appleToApple = async (
  clickedItems,
  context,
  dispatch,
  numOfCopiedTrack
) => {
  const results = [];
  const failedTracks = [];
  for (let playlist of clickedItems) {
    const tracks = playlist.playlistId
      ? await context.getAppleMusicPlaylistTracks(playlist.playlistId)
      : playlist.selectedTracks;
    let totalSongs = 0;
    let totalFound = 0;
    let totalNotFound = 0;
    const finalSearchResults = [];

    dispatch(setTotalTracks(tracks.length));
    for (let item of tracks) {
      const data = {
        trackName: item?.attributes?.name,
        artistName: item?.attributes?.artistName,
        isrc: item?.attributes?.isrc,
      };
      const resss = playlist.playlistId
        ? await context?.searchAppleMusic(data)
        : { hasTracks: true };

      if (
        (resss.attributes &&
          resss.attributes?.isrc &&
          resss.attributes.artistName === item?.attributes?.artistName &&
          resss.attributes.name === item?.attributes?.name) ||
        resss?.hasTracks
      ) {
        let parsedData = parseSpotifyTitleAndAuthor({
          title: item?.attributes?.name ?? item?.name,
          author: item?.attributes?.artistName ?? item?.artist,
        });
        const res = await searchForTrackInSpotify(
          parsedData.title,
          parsedData.author,
          resss.attributes?.isrc
        );

        if (res && res?.id) {
          totalFound++;
          dispatch(setCopied(1));

          finalSearchResults.push({ track: { uri: res.uri } });
        } else {
          totalNotFound++;
          failedTracks.push({
            title: item?.attributes?.name,
            author: item?.attributes?.artistName,
          });
        }
      } else {
        totalNotFound++;
        failedTracks.push({
          title: item?.attributes?.name,
          author: item?.attributes?.artistName,
        });
      }

      totalSongs++;
    }

    const appleres = await context.createAppleMusicPlaylist({
      playlistName: playlist.playlistName,
      tracks: finalSearchResults,
    });

    results.push({
      playlistName: playlist.playlistName,
      totalSongs,
      totalFound,
      totalNotFound,
      finalSearchResults,
      ApplePlaylistId: appleres[0]?.id ?? "not created",
    });
  }
  dispatch(setFailedTracks(failedTracks));
  return results;
};

export const getPreviewUrl = async (title, author, appleContext) => {
  let parsedData = parseSpotifyTitleAndAuthor({
    title,
    author,
  });
  const data = {
    trackName: parsedData.title,
    artistName: parsedData.author,
  };
  const res = await appleContext?.searchAppleMusic(data);
  return res?.attributes?.previews[0]?.url;
};
