import { Layout } from "components/Layout";
import React, { useState } from "react";
import styled from "styled-components";
import { ScreenTitle } from "components/ScreenTitle";
import { BackIcon } from "icons/BackIcon";
import { CloseIcon } from "icons";
import NotificationImageOne from "../assets/notification-image-one.png";
import NotificationImageTwo from "../assets/notification-image-two.png";
import NotificationImageThree from "../assets/notification-image-three.png";

const NotificationScreen = () => {
  // const [isNotificationEmpty, setIsNotificationEmpty] = useState(false);
  const isNotificationEmpty = false;

  const [notificationData, setNotificationData] = useState([
    {
      id: 1,
      title: "TheNinth followed you",
      img: NotificationImageOne,
      time: "2hr ago",
    },
    {
      id: 2,
      title: "Azeez started a room",
      img: NotificationImageTwo,
      time: "5min ago",
    },
    {
      id: 3,
      title: "Jabbar liked 2 of your songs ",
      img: NotificationImageThree,
      time: "few sec ago",
    },
  ]);

  const closeNotification = (id: number) => {
    const updatedNotifications = notificationData.filter(
      (notification) => notification.id !== id,
    );
    setNotificationData(updatedNotifications);
  };

  return (
    <Layout>
      <NotificationScreenContainer>
        <ScreenTitle title="Notifications" icon={<BackIcon />} />
        {isNotificationEmpty ? (
          <EmptyNotificationContentContainer>
            <p>You have no notification</p>
          </EmptyNotificationContentContainer>
        ) : (

          <ScreenWrapper>
        <div>Oops you caught us we are still working on this!</div>
      </ScreenWrapper>
          // <NotificationContentContainer>
          //   {notificationData &&
          //     notificationData.length > 0 &&
          //     notificationData.map((item) => (
          //       <NotificationContent key={item.id}>
          //         <NotificationContentDetails>
          //           <img src={item.img} alt="Notification"></img>
          //           <div>
          //             <h5>{item.title}</h5>
          //             <p>{item.time}</p>
          //           </div>
          //         </NotificationContentDetails>
          //         <div onClick={() => closeNotification(item.id)}>
          //           <CloseIcon />
          //         </div>
          //       </NotificationContent>
          //     ))}
          // </NotificationContentContainer>
        )}
      </NotificationScreenContainer>
    </Layout>
  );
};

export default NotificationScreen;

const NotificationScreenContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
`;

const EmptyNotificationContentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh;

  p {
    color: #434343;
    text-align: center;
    font-family: Satoshi;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
  }
`;

const NotificationContentContainer = styled.section`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 32px;
`;

const NotificationContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 10px;
`;

const NotificationContentDetails = styled.div`
  display: flex;
  gap: 10px;

  img {
    width: 34px;
    height: 34px;
  }

  h5 {
    font-family: Satoshi;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    color: #1f1f1f;
  }

  p {
    font-family: Satoshi;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    color: #8c8c8c;
  }
`;


const ScreenWrapper = styled.div`
  display: flex;
  height: 80vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;