import React from "react";
export const ErrorIcon = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.99998 11.3333C8.18887 11.3333 8.34731 11.2693 8.47531 11.1413C8.60331 11.0133 8.66709 10.8551 8.66665 10.6666C8.66665 10.4778 8.60265 10.3193 8.47465 10.1913C8.34665 10.0633 8.18842 9.99954 7.99998 9.99998C7.81109 9.99998 7.65265 10.064 7.52465 10.192C7.39665 10.32 7.33287 10.4782 7.33331 10.6666C7.33331 10.8555 7.39731 11.014 7.52531 11.142C7.65331 11.27 7.81154 11.3338 7.99998 11.3333ZM7.33331 8.66665H8.66665V4.66665H7.33331V8.66665ZM7.99998 14.6666C7.07776 14.6666 6.21109 14.4915 5.39998 14.1413C4.58887 13.7911 3.88331 13.3162 3.28331 12.7166C2.68331 12.1166 2.20842 11.4111 1.85865 10.6C1.50887 9.78887 1.33376 8.9222 1.33331 7.99998C1.33331 7.07776 1.50842 6.21109 1.85865 5.39998C2.20887 4.58887 2.68376 3.88331 3.28331 3.28331C3.88331 2.68331 4.58887 2.20842 5.39998 1.85865C6.21109 1.50887 7.07776 1.33376 7.99998 1.33331C8.9222 1.33331 9.78887 1.50842 10.6 1.85865C11.4111 2.20887 12.1166 2.68376 12.7166 3.28331C13.3166 3.88331 13.7918 4.58887 14.142 5.39998C14.4922 6.21109 14.6671 7.07776 14.6666 7.99998C14.6666 8.9222 14.4915 9.78887 14.1413 10.6C13.7911 11.4111 13.3162 12.1166 12.7166 12.7166C12.1166 13.3166 11.4111 13.7918 10.6 14.142C9.78887 14.4922 8.9222 14.6671 7.99998 14.6666Z"
      fill="#B42318"
    />
  </svg>
);
