// @ts-nocheck
import { createSlice } from "@reduxjs/toolkit";

const failedTracksSlice = createSlice({
    name: "failedTracks",
    initialState: [],
    reducers: {
        setFailedTracks: (state, action) => {
            return action.payload;
        },
        clearFailedTracks: () => {
            return []; // Reset the state to its initial value
          },
    },
});

export const { setFailedTracks, clearFailedTracks } = failedTracksSlice.actions;

export default failedTracksSlice.reducer;

export const failedTracks = (state) => state.failedTracks;
