import { selectCurrentUser } from "features/auth/authSlice";
import React, { FC, useContext, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import cookies from "js-cookie";
import styled from "styled-components";
import { HomeCopyIcon, HomePlayIcon, HomeShareIcon } from "icons";
import PlaylistImage from "assets/playa-play.svg";
import { IYoutubePlayLists } from "@types";
import { getAllTracksInYoutubePlaylist } from "services/youtube";
import { useSharePlaylistMutation } from "features/auth/authApiSlice";
import { getPreviewUrl } from "services/conversion";
import { MusicKitContext } from "components/MusicKitController";
import { RWebShare } from "react-web-share";

interface PlaylistsProps {
  playlists: any;
  clickedItems: any;
  setClickedItems: any;
  initiateCopy: any;
}

const HomeYoutubePlaylist: FC<PlaylistsProps> = ({
  playlists,
  clickedItems,
  setClickedItems,
  initiateCopy,
}) => {
  const userr = useSelector(selectCurrentUser);
  const user =
    userr !== null ? userr : JSON.parse(cookies.get("playa_user") as string);
  const navigate = useNavigate();

  const triggerShareRef = useRef<HTMLDivElement | null>(null);

  const [
    createSharedPlaylist,
    { data: newSharedPlaylist, isSuccess, isLoading, error: sharedRoomError },
  ] = useSharePlaylistMutation();

  const [sharableUrl, setShareAbleUrl] = useState<string | undefined>(
    undefined
  );

  const [sharedPlaylistId, setSharedPlaylistId] = useState<string | undefined>(
    undefined
  );
  const context = useContext(MusicKitContext);
  const youtubePlayListTracks = async (playlist: IYoutubePlayLists) => {
    const { tracks } = await getAllTracksInYoutubePlaylist(playlist?.id);

    if (tracks) {
      const playlistDetails = await Promise.all(
        tracks.map(async (track: any) => {
          const previewUrl = await getPreviewUrl(
            track?.songTitle,
            track?.author,
            context
          );

          return {
            name: track?.songTitle,
            artist: track?.author,
            track_id: track?.id,
            image_url: track?.image,
            preview_url: previewUrl ?? "no preview",
          };
        })
      );

      const sharedPlaylistData = {
        userId: userr._id,
        playlistName: playlist?.snippet?.title,
        playlistId: playlist?.id,
        streamingService: "youtube",
        tracks: playlistDetails,
        playlistBannerUrl: playlist?.snippet.thumbnails?.default?.url
          ? playlist?.snippet.thumbnails?.medium?.url
          : "",
      };

      const result = await createSharedPlaylist(sharedPlaylistData);
      if ("data" in result && result.data?._id) {
        const url = `https://app.playamusic.io/shared-playlist/${result.data._id}`;
        setShareAbleUrl(url);
        return url;
      }
    }
    return null;
  };

  const handleShareClick = async (playlist: IYoutubePlayLists) => {
    setSharedPlaylistId(playlist.id);
    const url = await youtubePlayListTracks(playlist);
  };

  const selectPlaylistToBeCopied = (name: string, id: string) => {
    setClickedItems([{ playlistName: name, playlistId: id }]);
    initiateCopy(true);
  };

  const resetShare = () => {
    setShareAbleUrl("");
  }

  return (
    <>
      {playlists ? (
        <>
          {playlists?.map((playlist: IYoutubePlayLists, index: number) => {
            const { snippet, id, etag } = playlist;
            return (
              <PlaylistWrapper
                key={index}
                backgroundimage={
                  snippet.thumbnails?.default?.url
                    ? snippet.thumbnails?.medium?.url
                    : ""
                }
              >
                <PlaylistOverlay></PlaylistOverlay>
                <PlayIconWrapper
                  onClick={() =>
                    navigate(`/listeningroom/${playlist.id}`, {
                      state: { playlistName: snippet?.title },
                    })
                  }
                >
                  <HomePlayIcon />
                </PlayIconWrapper>
                <PlaylistBottomInfo>
                  <PlalistInfo>
                    <PlalistInfoImage>
                      <img src={PlaylistImage} alt="" />
                    </PlalistInfoImage>
                    <PlalistInfoTitle>
                      <h1>{snippet?.title ?? ""}</h1>
                      {/* <p>{playlist?.songCount} Song(s)</p> */}
                    </PlalistInfoTitle>
                  </PlalistInfo>

                  <PlaylistAction>
                    <PlaylistActionContent
                      onClick={() =>
                        selectPlaylistToBeCopied(snippet?.title, playlist.id)
                      }
                    >
                      <HomeCopyIcon></HomeCopyIcon>
                      <p>Copy playlist</p>
                    </PlaylistActionContent>

                    {sharableUrl && sharedPlaylistId == id ? (
                      <RWebShare
                        data={{
                          text: "Shared Playlist",
                          url: sharableUrl,
                          title: "Flamingos",
                        }}
                        onClick={resetShare}
                      >
                        <button
                          style={{
                            outline: "none",
                            background: "transparent",
                            color: "#fff",
                            display: "flex",
                            alignItems: "center",
                            gap: "4px",
                            border: "none",
                            fontWeight: 600,
                          }}
                        >
                          <HomeShareIcon></HomeShareIcon>
                          Share now
                        </button>
                      </RWebShare>
                    ) : (
                      <PlaylistActionContent
                        onClick={() => handleShareClick(playlist)}
                      >
                        <HomeShareIcon></HomeShareIcon>
                        <p>Share playlist</p>
                      </PlaylistActionContent>
                    )}
                  </PlaylistAction>
                </PlaylistBottomInfo>
              </PlaylistWrapper>
            );
          })}
        </>
      ) : (
        <p>No playlist avaliable</p>
      )}
    </>
  );
};

export default HomeYoutubePlaylist;

const PlaylistWrapper = styled.section<{ backgroundimage: string }>`
  border-radius: 16px;
  max-width: 100vw;
  background-image: url(${(props) => props.backgroundimage});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 444px;
  margin-top: 22px;
  position: relative;
`;

const PlaylistOverlay = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(6, 22, 30, 1) 60%
  );
  opacity: 0.5;
  border-radius: 16px;
`;

const PlalistInfo = styled.div`
  display: flex;
  align-items: center;
  padding: 16px;
  z-index: 1;
  gap: 8px;
`;

const PlalistInfoImage = styled.div`
  width: 50px;
  z-index: 1;

  img {
    width: 50px;
    z-index: 1;
  }
`;

const PlalistInfoTitle = styled.div`
  z-index: 1;
  h1 {
    color: #e3e3e3;
    font-size: 20px;
    font-weight: 700;
    z-index: 1;
  }

  p {
    color: #e3e3e3;
    font-weight: 500;
    font-size: 12px;
  }
`;

const PlaylistBottomInfo = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  margin-bottom: 20px;
`;

const PlayIconWrapper = styled.div`
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
  cursor: pointer;

  svg {
    z-index: 2;
  }
`;

const PlaylistAction = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  width: 100%;
  z-index: 10;
`;

const PlaylistActionContent = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  cursor: pointer;
  z-index: 10;

  p {
    color: #f5f5f5;
    font-size: 14px;
    font-weight: 700;
    z-index: 10;
  }
`;
