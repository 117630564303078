import { ApplePlaylist } from "components/ApplePlaylist";
import { selectCurrentUser } from "features/auth/authSlice";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import cookies from "js-cookie";
import { HomeCopyIcon, HomePlayIcon, HomeShareIcon } from "icons";
import PlaylistImage from "assets/playa-play.svg";
import { useSharePlaylistMutation } from "features/auth/authApiSlice";
import { FC, useContext, useEffect, useState } from "react";
import { MusicKitContext } from "components/MusicKitController";
import { getPreviewUrl } from "services/conversion";

interface PlaylistsProps {
  playlists: any;
  clickedItems: any;
  setClickedItems: any;
  initiateCopy: any;
}

export const HomeApplePlaylists: FC<PlaylistsProps> = ({
  playlists,
  clickedItems,
  setClickedItems,
  initiateCopy,
}) => {
  const userr = useSelector(selectCurrentUser);
  const user =
    userr !== null ? userr : JSON.parse(cookies.get("playa_user") as string);
  const navigate = useNavigate();

  const [
    createSharedPlaylist,
    { data: newSharedPlaylist, isSuccess, isLoading, error: sharedRoomError },
  ] = useSharePlaylistMutation();

  const [sharableUrl, setShareAbleUrl] = useState<string | undefined>(
    undefined
  );
const [playlistss, SetPlaylistss] = useState<any[]>([])
  const context = useContext(MusicKitContext);

  useEffect(() => {
    const getPlaylistAdditionalData = async () => {
      const updatedPlaylists: any[] = await Promise.all(
        playlists.map(async (i: any) => {
          const y = context ? await context.getAppleMusicPlaylistTracks(i.id) : null;
          return { ...i, songCount: y ? y.length : 0 };
        })
      );
      return updatedPlaylists;
    };
  
    if (playlists && playlists.length > 0) {
      getPlaylistAdditionalData().then(updatedPlaylists => {
        SetPlaylistss(updatedPlaylists as any[]);
      }).catch(error => {
        console.error('Error fetching playlist additional data:', error);
      });
    }
  }, [context, playlists]);
  
  
  const applePlayListTracks = async (playlist: any) => {
    const tracks =
      context && (await context?.getAppleMusicPlaylistTracks(playlist?.id));

    if (tracks) {
      const playlistDetails = await Promise.all(
        tracks.map(async (track: any) => {
          const previewUrl = await getPreviewUrl(
            track?.attributes?.albumName,
            track?.attributes?.artistName,
            context
          );

          return (
            track?.attributes?.albumName && {
              name: track?.attributes?.albumName,
              artist: track?.attributes?.artistName,
              track_id: track?.id,
              image_url: track?.attributes?.artwork?.url,
              preview_url: previewUrl ?? "no preview",
            }
          );
        })
      );
      const filteredPlaylistDetails = playlistDetails.filter((i) => i?.name);
      const sharedPlaylistData = {
        userId: userr._id,
        playlistName: playlist?.attributes?.name,
        playlistId: playlist?.id,
        streamingService: "apple",
        tracks: filteredPlaylistDetails,
        playlistBannerUrl: playlist?.attributes?.artwork?.url
          ? playlist?.attributes?.artwork?.url
          : "",
      };

      const result:any = await createSharedPlaylist(sharedPlaylistData);
      if (result && result?.data && result.data?._id) {
        const url = `https://app.playamusic.io/shared-playlist/${result.data._id}`;
        setShareAbleUrl(url);
        console.log(url, "copy url---");
        return url; // Return the shareable URL
      }
    }
    return null;
  };

  const handleShareClick = async (playlist: any) => {
    const url = await applePlayListTracks(playlist);

    console.log(url);

    if (url && navigator.share) {
      try {
        await navigator.share({
          title: "Shared Playlist",
          text: "Check out this playlist I shared!",
          url: url,
        });
        console.log("Content shared successfully");
      } catch (error) {
        console.error("Error sharing content:", error);
      }
    } else {
      console.error("Sharing not supported or URL is invalid");
    }
  };

  const selectPlaylistToBeCopied = (name: string, id: string) => {
    setClickedItems([{ playlistName: name, playlistId: id }]);
    initiateCopy(true);
  };

  return (
    <>
      {playlistss && playlistss.length > 0 ? (
        <>
          {playlistss.map((playlist: any, index: any) => {
            const { attributes, id, name } = playlist;
            const artworkUrl = attributes?.artwork?.url || "";
            const playlistName = attributes?.name || "";
            const songCount = playlist?.songCount || 0;

            return (
              <PlaylistWrapper key={index} backgroundimage={artworkUrl}>
                <PlaylistOverlay />

                <PlayIconWrapper
                  onClick={() =>
                    navigate(`/listeningroom/${id}`, {
                      state: { playlistName },
                    })
                  }
                >
                  <HomePlayIcon />
                </PlayIconWrapper>

                <PlaylistBottomInfo>
                  <PlalistInfo>
                    <PlalistInfoImage>
                      <img src={PlaylistImage} alt="Playlist" />
                    </PlalistInfoImage>
                    <PlalistInfoTitle>
                      <h1>{playlistName}</h1>
                      <p>{songCount} Song {songCount > 1? "(s)": ''}</p>
                    </PlalistInfoTitle>
                  </PlalistInfo>

                  <PlaylistAction>
                    <PlaylistActionContent
                      onClick={() => selectPlaylistToBeCopied(name, id)}
                    >
                      <HomeCopyIcon />
                      <p>Copy playlist</p>
                    </PlaylistActionContent>
                    <PlaylistActionContent
                      onClick={() => handleShareClick(playlist)}
                    >
                      <HomeShareIcon />
                      <p>Share playlist</p>
                    </PlaylistActionContent>
                  </PlaylistAction>
                </PlaylistBottomInfo>
              </PlaylistWrapper>
            );
          })}
        </>
      ) : (
        <p>No playlist to show</p>
      )}
    </>
  );
};

const PlaylistWrapper = styled.section<{ backgroundimage: string }>`
  border-radius: 16px;
  max-width: 100vw;
  background-image: url(${(props) => props.backgroundimage});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 444px;
  margin-top: 22px;
  position: relative;
`;

const PlaylistOverlay = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(6, 22, 30, 1) 60%
  );
  opacity: 0.5;
  border-radius: 16px;
`;

const PlalistInfo = styled.div`
  display: flex;
  align-items: center;
  padding: 16px;
  z-index: 1;
  gap: 8px;
`;

const PlalistInfoImage = styled.div`
  width: 50px;
  z-index: 1;

  img {
    width: 50px;
    z-index: 1;
  }
`;

const PlalistInfoTitle = styled.div`
  z-index: 1;
  h1 {
    color: #e3e3e3;
    font-size: 20px;
    font-weight: 700;
    z-index: 1;
  }

  p {
    color: #e3e3e3;
    font-weight: 500;
    font-size: 12px;
  }
`;

const PlaylistBottomInfo = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  margin-bottom: 20px;
`;

const PlayIconWrapper = styled.div`
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
  cursor: pointer;

  svg {
    z-index: 2;
  }
`;

const PlaylistAction = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  width: 100%;
  z-index: 10;
`;

const PlaylistActionContent = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  cursor: pointer;
  z-index: 10;

  p {
    color: #f5f5f5;
    font-size: 14px;
    font-weight: 700;
    z-index: 10;
  }
`;
