import React from "react";
import styled from "styled-components";
import { RiCloseCircleLine } from "react-icons/ri";
interface ModalProps {
  title: string;
  bodyText: string;
  closeModal: () => void;
  icontype?:string;
  actionBtn: string;
  exitBtn: string;
  callBack?: () => void;
}

const BottomModal: React.FC<ModalProps> = ({
  title,
  bodyText,
  closeModal,
  actionBtn,
  exitBtn,
  icontype,
  callBack,
}) => {
  return (
    <BottomModalContainer>
      <BottomModalContainerContent>
        <h2>{icontype && icontype === 'close'? <RiCloseCircleLine color="#D92D20" size={25} style={{marginRight: 10}}/>: null}{title}</h2>
        <p>{bodyText}</p>
        <ModalAction>
          <BottomModalCancelAction onClick={closeModal}>
            {exitBtn}
          </BottomModalCancelAction>
          <BottomModalAction onClick={callBack}>{actionBtn}</BottomModalAction>
        </ModalAction>
      </BottomModalContainerContent>
    </BottomModalContainer>
  );
};

export default BottomModal;

const BottomModalContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 10px;
  position: fixed;
  bottom: 10%;
  right: 0;
  left: 0;
  border-radius: 27px;
  z-index: 1000;
`;

const BottomModalContainerContent = styled.div`
  background: #ffffff;
  width: 100%;
  padding: 16px;
  border-radius: 8px;
  z-index: 1000;

  h2 {
    color: #D92D20;
    font-family: Satoshi;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  p {
    font-family: Satoshi;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    color: #595959;
    margin-bottom: 32px;
  }
`;

const BottomModalCancelAction = styled.button`
  outline: none;
  border: none;
  background: transparent;
  color: #5f7f90;
  font-family: Satoshi;
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
`;

const BottomModalAction = styled.button`
  outline: none;
  border: none;
  background: transparent;
  color: #008135;
  font-family: Satoshi;
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
`;

const ModalAction = styled.div`
  display: flex;
  gap: 24px;
  justify-content: right;
`;
