import React, { useState, useRef, useEffect, useContext } from "react";
import styled from "styled-components";
import { OutputType } from "@types";
import {
  appleToSpotify,
  spotifyToApple,
  spotifyToYoutube,
  youtubeToApple,
  youtubeToSpotify,
} from "services/conversion";
import { PlaylistType } from "types";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { clearCopied, copied } from "features/tracks/copiedTracksSlice";
import {
  AppleMusicIcon,
  AudiomacIcon,
  SportifyIcon,
  YoutubeMusicIcon,
} from "icons/musicServicesIcon";
import { PlayaToast } from "utils";
import { clearFailedTracks } from "features/tracks/failedTracksSlice";
import { clearTotalTracks } from "features/tracks/tracksTotalSlice";
import { clearTracks } from "features/tracks/tracksSlice";
import { selectCurrentUser } from "features/auth/authSlice";
import cookies from "js-cookie";
import { MusicKitContext } from "components/MusicKitController";
interface ModalProps {
  action: string;
  closeModal: () => void;
  playListArr: OutputType;
  onGoingState: React.Dispatch<React.SetStateAction<boolean>>;
  actionCompleted: React.Dispatch<React.SetStateAction<boolean>>;
  clickedItems: PlaylistType[];
  setClickedItems: React.Dispatch<React.SetStateAction<PlaylistType[]>>;
}

interface PlaylistContentProps {
  clicked: boolean;
}

const SelectPlaylistBottomModal: React.FC<ModalProps> = ({
  action,
  closeModal,
  playListArr,
  onGoingState,
  clickedItems,
  setClickedItems,
  actionCompleted,
}) => {
  const ref = useRef<any>();
  const dispatch = useDispatch();
  const numOfCopiedTrack = useSelector(copied);
  const [conversionType, setConversionType] = useState("");
  const [isSelectPlatform, setIsSelectPlatform] = useState(false);
  const userr = useSelector(selectCurrentUser);
  const context = useContext(MusicKitContext);
  const user =
    userr !== null ? userr : JSON.parse(cookies.get("playa_user") as string);

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        closeModal();
        dispatch(clearCopied());
        dispatch(clearFailedTracks());
        dispatch(clearTotalTracks());
        dispatch(clearTracks());
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  const accountArr = [
    {
      app: "spotify",
      icon: <SportifyIcon />,
      callback: () => {},
      isView: user?.isSpotifyConnected,
    },
    {
      app: "apple",
      icon: <AppleMusicIcon />,
      callback: () => {},
      isView: user?.isAppleConnected,
    },
    {
      app: "youtube",
      icon: <YoutubeMusicIcon />,
      callback: () => {},
      isView: user?.isGoogleConnected,
    },
    {
      app: "audiomac",
      icon: <AudiomacIcon />,
      callback: () => null,
      isView: false,
    },
  ].filter((i) => i.app !== user?.primaryStreamingService && i.isView);

  const copyPlaylist = async () => {
    closeModal();
    const playlistData = clickedItems;
    onGoingState(true);

    if (
      user?.primaryStreamingService === "youtube" &&
      conversionType === "spotify"
    ) {
      try {
        const resp = await youtubeToSpotify(
          playlistData,
          dispatch,
          numOfCopiedTrack
        );
        if (resp) {
          onGoingState(false);
          actionCompleted(true);
        }
      } catch (error) {
        console.log(error, "<----- error");
        onGoingState(false);
        actionCompleted(true);
      }
    }

    if (
      user?.primaryStreamingService === "spotify" &&
      conversionType === "apple"
    ) {
      try {
        const resp = await spotifyToApple(
          playlistData,
          context,
          dispatch,
          numOfCopiedTrack
        );
        if (resp) {
          onGoingState(false);
          actionCompleted(true);
        }
      } catch (error) {
        console.log(error, "<----- error");
        onGoingState(false);
        actionCompleted(true);
      }
    }
    if (
      user?.primaryStreamingService === "apple" &&
      conversionType === "spotify"
    ) {
      try {
        const resp = await appleToSpotify(
          playlistData,
          context,
          dispatch,
          numOfCopiedTrack
        );
        if (resp) {
          onGoingState(false);
          actionCompleted(true);
        }
      } catch (error) {
        console.log(error, "<----- error");
        onGoingState(false);
        actionCompleted(true);
      }
    }
    //
    if (
      user?.primaryStreamingService === "youtube" &&
      conversionType === "apple"
    ) {
      try {
        const resp = await youtubeToApple(
          playlistData,
          context,
          dispatch,
          numOfCopiedTrack
        );

        if (resp) {
          console.log(resp, "toutube to apole result---");
          onGoingState(false);
          actionCompleted(true);
        }
      } catch (error) {
        console.log(error, "<----- error");
        onGoingState(false);
        actionCompleted(true);
      }
    }
    // SPOTFIY TO YOUTUBE
    if (
      user?.primaryStreamingService === "spotify" &&
      conversionType === "youtube"
    ) {
      console.log(playlistData, "playlistData");
      onGoingState(false);
      PlayaToast.error({
        msg: "Oops! coming soon",
      });
      // try {
      //   const resp = await spotifyToYoutube(
      //     playlistData,
      //     dispatch,
      //     numOfCopiedTrack
      //   );
      //   if (resp) {
      //     onGoingState(false);
      //     actionCompleted(true);
      //   }
      // } catch (error) {
      //   console.log(error, "<----- error");
      //   onGoingState(false);
      //     actionCompleted(true);
      // }
    }
    // APPLE TO YOUTUBE
    if (
      user?.primaryStreamingService === "apple" &&
      conversionType === "youtube"
    ) {
      console.log(playlistData, "playlistData");
      onGoingState(false);
      PlayaToast.error({
        msg: "Oops! coming soon",
      });
      // try {
      //   const resp = await spotifyToYoutube(
      //     playlistData,
      //     dispatch,
      //     numOfCopiedTrack
      //   );
      //   if (resp) {
      //     onGoingState(false);
      //     actionCompleted(true);
      //   }
      // } catch (error) {
      //   console.log(error, "<----- error");
      //   onGoingState(false);
      //     actionCompleted(true);
      // }
    }
  };

  return (
    <BottomModalContainer ref={ref}>
      <ButtomModalAction onClick={closeModal}>
        <CloseDiv></CloseDiv>
      </ButtomModalAction>
      <BottomModalContainerContentFlex>
        <>
          <h1>Select destination platform</h1>
          <DestinationContentContainer>
            {accountArr.length === 0 && (
              <p>
                Please connect your other streaming platforms to perform
                copy/sync actions
              </p>
            )}
            {accountArr.length > 0 &&
              accountArr.map((acc) => (
                <StyledButton
                  key={acc.app}
                  onClick={() => setConversionType(acc.app)}
                  selected={conversionType === acc.app}
                >
                  <p>{action} to</p>
                  {acc.icon}
                </StyledButton>
              ))}
          </DestinationContentContainer>
        </>
      </BottomModalContainerContentFlex>

      <ActionButton
        style={{
          // backgroundColor: clickedItems.length > 0  ? "#008135" : "#D9D9D9",
          backgroundColor:
            clickedItems.length === 0 || accountArr.length === 0
              ? "#D9D9D9"
              : "#008135",
          //
        }}
        onClick={
          conversionType === ""
            ? () => setIsSelectPlatform(true)
            : accountArr.length === 0
              ? () => null
              : () => copyPlaylist()
        }
      >
        {conversionType === "" ? (
          <p>Next</p>
        ) : (
          <p>{`${action} ${clickedItems.length}`} playlist(s)</p>
        )}
      </ActionButton>
    </BottomModalContainer>
  );
};

export default SelectPlaylistBottomModal;

const BottomModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  justify-content: space-between;
  padding: 10px 20px 50px 20px;
  position: fixed;
  bottom: 0%;
  right: 0;
  left: 0;
  border-radius: 24px 24px 0 0;
  z-index: 1000 !important;
  background-color: #fff;
`;

const ButtomModalAction = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CloseDiv = styled.div`
  width: 54px;
  height: 6px;
  border-radius: 10px;
  background: #d9d9d9;
`;

const BottomModalContainerContentFlex = styled.div`
  margin-bottom: 0px;
  margin-top: 34px;
`;

const ActionButton = styled.div`
  border-radius: 32px;
  padding: 16px 16px;
  margin-top: 0px;
  margin-bottom: 40px;

  p {
    font-family: Satoshi;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    color: #fafafa;
    text-align: center;
  }
`;

const StyledButton = styled.button<{ selected: boolean }>`
  display: flex;
  width: 100%;
  height: 46px;
  padding: 12px 14px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  border: 1px solid ${(props) => (props.selected ? "#DD9500" : "#bfbfbf")};
  background-color: ${(props) =>
    props.selected ? "rgba(247, 214, 144, 0.11)" : "transparent"};

  p {
    color: #1f1f1f;
    font-size: 14px;
    font-weight: 600;
    font-family: Satoshi;
  }
`;

const DestinationContentContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 16px;
  margin-bottom: 20px;
  margin-top: 40px;
`;
