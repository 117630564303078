//@ts-nocheck
import React, { ReactNode } from "react";
import styled from "styled-components";
import { IoCheckmarkCircle } from "react-icons/io5";
interface IConnectAccountButton {
  description: string;
  serviceIcon: ReactNode;
  isConnected?: boolean;
  isPrimary?: boolean;
}

export const ConnectAccountButton = ({
  description,
  serviceIcon,
  isConnected,
  isPrimary,
}: IConnectAccountButton) => {
  return (
    <StyledButton
      style={{
        background: `${isConnected ? "#F0F0F0" : "transparent"}`,
      }}
      className="serviceButton"
    >
      <p>{isConnected ? `Connected` : description}</p>
      {serviceIcon}{" "}
      {isPrimary && isConnected ? (
        <IoCheckmarkCircle color="#008135" size={25} />
      ) : null}
    </StyledButton>
  );
};

interface IStyledButton {
  isConnected: boolean;
}

export const StyledButton = styled.button<IStyledButton>`
  display: flex;
  width: 100%;
  height: 46px;
  padding: 12px 14px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  border: 1px solid #bfbfbf;
`;
