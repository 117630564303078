import styled from "styled-components";

interface ButtonProps {
  kind: string;
}

export const PrimaryButton = styled.button<ButtonProps>`
  background-color: ${(props) =>
    props.kind === "primary" ? "#008135" : "#008135"};
  border: none;
  color: #fff;
  border-radius: 32px;
  display: flex;
  height: 48px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 100%;
`;
