import styles from "styles/onboarding.module.scss";
// import { Navigate } from "react-router-dom";
// import { useSelector } from "react-redux";
import PlayaOnboardingImg from "assets/PlayaOnboardingImg.svg";
import { useNavigate } from "react-router-dom";
// import { selectCurrentToken } from "features/auth/authSlice";
// import usePersist from "hooks/usePersist";

const OnboardingScreen = () => {
  // const [persist] = usePersist();

  const navigate = useNavigate();

  // const token = useSelector(selectCurrentToken);
  // if (persist) return <Navigate to="/home" replace={true} />;

  return (
    <div className={styles.onboardingWrapper}>
      <div>
        <img src={PlayaOnboardingImg} alt="onboardingImg" />
      </div>
      <h5>Let The Music Flow</h5>

      <div className="subTextContainer">
        <p>Manage and share your music with</p>
        <p>friends across different platforms.</p>
      </div>
      <div className={styles.buttonWrapper}>
        <button
          className={styles.onboardingButton}
          onClick={() => navigate("/login")}
        >
          Next
        </button>
      </div>
      <div className={styles.overlay}></div>
    </div>
  );
};

export default OnboardingScreen;
