import React from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "features/auth/authSlice";
import { useGetRoomQuery } from "features/auth/authApiSlice";
import { LoadingScreen } from "components/common/LoadingScreen";
import { PRIMARY_STREAM_SERVICE } from "contants/primaryStreamingService";
import { AppleMusic } from "components/ListeningRoom/AppleMusic";
import { Spotify } from "components/ListeningRoom/Spotify";
import "styles/spotify.scss";
import { useLocation } from "react-router-dom";
import cookies from "js-cookie";
import { Youtube } from "components/ListeningRoom/Youtube";
const ListeningRoomScreen = ({ token }) => {
  // const user = useSelector(selectCurrentUser);
  const userr = useSelector(selectCurrentUser);
  const user = userr !== null ? userr : JSON.parse(cookies.get("playa_user"));
  const location = useLocation();
  const  appType = user?.primaryStreamingService;
  const params = useParams();
  const { playlistId } = params;

  // const { isLoading } = useGetRoomQuery(roomId);

  // if (isLoading) return <LoadingScreen />;

  if (appType === "spotify") {
    return <Spotify token={token} />;
  } else if (appType === "apple") {
    return <AppleMusic token={token} />;
  } else if (appType === "youtube") {
    return <Youtube token={token} />;
  } else {
    return null;
  }
  // return null;

  // if (user?.primaryStreamingService === PRIMARY_STREAM_SERVICE.SPOTIFY) {
  //   return <Spotify token={token} />;
  // } else {
  //   return <AppleMusic token={token} />;
  // }
};

export default ListeningRoomScreen;
